import { Dropdown as AntdDropdown } from "antd";

// Components
import Icon from "../../icon/Icon";

/**
 * Dropdown
 * @param {*} label
 * @param {*} menuItems
 * @param {*} placement
 * @param {*} triggerMode
 */
export default function Dropdown({
  label = "",
  menuItems = [],
  placement = "bottom",
  triggerMode = "hover",
  isArrowPointAtCenter = false,
  className = "",
}) {
  return (
    <AntdDropdown
      className={`${className} cursor-pointer`}
      menu={{ items: menuItems }}
      placement={placement}
      trigger={[triggerMode]}
      arrow={{
        pointAtCenter: isArrowPointAtCenter,
      }}
    >
      <div>
        {label} <Icon iconName="chevronDown" color="black" size="18" strokeWidth={2} />
      </div>
    </AntdDropdown>
  );
}
