import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

// Hooks
import { useUpdateQueryParams } from "../../../hooks/useUpdateQueryParams";

// Actions
import { ProjectActions } from "../../../redux-slice/project/projectSlice";

// Constants
import { DefaultPagination, QueryParamsKeys } from "../../../constants/WebConstants";

// Components
import Input from "../../../components/antd/input/Input";

// Page Components
import ProjectsTable from "./components/ProjectsTable";

const PAGE_SIZE = 8;

function Header() {
  return (
    <>
      <p className="my-3 fw-bold fs-5">All Projects</p>
      <div className="my-3">
        {/* Input Component */}
        <Input className="w-25" type="password" placeholder="Search Projects" isSearchButton={true} />
      </div>
    </>
  );
}

/**
 * User Project List Table
 */
export default function ProjectListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Query Params
  const [searchParams] = useUpdateQueryParams();

  // Selector State
  const projectListLoading = useSelector((state) => state.project.projectListLoading);

  // From URL
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber) ?? DefaultPagination.pageNumber;
  const pageSize = searchParams.get(QueryParamsKeys.pageSize) ?? PAGE_SIZE;
  const searchText = searchParams.get(QueryParamsKeys.searchText);

  //use Effect
  useEffect(() => {
    dispatch(ProjectActions.searchProjects({ searchText, pageNumber, pageSize }));
  }, [dispatch, searchText, pageNumber, pageSize]);

  //
  return (
    <div className="page-content">
      {/* Header Section..*/}
      {!projectListLoading && ( //
        <Header />
      )}

      {/* Table..*/}
      <ProjectsTable currentPage={pageNumber} pageSize={pageSize} dataLoading={projectListLoading} />
    </div>
  );
}
