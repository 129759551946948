import { useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

// Constants
import { QueryParamsKeys } from "../../../../constants/WebConstants";

// Utils
import DecimalUtils from "../../../../utils/DecimalUtils";

// Config
import GraphConfig from "../../../../reactflow/GraphConfig";

// Page Components
function VariableRow({ rowInfo = {}, boundsData = {}, setBoundsData = () => {}, optRunId = "" }) {
  // Row Information
  const { name, symbol, varType } = rowInfo;

  // Each Row Information
  const currBounds = boundsData[symbol] || {};
  const { value = "", min = "", max = "", solution = "" } = currBounds;

  const isInputDisabled = optRunId ? true : false;

  // TODO: Try to handle zero value, instead of converting to string in the condition.
  // State
  const [isFixed, setIsFixed] = useState(`${value}` || false);

  // Handle Change Function
  function handlChange({ target }, key = "value") {
    const newBouds = {
      ...currBounds,
      [key]: target.value,
    };

    // key is min/max/fixed
    setBoundsData((prev) => ({ ...prev, [symbol]: newBouds }));
  }

  //
  return (
    <tr>
      <td className="pt-1">
        <span className="m-2">
          {name}
          <small className="ms-2 text-secondary fst-italic">{GraphConfig.VarTypeNameMap[varType]}</small>
        </span>
      </td>

      <td className="pt-1">
        <span className="m-2">{symbol}</span>
      </td>

      <td className="">
        <select
          className="form-select form-select-sm"
          name="isFixed"
          value={isFixed}
          disabled={isInputDisabled}
          onChange={() => setIsFixed(!isFixed)}
        >
          <option value={true}>Fixed</option>
          <option value={false}>Floating</option>
        </select>
      </td>

      <td>
        <input className="form-control form-control-sm" value={value} onChange={handlChange} disabled={!isFixed} />
      </td>

      <td>
        <input
          className="form-control form-control-sm"
          value={min}
          disabled={isInputDisabled || isFixed}
          onChange={(e) => handlChange(e, "min")}
        />
      </td>

      <td>
        <input
          className="form-control form-control-sm"
          value={max}
          disabled={isInputDisabled || isFixed}
          onChange={(e) => handlChange(e, "max")}
        />
      </td>

      {optRunId && (
        <td className=" px-2 pt-1 bg-warning-subtle fw-semibold text-end">{DecimalUtils.fixDecimal(solution)}</td>
      )}
    </tr>
  );
}

function BoundsCardTableBody({ boundsData = {}, setBoundsData = () => {}, optRunId = "" }) {
  // Graph Data Selector State
  const graphData = useSelector((state) => state.graph.graphData);

  // Graph Data
  const { edges = [], parameters = [] } = graphData || {};

  return (
    <tbody>
      <tr>
        <td colSpan={8} className="text-center fw-bold bg-secondary text-light">
          Parameters
        </td>
      </tr>

      {/** Parameters */}
      {parameters.map((variable, idx) => (
        <VariableRow
          key={`key-var-${idx}`}
          rowInfo={variable}
          boundsData={boundsData}
          setBoundsData={setBoundsData}
          optRunId={optRunId}
        />
      ))}

      <tr>
        <td colSpan={8} className="text-center fw-bold bg-secondary text-light">
          Variables (Edges)
        </td>
      </tr>

      {/** Edges */}
      {edges.map((variable, idx) => (
        <VariableRow
          key={`key-var-${idx}`}
          rowInfo={variable}
          boundsData={boundsData}
          setBoundsData={setBoundsData}
          optRunId={optRunId}
        />
      ))}
    </tbody>
  );
}

function BoundsCardTableHeader({ optRunId = "" }) {
  return (
    <thead>
      <tr>
        <th className="px-2 py-1">Variable</th>

        <th className="col-2 px-2 py-1">Symbol</th>

        <th className="px-2 py-1">Type</th>

        <th className="col-1 px-2 py-1" title="Initial Value">
          Value
        </th>

        <th className="col-1 px-2 py-1" title="Lower Bound">
          Min.
        </th>

        <th className="col-1 px-2 py-1" title="Upper Bound">
          Max.
        </th>

        {optRunId && (
          <th className="col-1 px-2 py-1" title="Upper Bound">
            Solution
          </th>
        )}
      </tr>
    </thead>
  );
}

/**
 * Bounds Card
 * @param {*} boundsData
 * @param {*} setBoundsData
 */
export default function BoundsCard({ boundsData = {}, setBoundsData = () => {} }) {
  // Page Query Params
  const [searchParams] = useSearchParams();
  const optRunId = searchParams.get(QueryParamsKeys.optRunId) ?? "";

  return (
    <div className="card card-info mt-3">
      <div className="card-body">
        <h6 className="py-1 pb-2 mb-3 border-bottom fw-bold">Bounds:</h6>

        <div className="table-responsive">
          <table className="table table-compact">
            {/* Bounds Card Table Header */}
            <BoundsCardTableHeader optRunId={optRunId} />

            {/* Bounds Card Table Body */}
            <BoundsCardTableBody boundsData={boundsData} setBoundsData={setBoundsData} optRunId={optRunId} />
          </table>
        </div>
      </div>
    </div>
  );
}
