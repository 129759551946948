import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Components
import DataTable from "../../../../components/antd/table/DataTable";
import SkeletonTable from "../../../../components/antd/table/SkeletonTable";

// Constants
import { DefaultPagination, QueryParamsKeys } from "../../../../constants/WebConstants";

// Utils
import UrlUtils from "../../../../utils/urlUtils";
import OptRunHelper from "../../../../helpers/optRunHelper";

// URLs
import PageURL from "../../../../constants/pageUrls";
import GraphHelper from "../../../../helpers/graphHelper";

// Page Components
function OptRunTableActions({ record = {}, projectId }) {
  const { key: id } = record;
  const pathParamsObj = { projectId };
  const queryParamsObj = { [QueryParamsKeys.optRunId]: id };
  const OptRunSetupPageURL = UrlUtils.format(PageURL.OptSetupPageURL, pathParamsObj, queryParamsObj);

  return (
    <div>
      <Link className="btn btn-sm btn-outline-primary" to={OptRunSetupPageURL}>
        Open Setup
      </Link>
    </div>
  );
}

/**
 * OptRun Table Component
 */
export default function OptRunTable({ optSetup = {}, varArray = [], records = [], pagination = {} }) {
  // Page info
  // const { pageNo, pageSize, totalCount, totalPages, pageFirst, pageLast } = pagination;

  // OptSetup
  // const { id: optSetupId, projectId } = optSetup;

  // Table Headers
  const varHeaders = OptRunHelper.varsAsTableHeaders(varArray);
  const objHeader = {
    title: "Cost",
    dataIndex: "objCost",
  };

  const columns = [objHeader, ...varHeaders];

  //
  return (
    <div className="py-3 pb-5">
      {/** */}
      <DataTable rows={records} columns={columns} applyTableBorder={true} />
    </div>
  );
}
