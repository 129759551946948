import { useSelector } from "react-redux";
import { Tag } from "antd";

// Constants
import { USER_OBJECT_KEYS, USER_STATUS, USER_TAG_COLOR } from "../constants/userConstants";

// Components
import Loader from "../components/loader/Loader";

function Status({ id = "" }) {
  const userStatusLoading = useSelector((state) => state.user.userStatusLoadingmap[id]) || false;

  const { isActive = false } = useSelector((state) => state.user.userMap[id]);

  const statusText = isActive ? USER_STATUS.ACTIVE : USER_STATUS.IN_ACTIVE;
  const tagColor = isActive ? USER_TAG_COLOR.GREEN : USER_TAG_COLOR.RED;

  return (
    <div className="d-flex justify-content-center w-75">
      {userStatusLoading && <Loader />}
      {!userStatusLoading && (
        <Tag className="d-flex justify-content-center align-items-center w-100" color={tagColor}>
          {statusText}
        </Tag>
      )}
    </div>
  );
}

// Constructing Rows for the Users List
function constructUserListTableData(usersList = [], authUsersList = {}) {
  // Construct rows
  const userRowMap = {};

  for (let index = 0; index < usersList.length; index++) {
    const {
      id = "",
      firstName = "",
      lastName = "",
      emailId = "",
      phoneNum = "",
      designation = "",
      isActive = false,
    } = usersList[index];

    const userRow = {
      key: id,
      firstName,
      lastName,
      emailId,
      phoneNum,
      designation,
      isActive,
      role: authUsersList[id][USER_OBJECT_KEYS.ROLE],
      status: <Status id={id} />,
    };
    userRowMap[id] = userRow;
  }

  return userRowMap;
}

const UserTableUtils = { constructUserListTableData };

export default UserTableUtils;
