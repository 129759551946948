import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { ProjectFileActions } from "../../../../redux-slice/project/projectFileSlice";

// Constants
import { ICON_SIZE } from "../../../../constants/GeneralConstants";

// Utils
import ProjectFileTableUtils from "../../../../utils/projectFileTableUtils";

// Components
import SkeletonTable from "../../../../components/antd/table/SkeletonTable";
import DataTable from "../../../../components/antd/table/DataTable";
import Loader from "../../../../components/loader/Loader";
import Icon from "../../../../components/icon/Icon";

// Section
import ProjectFileTablePagination from "./ProjectFileTablePagination";

// Page Components
function ProjectFileTableActions({ record = {}, setIsModalOpen = () => {}, setSelectedFileId = () => {} }) {
  const dispatch = useDispatch();

  const downloadFileLoadingMap = useSelector((state) => state.projectFile.downloadFileLoadingMap);

  const { key: id, fileName = "", fileURL = "" } = record;

  const isLoading = downloadFileLoadingMap[id];

  function openModal() {
    setIsModalOpen(true);
    setSelectedFileId(id);
  }

  function savFile() {
    dispatch(ProjectFileActions.downloadFile({ fileId: id, fileName, fileURL }));
  }

  return (
    <div className="d-flex align-items-center gap-3">
      <Icon iconName="pencil" size={ICON_SIZE.MD} onClick={openModal} />

      {!isLoading && <Icon iconName="download" size={ICON_SIZE.MD} onClick={savFile} />}

      {isLoading && <Loader size={ICON_SIZE.MD} />}
    </div>
  );
}

/**
 * Project File Table
 */
export default function ProjectFileTable({ setIsModalOpen = () => {}, setSelectedFileId = () => {} }) {
  const filesMap = useSelector((state) => state.projectFile.filesMap);
  const fileListLoading = useSelector((state) => state.projectFile.fileListLoading);
  const fileListPagination = useSelector((state) => state.projectFile.fileListPagination);

  const { pageNo: currentPage, pageSize } = fileListPagination || {};

  // Constructing columns for users table
  const actionColumn = {
    title: "Action",
    Key: "action",
    render: (record) => {
      return (
        <ProjectFileTableActions
          record={record}
          setIsModalOpen={setIsModalOpen}
          setSelectedFileId={setSelectedFileId}
        />
      );
    },
  };

  const projectFileTableHeaders = ProjectFileTableUtils.getFileTableHeader(currentPage, pageSize);
  const columns = [...projectFileTableHeaders, actionColumn];

  if (fileListLoading) {
    return <SkeletonTable columnCount={8} rowCount={5} />;
  }

  return (
    <>
      <DataTable rows={Object.values(filesMap)} columns={columns} applyTableBorder={true} />

      {/* Table Pagination..*/}
      <ProjectFileTablePagination fileListLoading={fileListLoading} fileListPagination={fileListPagination} />
    </>
  );
}
