import { useEffect, useState } from "react";
import { Input as AntdInput } from "antd";

// Hooks
import { useUpdateQueryParams } from "../../../hooks/useUpdateQueryParams";

// Constants
import { DefaultPagination, QueryParamsKeys } from "../../../constants/WebConstants";

/**
 * Input
 * @param {*} type
 * @param {*} id
 * @param {*} value : Input Value
 * @param {*} className
 * @param {*} onChange : Function
 * @param {*} placeholder
 * @param {*} disabled : Boolean Value
 * @param {*} loading : Boolean Value
 * @param {*} isSearchButton : Boolean Value
 * @param {*} size : large, small and default
 * @param {*} variant : outlined, filled & borderless
 * @param {*} status : error & warning
 */
export default function Input({
  type = "",
  id,
  value = "",
  className = "",
  onChange = () => {},
  placeholder = "Enter text..",
  disabled = false,
  isSearchButton = false,
  size = "",
  variant = "outlined",
  status = "",
  ...rest
}) {
  // State
  const [searchText, setSearchText] = useState("");

  // Query Params
  const [searchParams, updateQueryParams] = useUpdateQueryParams();
  const search = searchParams.get(QueryParamsKeys.searchText);

  const inputValue = isSearchButton ? searchText : value;

  const inputType = AntdInput[type] ?? AntdInput;
  const InputTypeComponent = type ? inputType : AntdInput;

  const inputOnChange = isSearchButton
    ? ({ target }) => {
        setSearchText(target?.value || "");
      }
    : onChange;

  // Search
  function handleSearch(e) {
    const searchText = e.target?.value || "";

    if (!isSearchButton) {
      return e;
    }

    const params = {
      search: searchText,
      pn: DefaultPagination.pageNumber,
      ps: DefaultPagination.pageSize,
    };

    // Updating Url
    updateQueryParams({ params });
  }

  // useEffect
  useEffect(() => {
    setSearchText(search || "");
  }, [search]);

  return (
    <InputTypeComponent
      id={id}
      value={inputValue}
      className={className}
      placeholder={placeholder}
      onChange={inputOnChange}
      disabled={disabled}
      onPressEnter={handleSearch}
      size={size}
      variant={variant}
      status={status}
      {...rest}
    />
  );
}
