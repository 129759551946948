export function saveFile(filename = "", data) {
  const link = document.createElement("a");
  link.target = "_blank";
  link.download = filename;
  link.href = URL.createObjectURL(data);
  link.click();
}

export function base64ToFile(base64String, filename) {
  // Split the base64 string into its content and MIME type (optional but recommended)
  const arr = base64String.split(",");
  const mimeType = arr[0].match(/:(.*?);/)[1]; // Get the MIME type from base64 header
  const byteString = atob(arr[1]); // Decode base64 to binary string
  const byteNumbers = new Array(byteString.length);

  // Create an array of 8-bit unsigned integers from the binary string
  for (let i = 0; i < byteString.length; i++) {
    byteNumbers[i] = byteString.charCodeAt(i);
  }

  // Create a Uint8Array from the byte numbers
  const byteArray = new Uint8Array(byteNumbers);

  // Create a Blob from the byte array
  const blob = new Blob([byteArray], { type: mimeType });

  // Convert the Blob to a File (optional)
  const file = new File([blob], filename, { type: mimeType });

  return file;
}

// -------------------------------------------------------------------------
const FileUtils = {
  saveFile,
  base64ToFile,
};

// export default
export default FileUtils;
