// Edge Transformers
function transformObj(edgeObj = {}) {
  const edge = {
    ...edgeObj,
  };

  return edge;
}

function transformList(edgeObjList = []) {
  const edgeList = edgeObjList.map((obj) => {
    return transformObj(obj);
  });

  return edgeList;
}

// Exports
const EdgeTransformer = {
  transformObj,
  transformList,
};

export default EdgeTransformer;
