import React, { memo, useState } from "react";
import { BaseEdge, EdgeLabelRenderer, getSmoothStepPath } from "reactflow";
import InlineEdit from "../../../components/inline-edit/InlineEdit";

// this is a little helper component to render the actual edge label
function EdgeLabel({ transform, label }) {
  return (
    <div
      style={{
        position: "absolute",
        background: "transparent",
        padding: 10,
        color: "#ff5050",
        fontSize: 12,
        fontWeight: 700,
        transform,
      }}
      className="nodrag nopan"
    >
      {label}
    </div>
  );
}

function CustomEdgeStartEnd(edge) {
  const { id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, data } = edge;
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const [edgeLabel, setEdgeLabel] = useState("Update Name");

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      <EdgeLabelRenderer>
        {/* <InlineEdit value={edgeLabel} setValue={setEdgeLabel} /> */}
        <div
          style={{
            position: "absolute",
            background: "transparent",
            padding: 10,
            color: "#ff5050",
            fontSize: 12,
            fontWeight: 700,
          }}
          className="nodrag nopan"
        >
          {"label"}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}

export default memo(CustomEdgeStartEnd);
