// Pagination
export const DefaultPagination = {
  pageNumber: 1,
  pageSize: 10,
  totalCount: 0,
  maxPageSize: 10000,
};

export const QueryParamsKeys = {
  // Pagination
  pageNumber: "pn",
  pageSize: "ps",

  //
  orgType: "orgType",
  type: "type",
  searchText: "search",

  //
  optSetupId: "optSetupId",
  optRunId: "optRunId",

  // TODO : move to page state
  showEdge: "showEdge",
};

export const StatusCode = {
  FORBIDDEN: 403,
};
