//
import { Link } from "react-router-dom";

// Components

// NavLeft
// ----------------------------------------------------------------------------

function NavItem({ item = {}, activeItem = "", setActiveItem = () => {} }) {
  // Data
  const { id: itemId, icon, name, link } = item;

  //
  return (
    <li
      className={`nav-item ${activeItem === itemId ? "active" : ""}`}
      onClick={() => {
        setActiveItem(itemId);
      }}
    >
      <Link to={link} className="">
        {/** Item Icon */}
        {icon}

        {/** Item Text */}
        <span>{name}</span>
      </Link>
    </li>
  );
}

/**
 * Component :: Vertical Navigation on the LeftEdge
 */
export default function NavLeft({ containerClassName = "", navItems = [], activeItem, setActiveItem }) {
  //
  return (
    <nav className={`nav nav-left pt-2 ${containerClassName}`}>
      {/** Nav Menu */}
      <ul className="nav-items">
        {/* Itetrate over Menu group list */}
        {navItems.map((navItem, idx) => {
          return <NavItem key={idx} item={navItem} activeItem={activeItem} setActiveItem={setActiveItem} />;
        })}
      </ul>
    </nav>
  );
}
