import { Form, Select } from "antd";
import { useEffect, useMemo, useState } from "react";

// Helper
import optRunGraphHelper from "../../../../helpers/optRunGraphHelper";
import ChartHelper from "../../../../components/chart/chartHelper";

// Components
import Chart from "../../../../components/chart/Chart";
import Button from "../../../../components/button/Button";

// Page Constants
const DEFAULT_SELECTED_AXIS = {
  xAxis: "",
  yAxis: [],
  chartType: "",
};

const GRAPH_DIV_ID = "opt-graph";

// Page Components
function CustomizeRequiredMark(label = "", { required = false }) {
  return (
    <>
      {label}
      {required && <span className="text-danger ms-1">*</span>}
    </>
  );
}

function SelectOptionInput({ compInfo = {} }) {
  const {
    label = "",
    isMultiSelect = false,
    options = [],
    name = "",
    placeholder = "",
    showSearch = false,
    errMessage = "",
  } = compInfo;

  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required: true,
          message: errMessage,
        },
      ]}
    >
      <Select showSearch={showSearch || isMultiSelect} mode={isMultiSelect ? "multiple" : ""} placeholder={placeholder}>
        {options.map(({ name = "", value = "" }) => (
          <Select.Option key={value} value={value}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

function SelectAxisForm({ selectComponents = [], handleSelectChange = () => {} }) {
  return selectComponents.map((comp, idx) => (
    <SelectOptionInput key={idx} compInfo={comp} handleSelectChange={handleSelectChange} />
  ));
}

/**
 * Opt Run Graph
 * @param {*} varArray : Array
 * @param {*} dataList : Array
 * @param {*} openModal : Function
 */
export default function OptRunGraph({ varArray = [], dataList = [], openModal = () => {} }) {
  const [chartLayout, setChartLayout] = useState({});
  const [selectComponentsArray, setSelectComponentArray] = useState([]);
  const [chartFormData, setChartFormData] = useState(DEFAULT_SELECTED_AXIS);

  const [form] = Form.useForm();
  const xAxis = Form.useWatch("xAxis", form);
  const yAxis = Form.useWatch("yAxis", form);
  const chartType = Form.useWatch("chartType", form);

  const varObj = optRunGraphHelper.constructPropertObj(varArray);

  async function saveGraphImage() {
    const base64String = await ChartHelper.createImage(GRAPH_DIV_ID);
    openModal(base64String, chartLayout);
  }

  const xAxisData = useMemo(() => {
    return optRunGraphHelper.getObjPropertyValues(dataList, xAxis);
  }, [xAxis]);

  useEffect(() => {
    setChartFormData({ xAxis, yAxis, chartType });
  }, [xAxis, yAxis, chartType]);

  useEffect(() => {
    const selectComponents = optRunGraphHelper.getSelectComponentsArray(varArray, chartFormData);
    setSelectComponentArray(selectComponents);
  }, [varArray]);

  useEffect(() => {
    form.setFieldsValue({ chartType: "line" });
  }, []);

  useEffect(() => {
    const layout = optRunGraphHelper.constructChartLayout(varObj, chartFormData);
    setChartLayout(layout);
  }, [chartFormData]);

  const traces = useMemo(() => {
    return optRunGraphHelper.constructTraces(varObj, dataList, xAxisData, chartFormData);
  }, [varObj, dataList, xAxisData, chartFormData]);

  return (
    <div className="card mt-3 p-3">
      <Form
        labelCol={{
          span: 4,
        }}
        requiredMark={CustomizeRequiredMark}
        form={form}
        onFinish={saveGraphImage}
      >
        <SelectAxisForm selectComponents={selectComponentsArray} />

        <div className="text-center">
          <Chart divId={GRAPH_DIV_ID} layout={chartLayout} data={traces} />
        </div>

        <div className="d-flex flex-row-reverse">
          <Button className="btn-secondary" type="submit">
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
}
