const CHART_TYPE_OPTIONS = [
  { name: "LINE", value: "line" },
  { name: "BAR", value: "bar" },
];

const OBJECT_COST_OBJ = {
  name: "Cost",
  symbol: "objCost",
};

// Helper Functions
function constructPropertObj(varArray = []) {
  const varObj = varArray.reduce((acc, eachVar) => {
    const { symbol = "" } = eachVar;
    acc[symbol] = eachVar;
    return acc;
  }, {});

  return { objCost: OBJECT_COST_OBJ, ...varObj };
}

function getObjPropertyValues(dataList = [], property = "") {
  return dataList.reduce((acc, eachData) => {
    const value = eachData[property] || "";
    return value ? [...acc, value] : acc;
  }, []);
}

function constructTraces(varObj = {}, dataList = [], xAxisData = [], chartFormData = []) {
  const { yAxis = [], chartType = "" } = chartFormData;

  const traces = yAxis.map((symbol) => {
    const propertyValues = getObjPropertyValues(dataList, symbol);

    const { name = "" } = varObj[symbol] || {};

    const trace = {
      name,
      x: xAxisData,
      y: propertyValues,
      type: chartType,
    };

    return trace;
  });

  return traces;
}

function getXAndYAxisTitle(varObj = {}, chartFormData = {}) {
  const { xAxis = "", yAxis = [] } = chartFormData;
  const xAxisTitle = xAxis ? varObj[xAxis].name : "";
  const yAxisTitle = yAxis.map((symbol) => varObj[symbol].name).join(", ");

  return { xAxisTitle, yAxisTitle };
}

function constructChartLayout(varObj, chartFormData) {
  const { xAxisTitle = "", yAxisTitle = "" } = getXAndYAxisTitle(varObj, chartFormData);

  const title = xAxisTitle && xAxisTitle ? `${xAxisTitle} vs ${yAxisTitle}` : "";

  return {
    title,
    xaxis: {
      title: xAxisTitle,
      fixedrange: true, // To disable select Zoom
    },
    yaxis: {
      title: yAxisTitle,
      fixedrange: true,
    },
  };
}

function getOptionsArray(varArray = []) {
  const updatedVarArray = [OBJECT_COST_OBJ, ...varArray];

  return updatedVarArray.map(({ name = "", symbol = "" }) => ({ name: `${name} (${symbol})`, value: symbol }));
}

function getSelectComponentsArray(varArray = [], chartFormData = {}) {
  const { xAxis = "", yAxis = "", chartType = "" } = chartFormData;
  const optionsArray = getOptionsArray(varArray);

  return [
    {
      label: "Chart Type",
      options: CHART_TYPE_OPTIONS,
      name: "chartType",
      value: chartType,
      placeholder: "Select Chart Type",
      showSearch: false,
      isMultiSelect: false,
      errMessage: "Select chart type",
    },
    {
      label: "X Axis",
      options: optionsArray,
      name: "xAxis",
      value: xAxis,
      placeholder: "Select X Axis",
      showSearch: true,
      isMultiSelect: false,
      errMessage: "Please select X axis",
    },
    {
      label: "Y Axis",
      options: optionsArray,
      name: "yAxis",
      value: yAxis,
      placeholder: "Select Y Axis",
      showSearch: false,
      isMultiSelect: true,
      errMessage: "Please select Y axis",
    },
  ];
}

const optRunGraphHelper = {
  constructPropertObj,
  getObjPropertyValues,
  getSelectComponentsArray,

  // Chart
  constructTraces,
  constructChartLayout,
};

export default optRunGraphHelper;
