import { all, fork } from "redux-saga/effects";

// Auth Saga
import AuthSaga from "./auth/authSaga";

// Graph Sagas
import GraphSaga from "./graph/graphSaga";
import NodeSaga from "./graph/nodeSaga";
import EdgeSaga from "./graph/edgeSaga";
import ParameterSaga from "./graph/parameterSaga";
import EquationSaga from "./graph/equationSaga";

// Project Saga
import ProjectSaga from "./project/projectSaga";
import ProjectFileSaga from "./project/projectFileSaga";

// Project :: Opt Sagas
import OptSetupSaga from "./project/optSetupSaga";
import OptRunSaga from "./project/optRunSaga";
import OptRunJobSaga from "./project/optRunJobSaga";

// User  Saga
import UserSaga from "./user/userSaga";

//
// Root Saga

export default function* RootSaga() {
  yield all([
    // Auth Saga
    fork(AuthSaga),

    // Graph Saga
    fork(GraphSaga),
    fork(NodeSaga),
    fork(EdgeSaga),
    fork(ParameterSaga),
    fork(EquationSaga),

    // Project Saga
    fork(ProjectSaga),
    fork(ProjectFileSaga),

    // Project :: Opt Sagas
    fork(OptSetupSaga),
    fork(OptRunSaga),
    fork(OptRunJobSaga),

    // User Saga
    fork(UserSaga),
  ]);
}
