import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { EdgeActions } from "../../redux-slice/graph/edgeSlice";
import { GraphActions } from "../../redux-slice/graph/graphSlice";
import { setErrorInfo } from "../../app/error/ErrorActions";

// Service
import EdgeService from "../../services/graph/edgeService";

// Create Edge
export function* createEdge(action) {
  const { graphId, edge } = action.payload;

  try {
    const { id, type, source, target, data = {} } = edge;
    const edgeObj = {
      uid: id,
      type: type.toUpperCase(),
      sourceId: source,
      targetId: target,
      name: data.name,
      symbol: data.symbol,
    };

    // Invoke API
    const newEdge = yield EdgeService.createEdge(graphId, edgeObj);

    yield put(EdgeActions.createEdgeSuccess({ newEdge }));

    // TODO Remove
    // Refresh Graph Data (temp fix till the reactflow is moved to redux store)
    yield put(GraphActions.getGraphData({ graphId }));

    //
  } catch (error) {
    yield put(EdgeActions.createEdgeFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Update Edge Information
export function* updateEdgeInfo(action) {
  const { graphId, edgeUid, edgeInfo } = action.payload;

  try {
    yield EdgeService.updateEdgeInfo(graphId, edgeUid, edgeInfo);

    yield put(EdgeActions.updateEdgeInfoSuccess({ graphId, edgeUid, edgeInfo }));
  } catch (error) {
    yield put(EdgeActions.updateEdgeInfoFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Update Edge Var Information
export function* updateEdgeVarInfo(action) {
  const { graphId, edgeUid, varInfo } = action.payload;

  try {
    yield EdgeService.updateEdgeVarInfo(graphId, edgeUid, varInfo);

    yield put(EdgeActions.updateEdgeVarInfoSuccess({ graphId, edgeUid, varInfo }));
  } catch (error) {
    yield put(EdgeActions.updateEdgeVarInfoFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Update Edge Style
export function* updateEdgeStyle(action) {
  const { graphId, edgeUid, styleObj } = action.payload;

  try {
    yield EdgeService.updateEdgeStyle(graphId, edgeUid, styleObj);

    yield put(EdgeActions.updateEdgeStyleSuccess({ graphId, edgeUid, styleObj }));
  } catch (error) {
    yield put(EdgeActions.updateEdgeStyleFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// delete
export function* deleteEdge(action) {
  const { graphId, edgeUid } = action.payload;

  try {
    yield EdgeService.deleteEdge(graphId, edgeUid);

    yield put(EdgeActions.deleteEdgeSuccess());
  } catch (error) {
    yield put(EdgeActions.deleteEdgeFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Export
export default function* root() {
  yield all([
    //
    takeLatest(EdgeActions.createEdge.type, createEdge),

    //
    takeLatest(EdgeActions.updateEdgeInfo.type, updateEdgeInfo),
    takeLatest(EdgeActions.updateEdgeVarInfo.type, updateEdgeVarInfo),
    takeLatest(EdgeActions.updateEdgeStyle.type, updateEdgeStyle),

    //
    takeLatest(EdgeActions.deleteEdge.type, deleteEdge),
  ]);
}
