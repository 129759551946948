import React, { useCallback, useRef, useState } from "react";
import {
  Background,
  Controls,
  MarkerType,
  Panel,
  ReactFlow,
  ReactFlowProvider,
  addEdge,
  useEdgesState,
  useNodesState,
} from "reactflow";

// Constants
import { ICON_SIZE } from "../../../constants/GeneralConstants";

// Components
import PageHeader from "../../../app/layout/PageHeader";
import ConnectionLine from "./ConnectionLine";
import Icon from "../../../components/icon/Icon";

// Components :: Reactflow
import ConservationNode from "./ConservationNode";
import CustomEdgeStartEnd from "./CustomEdgeStartEnd";
import ContextMenu from "./ContextMenu";
import { actionOnNodeDragComplete, addInputNode, connectionLineStyle } from "./ReactFlowUtil";

const nodeTypes = {
  conservation: ConservationNode,
};

const edgeTypes = {
  "start-end": CustomEdgeStartEnd,
};

const initialNodes = [
  // {
  //   id: "1",
  //   type: "conservation",
  //   position: { x: 0, y: 0 },
  //   data: { label: "Node 1", leftHandles: [], rightHandles: [] },
  // },
  // {
  //   id: "2",
  //   type: "conservation",
  //   position: { x: 220, y: 0 },
  //   data: { label: "Node 2", inpHndls: [], outHndls: [] },
  // },
  // {
  //   id: "3",
  //   type: "conservation",
  //   position: { x: -140, y: -0 },
  //   data: { label: "Node 3", inpHndls: [], outHndls: [] },
  // },
];

const initialEdges = [
  // {
  //   id: "1-2-1",
  //   type: "start-end",
  //   source: "1",
  //   sourceHandle: "sa",
  //   target: "1",
  //   targetHandle: "sb",
  //   animated: true,
  //   data: {
  //     label: "edge 12 a",
  //   },
  //   markerEnd: {
  //     type: MarkerType.ArrowClosed,
  //   },
  // },
  // {
  //   id: "1-2-2",
  //   type: "start-end",
  //   source: "1",
  //   sourceHandle: "sb",
  //   target: "2",
  //   targetHandle: "tb",
  //   animated: true,
  //   data: {
  //     label: "edge 12 b",
  //   },
  //   markerEnd: {
  //     type: MarkerType.ArrowClosed,
  //   },
  // },
];

// Page Components
// ----------------------------------------------------------------------------

function ConsoleCard({ nodes, edges, selectedNode = {} }) {
  return (
    <div className="border p-3" style={{ minHeight: 650 }}>
      <ul className="list-unstyled list-graph-elems">
        {nodes.map((n) => {
          const nId = n.id;
          const nData = n.data || {};
          const nPos = n.position || {};

          const isSelected = nId === selectedNode?.id;

          return (
            <li className={`${isSelected ? "active" : ""}`} key={`node-list-${nId}`}>
              <span className="">
                <Icon iconName="square" size={ICON_SIZE.MD} /> {nData.label}
              </span>
              <small className="text-secondary">
                ({Math.round(nPos.x)}, {Math.round(nPos.y)})
              </small>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function CanvasCard({
  nodes,
  setNodes,
  onNodesChange,
  edges,
  setEdges,
  onEdgesChange,
  onEdgeDeleteComplete,
  onNodeClick,
  onNodeDoubleClick,
  connectionLine,
  connectionLineStyle,
  onNodeDragComplete,
  onNodeDeleteComplete,
}) {
  const ref = useRef(null);
  const [menu, setMenu] = useState(null);

  const onConnect = useCallback(
    (params) => {
      console.log(params);
      setEdges((eds) => addEdge(params, eds));
    },
    [setEdges]
  );

  const onAddInputNode = useCallback(() => {
    const position = {
      // As of now we are harcoading the position but we need to decide on how we go abut this
      x: 227,
      y: -110,
    };
    addInputNode(position, setNodes);
  }, [setNodes]);

  const onNodeContextMenu = useCallback(
    (event, node) => {
      // Prevent native context menu from showing
      event.preventDefault();

      // Calculate position of the context menu. We want to make sure it doesn't get positioned off-screen.
      console.log(ref);
      // const pane = ref.current.getBoundingClientRect();
      // setMenu({
      //   id: node.id,
      //   top: event.clientY < pane.height - 200 && event.clientY,
      //   left: event.clientX < pane.width - 200 && event.clientX,
      //   right: event.clientX >= pane.width - 200 && pane.width - event.clientX,
      //   bottom: event.clientY >= pane.height - 200 && pane.height - event.clientY,
      // });
    },
    [setMenu]
  );

  const onPaneClick = useCallback(() => setMenu(null), [setMenu]);

  return (
    <div className="w-100 h-100 border">
      <ReactFlow
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onEdgesDelete={onEdgeDeleteComplete}
        onConnect={onConnect}
        fitView={true}
        snapGrid={true}
        deleteKeyCode={["Delete"]}
        onNodeClick={onNodeClick}
        onNodeDoubleClick={onNodeDoubleClick}
        onNodeContextMenu={onNodeContextMenu}
        connectionLineComponent={connectionLine}
        connectionLineStyle={connectionLineStyle}
        onNodeDragStop={onNodeDragComplete}
        onNodesDelete={onNodeDeleteComplete}
      >
        <Controls />
        <Background variant="dots" gap={12} size={1} />

        {menu && ( //
          <ContextMenu onClick={onPaneClick} {...menu} />
        )}

        <Panel position="top-right">
          <button onClick={onAddInputNode}>+ Input Node</button>
        </Panel>
      </ReactFlow>
    </div>
  );
}

/**
 * Page
 */
export default function ReactflowSandboxPage() {
  // Page State
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [selectedNode, setSelectedNode] = useState({});

  console.log("Edges : ", edges);

  function onNodeClick(e, node) {
    console.log("onNodeClick", node);
    setSelectedNode(node);
  }

  function onNodeDoubleClick(n) {
    // console.log("onNodeDoubleClick", n);
  }

  // const onNodeDragComplete = useCallback((node) => {

  // }, []);

  const onNodeDragComplete = useCallback((evt, node) => {
    evt.preventDefault();
    actionOnNodeDragComplete(evt, node);
  }, []);

  const onNodeDeleteComplete = useCallback((node) => {
    console.log("Deleted Node :", node);
  }, []);

  const onEdgeDeleteComplete = useCallback((edge) => {
    console.log("Delete Edge :", edge);
  }, []);

  return (
    <div className="page p-4 px-5">
      <PageHeader title={"Reactflow Sandbox"} />

      {/* Page Content */}
      <div className="page-content">
        <ReactFlowProvider>
          <div className="row">
            <div className="col-9">
              <CanvasCard
                nodes={nodes}
                setNodes={setNodes}
                onNodesChange={onNodesChange}
                edges={edges}
                setEdges={setEdges}
                onEdgesChange={onEdgesChange}
                onEdgeDeleteComplete={onEdgeDeleteComplete}
                onNodeClick={onNodeClick}
                onNodeDoubleClick={onNodeDoubleClick}
                connectionLine={ConnectionLine}
                connectionLineStyle={connectionLineStyle}
                onNodeDragComplete={onNodeDragComplete}
                onNodeDeleteComplete={onNodeDeleteComplete}
              />
            </div>
            <div className="col-3">
              <ConsoleCard nodes={nodes} edges={edges} selectedNode={selectedNode} />
            </div>
          </div>
        </ReactFlowProvider>
      </div>
    </div>
  );
}
