// Parameter Transformers
function transformObj(parameterObj = {}) {
  const parameter = {
    ...parameterObj,
  };

  return parameter;
}

function transformList(parameterObjList = []) {
  const parameterList = parameterObjList.map((obj) => {
    return transformObj(obj);
  });

  return parameterList;
}

// Exports
const ParameterTransformer = {
  transformObj,
  transformList,
};

export default ParameterTransformer;
