import { createSlice } from "@reduxjs/toolkit";

// Transfomer
import NodeTransformer from "./nodeTransformer";

// Util Functions
function updateNodePosition(positionMap, graphId, nodeId, x, y) {
  const clonePositionMap = { ...positionMap };
  if (positionMap[nodeId]) {
    const existingNode = positionMap[nodeId];
    existingNode["x"] = x;
    existingNode["y"] = y;
    clonePositionMap[nodeId] = existingNode;
    return clonePositionMap;
  }

  clonePositionMap[nodeId] = { graphId: graphId, x: x, y: y };
  return clonePositionMap;
}

function updateNodeInfo(nodeInfoMap, graphId, nodeId, nodeInfo) {
  const cloneNodeInfoMap = { ...nodeInfoMap };
  nodeInfo["graphId"] = graphId;
  cloneNodeInfoMap[nodeId] = nodeInfo;
  return cloneNodeInfoMap;
}

function updateNodeStyle(nodeStyleMap, nodeId, styleObj) {
  const cloneNodeStyleMap = { ...nodeStyleMap };
  cloneNodeStyleMap[nodeId] = styleObj;
  return cloneNodeStyleMap;
}

// Intital state
const initialState = {
  // Create Node State
  createNodeLoading: false,
  createNodeErr: "",
  createdNode: {},

  // Update Node Info
  updateNodeInfoLoading: false,
  updateNodeInfoError: "",
  nodeInfoMap: {},

  // Update Node Style
  updateNodeStyleLoading: false,
  updateNodeStyleError: "",
  nodeStyleMap: {},

  // Update Node Position States
  updateNodePositionLoading: false,
  updateNodePositionError: "",
  positionMap: {},

  // Update Node Equation Generate status
  updateNodeEqnGenStatusLoading: false,
  updateNodeEqnGenStatusError: "",

  // Delete Node States
  deleteNodeLoading: false,
  deleteNodeError: "",

  // Node Paramters
  createParameterLoading: false,
  createParameterError: "",
  parameterMap: {},

  // Update Node Parameters state
  updateParameterLoading: false,
  updateParameterError: "",

  // Delete Node Parameters state
  deleteParameterLoading: false,
  deleteParameterError: "",
};

// Node Slice
const nodeSlice = createSlice({
  // Name
  name: "Node",

  // Initial State
  initialState,

  // Reducers
  reducers: {
    // Create Node
    createNode: (state) => {
      state.createNodeLoading = true;
    },

    createNodeSuccess: (state, action) => {
      const { newNode = {} } = action.payload;
      const transformedCreatedNode = NodeTransformer.transformObj(newNode);

      state.createdNode = transformedCreatedNode;
      state.createNodeLoading = false;
      state.createNodeErr = "";
    },

    createNodeFailure: (state, action) => {
      state.createNodeLoading = false;
      state.createNodeErr = action.payload.error;
    },

    // Update Node Information
    updateNodeInfo: (state) => {
      state.updateNodeInfoLoading = true;
    },

    updateNodeInfoSuccess: (state, action) => {
      const { graphId, nodeId, nodeInfo } = action.payload;
      const transformedNodeInfo = NodeTransformer.transformObj(nodeInfo);

      const nodeInfoMap = updateNodeInfo(state.nodeInfoMap, graphId, nodeId, transformedNodeInfo);

      state.nodeInfoMap = nodeInfoMap;
      state.updateNodeInfoLoading = false;
      state.updateNodeInfoError = "";
    },

    updateNodeInfoFailure: (state, action) => {
      state.updateNodeInfoLoading = false;
      state.updateNodeInfoError = action.payload.error;
    },

    // Update Node Style
    updateNodeStyle: (state) => {
      state.updateNodeStyleLoading = true;
    },

    updateNodeStyleSuccess: (state, action) => {
      const { graphId, nodeId, nodeInfo } = action.payload;
      const transformedNodeInfo = NodeTransformer.transformObj(nodeInfo);

      const nodeStyleMap = updateNodeStyle(state.nodeInfoMap, graphId, nodeId, transformedNodeInfo);

      state.nodeStyleMap = nodeStyleMap;
      state.updateNodeStyleLoading = false;
      state.updateNodeStyleError = "";
    },

    updateNodeStyleFailure: (state, action) => {
      state.updateNodeStyleLoading = false;
      state.updateNodeStyleError = action.payload.error;
    },

    // Update Node Position
    updateNodePosition: (state) => {
      state.updateNodePositionLoading = true;
    },

    updateNodePositionSuccess: (state, action) => {
      const { graphId, nodeId, x, y } = action.payload;
      const positionMap = updateNodePosition(state.positionMap, graphId, nodeId, x, y);

      state.positionMap = positionMap;
      state.updateNodePositionLoading = false;
      state.updateNodePositionError = "";
    },

    updateNodePositionFailure: (state, action) => {
      state.updateNodePositionLoading = false;
      state.updateNodePositionError = action.payload.error;
    },

    // Update Node Equation Generate status
    updateNodeEqnGenStatus: (state) => {
      state.updateNodeEqnGenStatusLoading = true;
    },

    updateNodeEqnGenStatusSuccess: (state, action) => {
      state.updateNodeEqnGenStatusLoading = false;
      state.updateNodeEqnGenStatusError = "";
    },

    updateNodeEqnGenStatusFailure: (state, action) => {
      state.updateNodeEqnGenStatusLoading = false;
      state.updateNodeEqnGenStatusError = action.payload.error;
    },

    // Delete Node
    deleteNode: (state) => {
      state.deleteNodeLoading = true;
    },

    deleteNodeSuccess: (state, action) => {
      state.deleteNodeLoading = false;
      state.deleteNodeError = "";
    },

    deleteNodeFailure: (state, action) => {
      state.deleteNodeLoading = false;
      state.deleteNodeError = action.payload.error;
    },
  },
});

// Reducer
export const NodeReducer = nodeSlice.reducer;

// Actions
export const NodeActions = nodeSlice.actions;
