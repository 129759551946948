import { memo } from "react";
import { BaseEdge, EdgeLabelRenderer, getSimpleBezierPath } from "reactflow";

// Custom Hook
import { useUpdateQueryParams } from "../../hooks/useUpdateQueryParams";

// Graph
import GraphConfig from "../GraphConfig";

// Constant
import { QueryParamsKeys } from "../../constants/WebConstants";

//
// EdgeSideLabel
function EdgeLabel({ transform, symbol, showEdge, colorCode, name, style }) {
  return (
    <div
      style={{
        position: "absolute",
        transform,
        pointerEvents: "all",
      }}
      className="nodrag nopan cont-edge-label"
    >
      <div style={style}>
        {name && <span className="me-1">{name}</span>}
        {symbol && ( //
          <small className="text-secondary fst-italic">({symbol})</small>
        )}
      </div>
    </div>
  );
}

//
// FlowEdge
// ----------------------------------------------------------------------------

function FlowEdge(edge) {
  // Query Params
  const [searchParams, updateQueryParams] = useUpdateQueryParams();
  const showEdge = searchParams.get(QueryParamsKeys.showEdge) === "true" ? true : false;

  // Constants
  const VarType = GraphConfig.VarType;
  const VarTypeColorsMap = GraphConfig.VarTypeColorsMap;

  // Properties
  const { id, data = {} } = edge; // Basic

  // Path
  const { sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition } = edge; // Position
  const [edgePath, labelX, labelY] = getSimpleBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  // Data :: Var
  const varType = data.varType;
  const name = data.name;
  const symbol = data.symbol;

  // Style
  const colorCode = VarTypeColorsMap[varType];
  const pathStyle = {
    stroke: colorCode,
  };

  const edgeLabelStyle = {
    // visibility: showEdge ? "visible" : "hidden", TODO: uncomment when needed
    padding: "0 2px 20px 2px",
    fontSize: 10,
    fontWeight: 800,
  };

  const centerLabelStyle = {
    background: colorCode,
    padding: "4px 10px",
    borderRadius: 5,
    fontSize: 10,
    fontWeight: 600,
  };

  //
  //
  return (
    <>
      <BaseEdge id={id} path={edgePath} style={pathStyle} />
      <EdgeLabelRenderer>
        <EdgeLabel
          transform={`translate(0%, -50%) translate(${sourceX}px,${sourceY}px)`}
          symbol={symbol}
          showEdge={showEdge}
          style={edgeLabelStyle}
        />
        <EdgeLabel
          transform={`translate(-50%, -50%) translate(${labelX}px,${labelY}px)`}
          colorCode={colorCode}
          name={name}
          style={centerLabelStyle}
        />
        <EdgeLabel
          transform={`translate(-100%, -50%) translate(${targetX}px,${targetY}px)`}
          symbol={symbol}
          showEdge={showEdge}
          style={edgeLabelStyle}
        />
      </EdgeLabelRenderer>
    </>
  );
}

export default memo(FlowEdge);
