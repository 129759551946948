//
// OptRun Job Transformers
//

function transformObj(optRunJobObj) {
  const { id = "", updatedOn } = optRunJobObj;
  const optRunJob = {
    ...optRunJobObj,
  };

  return optRunJob;
}

function transformList(optRunJobObjList) {
  const optRunJobList = optRunJobObjList.map((obj) => {
    return transformObj(obj);
  });

  return optRunJobList;
}

// Exports
// ----------------------------------------------------------------------------

const OptRunJobTransformer = {
  transformObj,
  transformList,
};

export default OptRunJobTransformer;
