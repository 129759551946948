import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "antd";

// Actions
import { AuthActions } from "../../../redux-slice/auth/authSlice";

// Utils
import { getItem } from "../../../app/LocalStorage";

// Constants
import { LocalStorageKeys } from "../../../constants/GeneralConstants";

// Components
import Button from "../../../components/button/Button";
import Label from "../../../components/label/Label";
import Input from "../../../components/antd/input/Input";

// Page Components
function ProfileCard({ authUserInfo = {} }) {
  // User Information
  const { firstName = "", lastName = "", phoneNo = "", emailId = "", org = {} } = authUserInfo || {};

  // Organization Information
  const { name = "" } = org;

  return (
    <>
      <h2>Profile</h2>

      <div className="row mt-3">
        <div className="col-6">
          <Label title="Name" className="fw-bold" />
          <div>
            {firstName} {lastName}
          </div>
        </div>
        <div className="col-6">
          <Label title="Organization Name" className="fw-bold" />
          <div>{name}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-6 mt-4">
          <Label title="Email" className="fw-bold" />
          <div>{emailId}</div>
        </div>
        <div className="col-6 mt-4">
          <Label title="Phone Number" className="fw-bold" />
          <div>{phoneNo}</div>
        </div>
      </div>
    </>
  );
}

function ResetPassword({ authUserInfo = {} }) {
  // Dispatch
  const dispatch = useDispatch();

  // State
  const [validationError, setValidationError] = useState("");

  // Selector State
  const resetPasswordLoading = useSelector((state) => state.auth.resetPasswordLoading);

  // Auth User Information
  const { emailId = "" } = authUserInfo;

  const [form] = Form.useForm();

  function validateConfirmPassword(_, value) {
    const isPasswordInvalid = value && value !== form.getFieldValue("newPassword");

    if (isPasswordInvalid) {
      return Promise.reject(new Error("Passwords do not match!"));
    }

    return Promise.resolve();
  }

  function handleSubmit() {
    form
      .validateFields()
      .then((values) => {
        const { newPassword = "" } = values;
        setValidationError("");
        dispatch(AuthActions.resetPassword({ email: emailId, newPassword }));
      })
      .catch((info) => setValidationError(info.errorFields[0].errors[0]));
  }

  return (
    <div className="mt-2">
      <Form layout="vertical" form={form}>
        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[
            {
              required: true,
              message: "Please enter your new password!",
            },
          ]}
        >
          <Input type="Password" placeholder="Enter your password" />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "Please enter confirm password!",
            },
            {
              validator: validateConfirmPassword,
            },
          ]}
        >
          <Input type="Password" placeholder="Enter your password" />
        </Form.Item>
        <Form.Item>
          <Button
            className="btn btn-warning float-end mt-3"
            onClick={handleSubmit}
            loading={resetPasswordLoading}
            dispatch={resetPasswordLoading}
          >
            Reset
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

/**
 * Profile Page
 */
export default function ProfilePage() {
  // Getting user information
  const authUserInfo = JSON.parse(getItem(LocalStorageKeys.AUTH_USER) || "{}") || {};

  return (
    <div className="container-fluid mt-4">
      <div className="row page-content gx-4">
        <div className="col-2"></div>
        <div className="col-4 card p-4">
          {/* Profile Card */}
          <ProfileCard authUserInfo={authUserInfo} />
        </div>

        <div className="col-4">
          <div className="card p-4">
            <h2>Reset Password</h2>

            {/* Reset Password */}
            <ResetPassword authUserInfo={authUserInfo} />
          </div>
        </div>
      </div>
    </div>
  );
}
