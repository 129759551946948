// Utils
import UrlUtils from "../../utils/urlUtils";

// URLs
import { EdgeUrls } from "../../constants/serviceUrls";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Create Edge
const createEdge = async function (graphId, edgeInfo) {
  // API URL
  const apiURL = UrlUtils.format(EdgeUrls.createEdge, { graphId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(apiURL, {}, edgeInfo);
};

// Update Edge Info
const updateEdgeInfo = async function (graphId, edgeUid, edgeInfo) {
  // Params
  const params = {
    type: edgeInfo.type,
  };

  // API URL
  const apiURL = UrlUtils.format(EdgeUrls.updateEdgeInfoByUid, { graphId, edgeUid });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiURL, params);
};

// Update Edge Var Info
const updateEdgeVarInfo = async function (graphId, edgeUid, varInfo) {
  // Params
  const params = {
    varType: varInfo.varType,
    name: varInfo.name,
    symbol: varInfo.symbol,
  };

  // API URL
  const apiURL = UrlUtils.format(EdgeUrls.updateEdgeVarInfoByUid, { graphId, edgeUid });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiURL, params);
};

// Update Edge Style
const updateEdgeStyle = async function (graphId, edgeUid, styleObj) {
  // API URL
  const apiURL = UrlUtils.format(EdgeUrls.updateEdgeStyleByUid, { graphId, edgeUid });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiURL, {}, styleObj);
};

// Delete Edge
const deleteEdge = async function (graphId, edgeUid) {
  // API URL
  const apiURL = UrlUtils.format(EdgeUrls.deleteEdgeByUid, { graphId, edgeUid });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.delete(apiURL);
};

// Export
// ----------------------------------------------------------------------------

const EdgeService = {
  //
  createEdge,

  //
  updateEdgeInfo,
  updateEdgeVarInfo,
  updateEdgeStyle,

  //
  deleteEdge,
};

export default EdgeService;
