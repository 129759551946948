// Node Transformers
function transformObj(nodeObj = {}) {
  const node = {
    ...nodeObj,
  };

  return node;
}

function transformList(nodeObjList = []) {
  const nodeList = nodeObjList.map((obj) => {
    return transformObj(obj);
  });

  return nodeList;
}

// Exports
const NodeTransformer = {
  transformObj,
  transformList,
};

export default NodeTransformer;
