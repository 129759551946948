import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Actions
import { GraphActions } from "../../../redux-slice/graph/graphSlice";
import { OptSetupActions } from "../../../redux-slice/project/optSetupSlice";
import { ProjectActions } from "../../../redux-slice/project/projectSlice";
import { OptRunActions } from "../../../redux-slice/project/optRunSlice";

// Hooks
import { useUpdateQueryParams } from "../../../hooks/useUpdateQueryParams";

// Constants
import { QueryParamsKeys } from "../../../constants/WebConstants";
import { ICON_SIZE } from "../../../constants/GeneralConstants";
import PageURL from "../../../constants/pageUrls";

// Utils
import GraphHelper from "../../../helpers/graphHelper";
import UrlUtils from "../../../utils/urlUtils";

// Components
import Button from "../../../components/button/Button";
import Icon from "../../../components/icon/Icon";
import PageHeader from "../../../app/layout/PageHeader";

// Sections
import BoundsCard from "./components/BoundsCard";
import ObjectiveFunctionCard from "./components/ObjectiveFunctionCard";
import AdvanceConfigCard from "./components/AdvanceConfigCard";
import LogViewModal from "./components/LogViewModal";
import ObjectiveFunctionSolutionCard from "./components/ObjectiveFunctionSolutionCard";
import ConstraintsCard from "./components/ConstraintsCard";

// Helper Functions
function constructFormData(data = [], optSetup = {}, optRun = {}) {
  // Opt Setup
  const { varFixedValues = {}, varMinBounds = {}, varMaxBounds = {} } = optSetup;
  const { solution = {} } = optRun;

  return data.reduce((acc, eachData) => {
    const { symbol = "" } = eachData || {};

    const obj = {
      min: varMinBounds[symbol] ?? "",
      max: varMaxBounds[symbol] ?? "",
      value: varFixedValues[symbol] ?? "",
      solution: solution[symbol] ?? "",
    };

    return { ...acc, [symbol]: obj };
  }, {});
}

function getObjectiveArray(objectiveInfo) {
  const { name = "", isMinimize = true, costExpression = "" } = objectiveInfo || {};
  const objectivesArray = [{ name, isMinimize, costExpression }];
  return objectivesArray;
}

function getParsedValues(boundsData = {}, varSymbol = "") {
  const { value = "", min = "", max = "" } = boundsData[varSymbol] || {};

  // Parsing min, max and value
  const parsedValue = parseFloat(value) ?? "";
  const parsedMin = parseFloat(min) ?? "";
  const parsedMax = parseFloat(max) ?? "";
  return { parsedValue, parsedMin, parsedMax };
}

function validateValue(value = "") {
  return !isNaN(value) && `${value}`;
}

function getVarValues(boundsData = {}) {
  const varFixedValues = {};
  const varMinBounds = {};
  const varMaxBounds = {};

  // Constructing var values
  Object.keys(boundsData).forEach((varSymbol) => {
    const { parsedValue, parsedMin, parsedMax } = getParsedValues(boundsData, varSymbol);

    // TODO: Try to handle zero value, instead of converting to string in the condition.
    // Checking for Nan and Zero
    if (validateValue(parsedValue)) {
      varFixedValues[varSymbol] = parsedValue;
    } else {
      if (validateValue(parsedMin)) {
        varMinBounds[varSymbol] = parsedMin;
      }
      if (validateValue(parsedMax)) {
        varMaxBounds[varSymbol] = parsedMax;
      }
    }
  });

  return { varFixedValues, varMinBounds, varMaxBounds };
}

// Page Components
function SaveAndRunButton({ objectiveInfo = {}, boundsData = {}, config = {}, navigate, dispatch }) {
  // Page Params
  const { projectId = "", optSetupId = "" } = useParams();
  const graphId = projectId; // graphId is just "projectId"

  const createOptSetupLoading = useSelector((state) => state.optSetup.createOptSetupLoading);
  const updateOptSetupLoading = useSelector((state) => state.optSetup.updateOptSetupLoading);
  const isPageLoading = createOptSetupLoading || updateOptSetupLoading;

  // Save and Setup
  function saveAndRun() {
    const { name = "" } = objectiveInfo;

    const objectivesArray = getObjectiveArray(objectiveInfo);

    // Var Bounds
    const { varFixedValues, varMinBounds, varMaxBounds } = getVarValues(boundsData);

    // Form Data
    const optRunObj = {
      projectId,
      graphId,
      name,
      objectives: objectivesArray,
      varFixedValues,
      varMinBounds,
      varMaxBounds,

      config: { ...config },
      // ...varValues,
    };

    // Dispatch
    dispatch(OptRunActions.createOptRun({ projectId, optSetupId, optRunObj, navigate }));
  }

  return (
    <Button
      className="btn btn-secondary"
      icon={<Icon iconName="play" color="white" size={ICON_SIZE.MD} />}
      onClick={saveAndRun}
      loading={isPageLoading}
      disabled={isPageLoading}
    >
      Save and Run
    </Button>
  );
}

function OptRunViewButtons({ navigate }) {
  const { projectId = "", optSetupId = "" } = useParams();

  function navigateToOptListPage() {
    const url = UrlUtils.format(PageURL.OptSetupListPageURL, { projectId });
    navigate(url);
  }

  function navigateToOptRunListPage() {
    const url = UrlUtils.format(PageURL.OptRunListPageURL, { projectId, optSetupId });
    navigate(url);
  }

  return (
    <div className="d-flex align-items-center">
      <Button className="btn-outline-primary" onClick={navigateToOptListPage}>
        Back to Opt Setups
      </Button>

      <Button className="btn-outline-secondary" onClick={navigateToOptRunListPage}>
        View Solutions
      </Button>
    </div>
  );
}

function PageHeaderSection({ optRunId = "", objectiveInfo = {}, boundsData = {}, config = {} }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Content
  const pageTitle = "Optimization Problem Setup";
  const pageActions = (
    <div className="btn-cont">
      {optRunId && <OptRunViewButtons navigate={navigate} />}

      {/* Save And Run Button */}
      {!optRunId && (
        <SaveAndRunButton
          boundsData={boundsData}
          objectiveInfo={objectiveInfo}
          config={config}
          navigate={navigate}
          dispatch={dispatch}
        />
      )}
    </div>
  );

  return <PageHeader title={pageTitle} actions={pageActions} />;
}

/**
 * Opt Run Page
 */
export default function OptRunPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Page Params
  const { projectId = "", optSetupId = "" } = useParams();
  const graphId = projectId; // graphId is just "projectId"

  // State
  const [boundsData, setBoundsData] = useState({});
  const [objectiveInfo, setObjectiveInfo] = useState("");
  const [advanceConfig, setAdvanceConfig] = useState({});
  const [openLogModal, setOpenLogViewModal] = useState(false);

  const [searchParams, updateQueryParams] = useUpdateQueryParams();
  const optRunId = searchParams.get(QueryParamsKeys.optRunId) ?? "";

  // Selector State
  const graphData = useSelector((state) => state.graph.graphData);
  const optSetup = useSelector((state) => state.optSetup.optSetup);

  const optRun = useSelector((state) => state.optRun.optRun);
  const isOptRunSuccess = useSelector((state) => state.optRun.isOptRunSuccess);

  //
  // Graph Data
  const { edges = [], parameters = [], equations = [] } = graphData || {};

  // OptSetup Information
  const { objectives = [] } = optSetup || {};

  // Dispatch API calls
  useEffect(() => {
    dispatch(ProjectActions.getProject({ projectId }));
    dispatch(GraphActions.getGraphData({ graphId }));
  }, [projectId, graphId, dispatch]);

  useEffect(() => {
    const { id = "" } = optRun || {};
    const isOptIdChanged = !id && id !== optRunId;

    if (isOptIdChanged) {
      dispatch(OptRunActions.getOptRun({ projectId, optSetupId, optRunId }));
    }
  }, [optRun, projectId, optSetupId]);

  useEffect(() => {
    if (optSetupId) {
      dispatch(OptSetupActions.getOptSetup({ projectId, optSetupId }));
    }
  }, [projectId, optSetupId, dispatch]);

  useEffect(() => {
    if (optSetupId) {
      const objInfo = objectives[0] || {};
      setObjectiveInfo(objInfo);
    } else {
      setObjectiveInfo({});
    }
  }, [optSetupId, objectives]);

  useEffect(() => {
    if (isOptRunSuccess) {
      const params = { optRunId: optRun.id };
      updateQueryParams({ params });
    }
  }, [isOptRunSuccess]);

  useEffect(() => {
    if (optSetupId) {
      // Combining data of parameters and edges
      const data = [...parameters, ...edges];

      setBoundsData(constructFormData(data, optSetup, optRun));
      setAdvanceConfig(optSetup?.config || {});
    } else {
      setBoundsData({});
      setAdvanceConfig({});
    }
  }, [graphData, optSetup, optRun, optSetupId]);

  //
  return (
    <>
      {/* Page Content */}
      <div className="main-cont position-right-0">
        <div className="content-wrapper">
          {/** Header */}
          <PageHeaderSection
            optRunId={optRunId}
            objectiveInfo={objectiveInfo}
            boundsData={boundsData}
            config={advanceConfig}
          />

          {/* Page Content */}
          <div className="page-content">
            <div className="row my-4">
              <div className="col-5">
                {/** Objective Function */}
                <ObjectiveFunctionCard objectiveInfo={objectiveInfo} setObjectiveInfo={setObjectiveInfo} />

                {/** Constraints */}
                <ConstraintsCard equations={equations} />
              </div>
              <div className="col-7">
                <AdvanceConfigCard advanceConfig={advanceConfig} setAdvanceConfig={setAdvanceConfig} />

                {/* Objective Function Solution Card */}
                <ObjectiveFunctionSolutionCard optRunId={optRunId} setOpenModal={setOpenLogViewModal} />

                {/** Bounds */}
                <BoundsCard boundsData={boundsData} setBoundsData={setBoundsData} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <LogViewModal openModal={openLogModal} setOpenModal={setOpenLogViewModal} />
    </>
  );
}
