import { Modal as AntdModal } from "antd";

// Component
import Button from "../../button/Button";

const MODAL_SIZE = {
  sm: 400,
  md: 600,
  lg: 800,
  xl: 1000,
};

// Page Components
function OkAndCancelButton({
  onCancel = () => {},
  onOk = () => {},
  isOkButtonLoading = false,
  isOkBtnDisabled = false,
}) {
  return (
    <>
      <Button onClick={onCancel} className="btn btn-outline-dark">
        Cancel
      </Button>

      <Button className="btn btn-primary" disabled={isOkBtnDisabled} loading={isOkButtonLoading} onClick={onOk}>
        Ok
      </Button>
    </>
  );
}

/**
 * Modal
 * @param {*} title : String
 * @param {*} isModalOpen : Boolean
 * @param {*} isVerticallyCentered : Boolean
 * @param {*} onOk : Function
 * @param {*} onCancel : Function
 * @param {*} footer : component
 * @param {*} isModalLoading : Boolean
 * @param {*} isOkAndCancelBtnPresent : Boolean
 * @param {*} isOkButtonLoading : Boolean
 * @param {*} isOkBtnDisabled : Boolean
 * @param {*} width : String
 */
export default function Modal({
  title = "",
  footer = <></>,
  isModalOpen = false,
  isVerticallyCentered = false,
  onOk = () => {},
  onCancel = () => {},
  isModalLoading = false,
  isOkAndCancelBtnPresent = false,
  isOkButtonLoading = false,
  isOkBtnDisabled = false,
  size = "lg",
  children,
  destroyOnClose = false,
  ...rest
}) {
  const width = MODAL_SIZE[size];

  function getModalFooter() {
    if (isOkAndCancelBtnPresent) {
      return (
        <div className="d-flex align-items-center gap-2 justify-content-end">
          <OkAndCancelButton />

          {footer}
        </div>
      );
    }

    return footer;
  }

  return (
    <AntdModal
      title={title}
      open={isModalOpen}
      centered={isVerticallyCentered}
      onOk={onOk}
      onCancel={onCancel}
      loading={isModalLoading}
      footer={getModalFooter}
      width={width}
      destroyOnClose={destroyOnClose}
      {...rest}
    >
      {children}
    </AntdModal>
  );
}
