import React from "react";
import { useDispatch } from "react-redux";
import { useReactFlow } from "reactflow";

// Actions
import { NodeActions } from "../../../redux-slice/graph/nodeSlice";

// Constants
import { ICON_SIZE } from "../../../constants/GeneralConstants";

// Components
import CardCollapsible from "../../../components/card-collapsible/CardCollapsible";
import InlineEdit from "../../../components/inline-edit/InlineEdit";
import Icon from "../../../components/icon/Icon";

// Config
import GraphConfig from "../../../reactflow/GraphConfig";
import ReactFlowExt from "../../../reactflow/ReactFlowExt";

//
import GraphEquationsCard from "./card/GraphEquationsCard";
import GraphParametersCard from "./card/GraphParametersCard";

//
// Constants
const NodeType = GraphConfig.NodeType;
const NodeTypeNameMap = GraphConfig.NodeTypeNameMap;

const VarTypeNameMap = GraphConfig.VarTypeNameMap;
const EdgeVarTypes = GraphConfig.EdgeVarTypes;

//
// Component
// ----------------------------------------------------------------------------

//
function EdgesList({ title, edges = [] }) {
  return (
    <>
      <p className="mb-1">{title}</p>
      <ul className="list-unstyled list-graph-elems">
        {edges.map((e) => {
          const eId = e.id;
          const eData = e.data || {};

          return (
            <li className="" key={`edge-list-${eId}`}>
              <span className="">
                <Icon iconName="chevronRight" size={ICON_SIZE.MD} /> {eData.name}
              </span>
              <span className="text-secondary">({eData.symbol})</span>
            </li>
          );
        })}
      </ul>
    </>
  );
}

//
export default function SectionNodeInfo({ graphId, node = {}, inputEdges = [], outputEdges = [] }) {
  // dispatch
  const dispatch = useDispatch();

  // Graph State functions
  const { setNodes } = useReactFlow();

  // Node Info
  const nodeUid = node.id;
  const nodeType = node.type;
  const nodePos = node.position || {};
  const nodeData = node.data || {};

  const nodeName = nodeData.name;
  const nodeSymbol = nodeData.symbol;
  const nodeTypeName = NodeTypeNameMap[nodeType];

  const nodeVarType = nodeData.varType;

  // Parameters & Equations
  const parameters = nodeData.parameters || [];
  const equations = nodeData.equations || [];

  const paramVarPrefix = nodeData.symbol || "";

  // Variables
  const inpEdgeVars = inputEdges.map(({ data }) => data);
  const outEdgeVars = outputEdges.map(({ data }) => data);
  const nodeVariables = [...inpEdgeVars, ...outEdgeVars, ...parameters];

  // Settings
  const generateEqns = nodeData.generateEqns || false;

  //
  //
  // Component Functions
  const updateNodeData = ReactFlowExt.useUpdateNodeData(nodeUid);

  const onNodeVarTypeChange = React.useCallback(
    ({ target }) => {
      // Data Updates
      const newVarType = target.value;
      const dataUpdates = { name: nodeName, symbol: nodeSymbol, varType: newVarType };

      // Dispatch Node Update Action
      dispatch(NodeActions.updateNodeInfo({ graphId, nodeUid, nodeInfo: dataUpdates }));

      // Callback
      updateNodeData(dataUpdates);
    },
    [graphId, nodeUid, nodeName, nodeSymbol, updateNodeData, dispatch]
  );

  const onNodeNameChange = React.useCallback(
    (newName) => {
      // Data Update
      const dataUpdates = { name: newName, symbol: nodeSymbol, varType: nodeVarType };

      // Dispatch Node Update Action
      dispatch(NodeActions.updateNodeInfo({ graphId, nodeUid, nodeInfo: dataUpdates }));

      // Callback
      updateNodeData(dataUpdates);
    },
    [graphId, nodeUid, nodeSymbol, nodeVarType, updateNodeData, dispatch]
  );

  const onNodeEqnGenChange = React.useCallback(
    ({ target }) => {
      // Data Updates
      const newEqnGenVal = target.checked;

      // Dispatch Node Update Action
      dispatch(NodeActions.updateNodeEqnGenStatus({ graphId, nodeUid, generateEqns: newEqnGenVal }));

      // Callback
      updateNodeData({ generateEqns: newEqnGenVal });
    },
    [graphId, nodeUid, updateNodeData, dispatch]
  );

  //
  return (
    <>
      <div className="sec-info p-3">
        <h6 className="px-1 mb-2 d-flex justify-content-between">
          <span>Node</span>
          <small className="text-secondary">({nodeTypeName})</small>
        </h6>
      </div>

      {/** Node Info */}
      <div className="sec-info px-3 py-2">
        <div className="row">
          <label className="col-3 col-form-label">Name</label>
          <div className="col-9">
            <InlineEdit className="form-control" value={nodeName || "--"} setValue={onNodeNameChange} />
          </div>
        </div>

        {(nodeType === NodeType.SOURCE || nodeType === NodeType.SINK) && ( //
          <div className="row">
            <label className="col-3 col-form-label">Type</label>
            <div className="col-9">
              <select
                className="form-select form-select-sm border-0 px-2"
                onChange={onNodeVarTypeChange}
                value={nodeVarType}
              >
                <option value={""}>--</option>
                {EdgeVarTypes.map((vt) => {
                  const varTypeName = VarTypeNameMap[vt];
                  return (
                    <option key={vt} value={vt}>
                      {varTypeName}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        )}
      </div>

      {/** Connected Edges */}
      <div className="sec-info">
        <CardCollapsible
          header={"Connected Edges (Variables)"}
          collapsed={true}
          className="pb-1"
          id="card-sel-node-edges"
        >
          {/** */}
          <EdgesList title="Inputs" edges={inputEdges} />
          <EdgesList title="Outputs" edges={outputEdges} />
        </CardCollapsible>
      </div>

      <div className="sec-info">
        <GraphParametersCard
          collapsed={false}
          graphId={graphId}
          nodeUid={nodeUid}
          parameters={parameters}
          parameterVarPrefix={paramVarPrefix}
        />
      </div>

      <div className="sec-info">
        <GraphEquationsCard
          collapsed={false}
          graphId={graphId}
          nodeUid={nodeUid}
          equations={equations}
          variables={nodeVariables}
        />
      </div>

      <div className="sec-info">
        <CardCollapsible header={"Settings"} collapsed={false} className="pb-1">
          {/** */}
          <ul className="list-unstyled list-graph-elems">
            <li className="">
              <span className="">Auto-generate Equations</span>
              <span className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={generateEqns}
                  onChange={onNodeEqnGenChange}
                />
              </span>
            </li>
          </ul>
        </CardCollapsible>
      </div>

      <div className="sec-info d-none">
        <CardCollapsible header={"Data"} collapsed={false} className="pb-1">
          {/** */}
        </CardCollapsible>
      </div>
    </>
  );
}
