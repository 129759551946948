//
import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { OptRunActions } from "../../redux-slice/project/optRunSlice";
import { setErrorInfo } from "../../app/error/ErrorActions";

// Service
import OptRunService from "../../services/project/optRunService";

// Utils
import UrlUtils from "../../utils/urlUtils";

// Constants
import PageURL from "../../constants/pageUrls";

//
// Saga Functions
// ----------------------------------------------------------------------------

// Create OptRun
function* createOptRun(action) {
  const { projectId, optSetupId, optRunObj, navigate } = action.payload;
  try {
    const optRun = yield OptRunService.createOptRun(projectId, optSetupId, optRunObj);

    // Dispatching Action
    yield put(OptRunActions.createOptRunSuccess({ optRun }));

    // Toaster
    toast.success("Saved successfully");
  } catch (error) {
    yield put(OptRunActions.createOptRunFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

//
// Delete OptRun
function* deleteOptRun(action) {
  const { projectId, optSetupId, optRunId } = action.payload;
  try {
    yield OptRunService.deleteOptRun(projectId, optSetupId, optRunId);

    // Dispatching Action
    yield put(OptRunActions.deleteOptRunSuccess());

    // Toaster
    toast.success("Deleted successfully");
  } catch (error) {
    yield put(OptRunActions.deleteOptRunFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Delete OptRuns by OptSetup
function* deleteOptRunsBySetup(action) {
  const { projectId, optSetupId } = action.payload;
  try {
    yield OptRunService.deleteOptRunsBySetup(projectId, optSetupId);

    // Dispatching Action
    yield put(OptRunActions.deleteOptRunsBySetupSuccess());

    // Toaster
    toast.success("Deleted OptRuns successfully");
  } catch (error) {
    yield put(OptRunActions.deleteOptRunsBySetupFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Get OptRun
function* getOptRun(action) {
  const { projectId, optSetupId, optRunId } = action.payload;
  try {
    const optRun = yield OptRunService.getOptRun(projectId, optSetupId, optRunId);

    // Dispatching Action
    yield put(OptRunActions.getOptRunSuccess({ optRun }));
  } catch (error) {
    yield put(OptRunActions.getOptRunFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Get OptRun list
function* searchOptRuns(action) {
  try {
    const { projectId, optSetupId, searchParams = {}, pageNumber, pageSize } = action.payload;

    const { items, pagination } = yield OptRunService.searchOptRuns(
      projectId,
      optSetupId,
      searchParams,
      pageNumber,
      pageSize
    );

    // Dispatching Action
    yield put(OptRunActions.searchOptRunsSuccess({ items, pagination }));
  } catch (error) {
    yield put(OptRunActions.searchOptRunsFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Get OptRun Problem
function* getOptRunProblem(action) {
  const { projectId, optRunId, format } = action.payload;
  try {
    const optRunProblem = yield OptRunService.getOptRunProblem(projectId, optRunId, format);

    // Dispatching Action
    yield put(OptRunActions.getOptRunProblemSuccess({ optRunProblem }));
  } catch (error) {
    yield put(OptRunActions.getOptRunProblemFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

//
// Export

export default function* root() {
  yield all([
    //
    takeLatest(OptRunActions.createOptRun.type, createOptRun),

    //
    takeLatest(OptRunActions.deleteOptRun.type, deleteOptRun),
    takeLatest(OptRunActions.deleteOptRunsBySetup.type, deleteOptRunsBySetup),

    //
    takeLatest(OptRunActions.getOptRun.type, getOptRun),
    takeLatest(OptRunActions.searchOptRuns.type, searchOptRuns),

    //
    takeLatest(OptRunActions.getOptRunProblem.type, getOptRunProblem),
  ]);
}
