import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Form, Select, Upload } from "antd";

// Actions
import { ProjectFileActions } from "../../../../redux-slice/project/projectFileSlice";

// Constants
import { ICON_SIZE } from "../../../../constants/GeneralConstants";

// Components
import Modal from "../../../../components/antd/modal/Modal";
import Button from "../../../../components/button/Button";
import Input from "../../../../components/antd/input/Input";
import Icon from "../../../../components/icon/Icon";

// Page Constants
const FILE_KINDS = [
  { value: "REFERENCE", name: "Reference" },
  { value: "DATA", name: "Data" },
  { value: "REPORT", name: "Report" },
];

// Helper functions
function getCreateFileFormData(values = {}) {
  const { file = [], fileKind = "", title = "", notes = "" } = values;
  const { originFileObj = {} } = file[0] || {};

  const formData = new FormData();
  formData.append("file", originFileObj);
  formData.append("fileKind", fileKind);
  formData.append("title", title);
  formData.append("notes", notes);

  return formData;
}

function getFile(e) {
  const isArray = Array.isArray(e);

  if (isArray) {
    return e;
  }
  return e && e.fileList;
}

/**
 * Create And Edit Project File Modal
 * @param {*} isModalOpen : Boolean
 * @param {*} setIsModalOpen : Function
 * @param {*} param0
 */
export default function CreateAndEditProjectFileModal({
  fileInfo = {},
  isModalOpen = false,
  setIsModalOpen = () => {},
}) {
  const dispatch = useDispatch();

  const { projectId = "" } = useParams();

  const { id: fileId = "" } = fileInfo;

  const fileInfoLoading = useSelector((state) => state.projectFile.fileInfoLoading);
  const createFileLoading = useSelector((state) => state.projectFile.createFileLoading);
  const updateFileInfoLoading = useSelector((state) => state.projectFile.updateFileInfoLoading);
  const fileListPagination = useSelector((state) => state.projectFile.fileListPagination);

  const { pageNo = "" } = fileListPagination || {};

  const loadingStatus = createFileLoading || updateFileInfoLoading;

  const isFileInfoPresent = Object.keys(fileInfo).length !== 0;
  const btnAndModalTitle = isFileInfoPresent ? "Update File" : "Create File";

  const [form] = Form.useForm();

  function dispatchUpdateFileInfoEvent(values) {
    const fileData = { ...values, projectId };
    dispatch(ProjectFileActions.updateFileInfo({ projectId, fileId, fileData, pageNumber: pageNo }));
  }

  function dispatchCreateFileEvent(values) {
    const fileData = getCreateFileFormData(values);
    dispatch(ProjectFileActions.createFile({ projectId, fileData, pageNumber: pageNo }));
  }

  function onSave(values) {
    if (isFileInfoPresent) {
      dispatchUpdateFileInfoEvent(values);
      return;
    }
    dispatchCreateFileEvent(values);
  }

  function closeModal() {
    form.resetFields();
    setIsModalOpen(false);
  }

  useEffect(() => {
    const { fileKind = "", notes = "", title = "" } = fileInfo;

    form.setFieldsValue({ fileKind, notes, title });
  }, [fileInfo]);

  return (
    <Modal
      title={btnAndModalTitle}
      isModalOpen={isModalOpen}
      onCancel={closeModal}
      destroyOnClose={true}
      loading={fileInfoLoading}
    >
      <Form form={form} layout="vertical" onFinish={onSave}>
        <Form.Item
          name="fileKind"
          label="File Kind"
          rules={[
            {
              required: true,
              message: "File Kind is required !!",
            },
          ]}
        >
          <Select placeholder="Select Variable Type">
            {FILE_KINDS.map((fileKind) => {
              const { name = "", value = "" } = fileKind;

              return (
                <Select.Option key={value} value={value}>
                  {name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input placeholder="Enter Title" />
        </Form.Item>

        <Form.Item name="notes" label="Notes">
          <Input type="TextArea" placeholder="Enter notes" />
        </Form.Item>

        {!isFileInfoPresent && (
          <Form.Item
            label="Upload File"
            name="file"
            valuePropName="fileList"
            getValueFromEvent={(e) => getFile(e)}
            rules={[
              {
                required: true,
                message: "Please upload a file !!",
              },
            ]}
          >
            <Upload beforeUpload={() => false} maxCount={1}>
              <Button
                type="button" // Prevents form submission
                className="btn-outline-secondary"
                icon={<Icon size={ICON_SIZE.SM} iconName="upload" />}
              >
                Click to Upload
              </Button>
            </Upload>
          </Form.Item>
        )}

        <Form.Item>
          <Button className="btn-primary float-end" type="submit" loading={loadingStatus} disabled={loadingStatus}>
            {btnAndModalTitle}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
