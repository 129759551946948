//

// Components
import NavBranding from "./NavBranding";

// Constants
import PageURL from "../../constants/pageUrls";

// Images
import URL_IMG_LOGO from "../../assests/imgs/branding/logo.png";
import URL_IMG_LOGO_TEXT from "../../assests/imgs/branding/logo_alt.png";

//
// Components
// ----------------------------------------------------------------------------

/**
 * Main Component
 */
export default function NavTop({
  sectionLeft = <></>,
  sectionCenter = <></>,
  sectionRight = <></>,
  sidebarCollapsible = false,
  showSideBar,
  setShowSideBar,
}) {
  // Header Logo URL
  const URL_LOGO = showSideBar ? URL_IMG_LOGO_TEXT : URL_IMG_LOGO;

  //
  return (
    <header className={`topbar ${!showSideBar && "collapsed"}`}>
      <div className="d-flex">
        {/* Logo */}
        <NavBranding className="" logoURL={URL_LOGO} redirectURL={PageURL.DashboardPageURL} />

        {/** Section ::Left */}
        <div>{sectionLeft}</div>
      </div>

      {/** Section :: Center */}
      <div>{sectionCenter}</div>

      {/** Section :: Right */}
      <div className="me-4">{sectionRight}</div>
    </header>
  );
}
