//
// Component

/**
 * Objective Function Card
 * @param {*} param0
 * @returns
 */
export default function ObjectiveFunctionCard({ objectiveInfo = {} }) {
  // Objective Information
  const { name = "", isMinimize = "", costExpression = "" } = objectiveInfo || {};

  //
  return (
    <div className="card card-info mb-3">
      <div className="card-body">
        {/** */}
        <div className="row mb-2">
          <label className="col-2 col-form-label">Name</label>
          <div className="col-10">
            <label className="py-2">{name}</label>
          </div>
        </div>
        <div className="row mb-2">
          <label className="col-2 col-form-label">Objective</label>
          <div className="col-10">
            <label className="py-2">{isMinimize ? "Minimize" : "Maximize"}</label>
          </div>
        </div>
        <div className="row mb-2">
          <label className="col-2 col-form-label">Cost</label>
          <div className="col-10">
            <label className="py-2">{costExpression}</label>
          </div>
        </div>
      </div>
    </div>
  );
}
