// Utils
import HttpClientFactory from "../../app/http/HttpClientFactory";
import UrlUtils from "../../utils/urlUtils";

// Constants
import { ProjectFileUrls } from "../../constants/serviceUrls";
import { DefaultPagination } from "../../constants/WebConstants";
import { ContentType } from "../../app/http/HttpConstants";

// Create File
async function createFile(projectId, fileData) {
  const apiURL = UrlUtils.format(ProjectFileUrls.createFile, { projectId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(apiURL, {}, fileData, ContentType.MULTIPART_FORM_DATA);
}

// Update File
async function updateFileInfo(projectId, fileId, fileData) {
  // API URL
  const apiURL = UrlUtils.format(ProjectFileUrls.updateFileInfo, { projectId, fileId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiURL, {}, fileData);
}

// Download File
async function downloadFile(url) {
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.downloadPublicFile(url);
}

// Get File
async function getFileInfo(projectId, fileId) {
  // API URL
  const apiURL = UrlUtils.format(ProjectFileUrls.getFileInfo, { projectId, fileId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(apiURL);
}

// Search Files
async function searchFiles(projectId, pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (searchText) {
    params.q = searchText;
  }

  // API URL
  const apiURL = UrlUtils.format(ProjectFileUrls.searchFiles, { projectId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(apiURL, params);
}

// Exports
const ProjectFileService = {
  //
  createFile,

  //
  updateFileInfo,
  downloadFile,

  //
  getFileInfo,
  searchFiles,
};

export default ProjectFileService;
