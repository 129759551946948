import { createSlice } from "@reduxjs/toolkit";

// Transformer
import AuthTransformer from "./authTransformer";

// Intital state
const initialState = {
  authInfo: {},
  loginLoading: false,
  loginError: "",

  // Reset Password
  resetPasswordLoading: false,
  resetPasswordError: "",
};

// Auth Slice
const authSlice = createSlice({
  // Name
  name: "Auth",

  // Initial State
  initialState,

  // Reducers
  reducers: {
    // Login
    login: (state) => {
      state.loginLoading = true;
    },

    loginSuccess: (state, action) => {
      const { authInfo } = action.payload;
      const transformedAuthInfo = AuthTransformer.transformObj(authInfo);

      state.authInfo = transformedAuthInfo;
      state.loginLoading = false;
    },

    loginFailure: (state, action) => {
      state.loginLoading = false;
      state.loginError = action.payload.error;
    },

    // Reset Password
    resetPassword: (state) => {
      state.resetPasswordLoading = true;
    },

    resetPasswordSuccess: (state) => {
      state.resetPasswordLoading = false;
      state.resetPasswordError = "";
    },

    resetPasswordFailure: (state, action) => {
      state.resetPasswordLoading = false;
      state.resetPasswordError = action.payload.error;
    },

    // Reset login
    resetLogin: () => initialState,
  },
});

// Reducer
export const AuthReducer = authSlice.reducer;

// Actions
export const AuthActions = authSlice.actions;
