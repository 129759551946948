//
// Project Transformers
//

function transformObj(projectObj) {
  const { id = "", name = "", description = "", updatedOn, createdOn } = projectObj;
  const project = {
    id: id,
    name: name,
    description: description,
    updatedOn: updatedOn,
    createdOn: createdOn,
  };

  return project;
}

function transformList(projectObjList) {
  const projectList = projectObjList.map((obj) => {
    return transformObj(obj);
  });

  return projectList;
}

// Exports
// ----------------------------------------------------------------------------

const ProjectTransformer = {
  transformObj,
  transformList,
};

export default ProjectTransformer;
