//
import React from "react";

// Constants
import { ICON_SIZE } from "../../../constants/GeneralConstants";

// Components
import CardCollapsible from "../../../components/card-collapsible/CardCollapsible";
import Icon from "../../../components/icon/Icon";

// Components :: ReactFlow
import GraphConfig from "../../../reactflow/GraphConfig";
import ReactFlowExt from "../../../reactflow/ReactFlowExt";

// Custom Hook
import { useUpdateQueryParams } from "../../../hooks/useUpdateQueryParams";

// Constant
import { QueryParamsKeys } from "../../../constants/WebConstants";
import Loader from "../../../components/loader/Loader";

//
// Constants
const NodeTypeNameMap = GraphConfig.NodeTypeNameMap;

//
// Component
// ----------------------------------------------------------------------------

export default function CanvasSidebarLeft({
  graphId,
  nodes = [],
  warnings = {},
  errors = {},
  validationLoading = false,
}) {
  // Query Params
  const [searchParams, updateQueryParams] = useUpdateQueryParams();
  const showEdges = searchParams.get(QueryParamsKeys.showEdge) === "true" ? true : false;

  //
  // Graph State :: Selection

  const { selectedNodes, selectedNode, selectedNodeWarnings, selectedNodeErrors } = React.useMemo(() => {
    const selectedNodes = nodes.filter((n) => n.selected) || [];
    if (selectedNodes.length !== 1) {
      return { selectedNodes, selectedNode: undefined, selectedNodeWarnings: [], selectedNodeErrors: [] };
    }

    // Selected Node
    const selectedNode = selectedNodes[0];

    // Selected Node Warnings & Errors
    const selectedNodeUid = selectedNodes[0].id;
    const selectedNodeWarnings = warnings[selectedNodeUid] || [];
    const selectedNodeErrors = errors[selectedNodeUid] || [];

    return { selectedNodes, selectedNode, selectedNodeWarnings, selectedNodeErrors };
  }, [nodes, warnings, errors]);

  //
  // Component Functions
  const selectNode = ReactFlowExt.useNodeSelectStatus(true);
  const unSelectNode = ReactFlowExt.useNodeSelectStatus(false);

  const toggleNodeSelectStatus = (node) => {
    const { id, selected } = node;

    if (selected) {
      unSelectNode(id);
    } else {
      selectNode(id);
    }
  };

  const handleShowEdge = () => {
    const params = {
      showEdge: !showEdges,
    };

    updateQueryParams({ params });
  };

  //
  return (
    //
    <div className="sidebar sidebar-left">
      <div className="position-relative">
        {/** */}

        <div className="sec-info">
          {/** Nodes List */}
          <CardCollapsible header={"Modules (Nodes)"} collapsed={false} id="card-graph-nodes">
            <ul className="list-unstyled list-item-selectable list-graph-elems">
              {nodes.map((n) => {
                const nId = n.id;
                const nType = n.type;
                const nData = n.data || {};

                const isSelected = n.selected;

                return (
                  <li
                    className={`${isSelected ? "active" : ""}`}
                    key={`node-list-${nId}`}
                    onClick={() => toggleNodeSelectStatus(n)}
                    title="Click to Select Node"
                  >
                    <span>
                      <Icon iconName="square" fill={isSelected ? "black" : "transparent"} size={13} className="me-1" />
                      {nData.name}
                    </span>
                    <small className="text-secondary">{NodeTypeNameMap[nType]}</small>
                  </li>
                );
              })}
            </ul>
          </CardCollapsible>
        </div>

        <div className={`sec-info ${selectedNode ? "" : "d-none"}`}>
          {/** Validation */}
          <CardCollapsible header={"Node Problems"} collapsed={false} className="" id="card-validation-res">
            {selectedNode && ( //
              <p className="fw-bold mb-2">{selectedNode.data?.name}</p>
            )}

            {validationLoading && ( //
              <Loader containerClassName="text-center py-2 mb-2" />
            )}

            {!validationLoading && ( //
              <>
                {/** Warnings */}
                <ul className="list-unstyled">
                  {selectedNodeWarnings.map((w, idx) => {
                    return (
                      <li className="text-warning" key={`node-warn-list-${idx}`}>
                        <Icon iconName="triangleAlert" size={ICON_SIZE.MD} className="me-2" />
                        {w}
                      </li>
                    );
                  })}
                </ul>

                {/** Errors */}
                <ul className="list-unstyled">
                  {selectedNodeErrors.map((e, idx) => {
                    return (
                      <li className="text-danger" key={`node-err-list-${idx}`}>
                        <Icon iconName="octagonAlert" size={ICON_SIZE.MD} className="me-2" /> {e}
                      </li>
                    );
                  })}
                </ul>
              </>
            )}

            <small className="fst-italic">** Re validate for latest problems</small>
          </CardCollapsible>
        </div>

        <div className="sec-info d-none">
          {/* Settings */}
          <CardCollapsible header={"Settings"} collapsed={false} className="" id="card-node-settings">
            <div className="form-check form-switch">
              <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                Show Edges
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                onChange={handleShowEdge}
                checked={showEdges}
              />
            </div>
          </CardCollapsible>
        </div>
      </div>
    </div>
  );
}
