import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { GraphActions } from "../../../redux-slice/graph/graphSlice";
import { ProjectActions } from "../../../redux-slice/project/projectSlice";

// Components
import CardCollapsible from "../../../components/card-collapsible/CardCollapsible";
import Icon from "../../../components/icon/Icon";

// Components :: Graph / Reactflow
import GraphConfig from "../../../reactflow/GraphConfig";
import GraphUtil from "../../../reactflow/GraphUtil";

//
// Constants
const NodeType = GraphConfig.NodeType;
const NodeTypeNameMap = GraphConfig.NodeTypeNameMap;

const VarType = GraphConfig.VarType;
const VarTypeNameMap = GraphConfig.VarTypeNameMap;

//
// Page Components
// ----------------------------------------------------------------------------

//
function VariableListItem({ name, symbol, varType = VarType.PARAMETER }) {
  //
  const varTypeTxt = VarTypeNameMap[varType];

  return (
    <li className="">
      <span className="">
        {name}
        <small className="text-secondary ms-2 fst-italic">{varTypeTxt}</small>
      </span>
      <span className="">{symbol}</span>
    </li>
  );
}

//
function EdgesList({ edges = [] }) {
  //
  return (
    <ul className="list-unstyled list-graph-elems">
      {edges.map(({ id, data = {} }, idx) => {
        // data
        const { name, symbol, varType } = data;

        return (
          //
          <VariableListItem key={`key-var-${idx}`} name={name} symbol={symbol} varType={varType} />
        );
      })}
    </ul>
  );
}

//
function ParameterList({ parameters = [] }) {
  //
  return (
    <ul className="list-unstyled list-graph-elems">
      {parameters.map((p, idx) => {
        const { id, name, symbol, varType } = p;
        //
        return (
          //
          <VariableListItem key={`key-param-${id}`} name={name} symbol={symbol} varType={varType} />
        );
      })}
    </ul>
  );
}

//
function EquationList({ equations = [] }) {
  //
  return (
    <ul className="list-unstyled list-graph-elems">
      {equations.map((eq, idx) => {
        const { id, name, text } = eq;
        //
        return (
          <li className="" key={`key-eqn-${id}`}>
            {text}
            <small className="text-secondary ms-2 fst-italic">{name}</small>
          </li>
        );
      })}
    </ul>
  );
}

//
function NodeSourceCard({ node = {}, inputEdges = [], outputEdges = [] }) {
  // data
  const { id, type, data = {} } = node;
  const { name, parameters = [], equations = [] } = data;

  //
  const header = (
    <>
      <strong className="me-3">{name}</strong>
      <small className="badge bg-light text-dark border">{NodeTypeNameMap[type]}</small>
    </>
  );

  //
  return (
    <CardCollapsible className={"bg-white mb-2"} header={header} collapsed={false} id={`card-node-${id}`}>
      <div className="pb-2">
        <div className="row">
          <div className="col-6 border-end">
            <h6 className="py-2 border-bottom fw-bold">Equations</h6>
            <div className="">
              <EquationList equations={equations} />
            </div>
          </div>
          <div className="col-2 border-end">
            <h6 className="py-2 border-bottom fw-bold">Parameters</h6>
            <div className="">
              <ParameterList parameters={parameters} />
            </div>
          </div>

          <div className="col-2 border-end">
            <h6 className="py-2 border-bottom fw-bold">Inputs</h6>
            <div className="">
              <EdgesList edges={inputEdges} />
            </div>
          </div>
          <div className="col-2">
            <h6 className="py-2 border-bottom fw-bold">Outputs</h6>
            <div className="">
              <EdgesList edges={outputEdges} />
            </div>
          </div>
        </div>
      </div>
    </CardCollapsible>
  );
}

/**
 * Page
 */
export default function GraphSourcePage() {
  // Dispatch
  const dispatch = useDispatch();

  // Page Params
  const params = useParams();
  const { projectId } = params;
  const graphId = projectId; // graphId is just "projectId"

  // Dispatch API calls
  useEffect(() => {
    dispatch(ProjectActions.getProject({ projectId }));
    dispatch(GraphActions.getGraphData({ graphId }));
  }, [projectId, graphId, dispatch]);

  //
  // Selector State
  const projectInfo = useSelector((state) => state.project.projectInfo);
  const graphData = useSelector((state) => state.graph.graphData);
  const graphDataLoading = useSelector((state) => state.graph.graphDataLoading);

  //
  // Graph Data
  const { nodes = [], edges = [] } = GraphUtil.toReactFlowFormat(graphId, graphData);
  const { parametersMap = {}, equationsMap = {} } = graphData || {};

  const graphParameters = parametersMap[graphId] || [];
  const graphEquations = equationsMap[graphId] || [];

  // Node :: Control Volumes
  const cvNodes = React.useMemo(() => {
    // filter control_volumes
    const cvNodes = nodes.filter((n) => {
      return n.type === NodeType.CONTROL_VOLUME;
    });

    return cvNodes;
  }, [nodes]);

  // Node :: Connected Edges
  const { inEdgesByNode, outEdgesByNode } = React.useMemo(() => {
    // init
    const inEdgesByNode = {};
    const outEdgesByNode = {};

    // iterate over edges
    edges.forEach((e) => {
      const { source, target } = e;

      // Update input, output edges map
      inEdgesByNode[target] = [...(inEdgesByNode[target] || []), e];
      outEdgesByNode[source] = [...(outEdgesByNode[source] || []), e];
    });

    return { inEdgesByNode, outEdgesByNode };
  }, [edges]);

  //
  return (
    <div className="main-cont position-right-0">
      <div className="content-wrapper">
        {/** */}

        <div className="page-content">
          {/** */}
          <h3 className="m-1 mb-3">
            <Icon iconName="workflow" /> Graph
          </h3>
          <div className="mb-4">
            <CardCollapsible className={"bg-white mb-2"} header={"System"} collapsed={false} id={`card-graph`}>
              <div className="pb-2">
                <div className="row">
                  <div className="col-6">
                    <h6 className="py-2 border-bottom fw-bold">Equations</h6>
                    <div className="">
                      <EquationList equations={graphEquations} />
                    </div>
                  </div>
                  <div className="col-2 border-end">
                    <h6 className="py-2 border-bottom fw-bold">Parameters</h6>
                    <div className="">
                      <ParameterList parameters={graphParameters} />
                    </div>
                  </div>
                </div>
              </div>
            </CardCollapsible>
          </div>

          {/** Nodes */}
          <h3 className="m-1 mb-3">
            <Icon iconName="square" /> Nodes
          </h3>
          <div className="sec-nodes">
            {cvNodes.map((n) => {
              const nId = n.id;
              const inpEdges = inEdgesByNode[nId] || [];
              const outEdges = outEdgesByNode[nId] || [];

              return <NodeSourceCard key={`card-node-${nId}`} node={n} inputEdges={inpEdges} outputEdges={outEdges} />;
            })}
          </div>
        </div>

        {/** */}
      </div>
    </div>
  );
}
