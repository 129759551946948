import { useSelector } from "react-redux";
import { Form, Select } from "antd";
import { useEffect } from "react";

// Utils
import GraphConfig from "../../../../reactflow/GraphConfig";
import GraphUtil from "../../../../reactflow/GraphUtil";

// Components
import Modal from "../../../../components/antd/modal/Modal";
import Input from "../../../../components/antd/input/Input";
import Button from "../../../../components/button/Button";

// Page Constants
const VarType = GraphConfig.VarType;
const VarTypeNameMap = GraphConfig.VarTypeNameMap;

/**
 * Graph Parameter Modal
 * @param {*} isModalOpen : Boolean
 * @param {*} closeModal : Function
 * @param {*} nodeUid : Id
 * @param {*} parameter :Object
 * @param {*} varPrefix :String
 * @param {*} onSave :Function
 */
export default function GraphParameterModal({
  isModalOpen = false,
  closeModal = () => {},
  nodeUid,
  parameter = {},
  varPrefix,
  onSave,
}) {
  const createParameterLoading = useSelector((state) => state.parameter.createParameterLoading);
  const updateParameterLoading = useSelector((state) => state.parameter.updateParameterLoading);

  const loadingStatus = createParameterLoading || updateParameterLoading;

  const [form] = Form.useForm();

  useEffect(() => {
    const { varType = "", name = "", symbol = "" } = parameter;

    const genSymbol = GraphUtil.toParamVarSymbol(varPrefix, varType, name);
    const updatedSymbol = genSymbol === "_" ? "" : genSymbol;

    form.setFieldsValue({ varType, name, symbol: updatedSymbol || symbol });
  }, [varPrefix, parameter]);

  return (
    <Modal
      destroyOnClose={true}
      isModalOpen={isModalOpen}
      title={`${nodeUid ? "Node" : "Graph"} Parameter`}
      size="md"
      onCancel={closeModal}
    >
      {/* Project Create Modal */}
      <Form form={form} layout="vertical" onFinish={onSave}>
        <Form.Item
          name="varType"
          label="Variable Type"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select placeholder="Select Variable Type">
            {Object.keys(VarType).map((vt) => {
              const varTypeName = VarTypeNameMap[vt];

              return (
                <Select.Option key={vt} value={vt}>
                  {varTypeName}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Enter Name" />
        </Form.Item>

        <Form.Item
          name="symbol"
          label="Variable Symbol"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Enter Varuable Symbol" />
        </Form.Item>

        <Form.Item>
          <Button
            className="btn btn-sm btn-primary float-end"
            type="submit"
            loading={loadingStatus}
            disabled={loadingStatus}
          >
            Save Parameter
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
