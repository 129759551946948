import { RouterProvider, createBrowserRouter } from "react-router-dom";

// Constants
import PageURL from "./constants/pageUrls";

// CSS and JS
import "./App.scss";
import "bootstrap/dist/js/bootstrap.js";
import "react-toastify/dist/ReactToastify.css";

// App Setup
import ErrorHandler from "./app/error/ErrorHandler";
import ProtectedRoute from "./app/ProtectedRoute";
import Layout from "./app/layout/Layout";
import LayoutProject from "./app/layout/LayoutProject";

// Pages :: Error
import ErrorPage from "./pages/error/ErrorPage";

// Pages :: Auth
import LoginPage from "./pages/auth/LoginPage";
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage";

// Pages :: Profile
import ProfilePage from "./pages/user/user-profile/ProfilePage";

// Pages :: Dashboard
import DashboardPage from "./pages/dashboard/DashboardPage";

// Pages :: Users
import UserListPage from "./pages/user/user-list/UserListPage";

// Pages :: Projects
import ProjectListPage from "./pages/project/project-list/ProjectListPage";
import ProjectViewPage from "./pages/project/project-view/ProjectViewPage";

import GraphCanvasPage from "./pages/project/graph-canvas/GraphCanvasPage";
import GraphSourcePage from "./pages/project/graph-source/GraphSourcePage";

import OptSetupPage from "./pages/project/opt-setup/OptSetupPage";
import OptSetupListPage from "./pages/project/opt-setup-list/OptSetupListPage";

import OptRunPage from "./pages/project/opt-run/OptRunPage";
import OptRunListPage from "./pages/project/opt-run-list/OptRunListPage";
import OptRunProblemPage from "./pages/project/opt-run-problem/OptRunProblemPage";

import ProjectDataPage from "./pages/project/project-data/ProjectDataPage";
import ProjectExecutePage from "./pages/project/project-execute/ProjectExecutePage";
import ProjectResultPage from "./pages/project/project-result/ProjectResultPage";

import ProjectFileListPage from "./pages/project/project-file/ProjectFileListPage";

// Pages :: Dev
import devRoutes from "./dev/DevRoutes";

//
// Router
// ----------------------------------------------------------------------------

const router = createBrowserRouter([
  // Error Pages
  {
    path: PageURL.ErrorPageURL,
    element: <ErrorPage />,
  },

  // Layout :: Topbar
  {
    element: <Layout topbar={false} sidebar={false} layoutClassName="layout-topbar" />,
    children: [
      // Auth Pages
      {
        path: PageURL.LoginPageURL,
        element: <LoginPage />,
      },
      {
        path: PageURL.ForgotPasswordPageURL,
        element: <ForgotPasswordPage />,
      },
    ],
  },

  // Layout :: Sidebar
  {
    path: "/",
    element: (
      <ErrorHandler>
        <ProtectedRoute>
          <Layout
            topbar={true}
            navLeft={false}
            sidebar={true}
            sidebarCollapsible={false}
            layoutClassName="layout-sidebar"
          />
        </ProtectedRoute>
      </ErrorHandler>
    ),
    children: [
      {
        path: PageURL.DashboardPageURL,
        element: <DashboardPage />,
      },

      {
        path: PageURL.UserListPageURL,
        element: <UserListPage />,
      },
      {
        path: PageURL.ProfilePageURL,
        element: <ProfilePage />,
      },

      {
        path: PageURL.ProjectListPageURL,
        element: <ProjectListPage />,
      },
    ],
  },

  // Layout :: Shell
  {
    element: (
      <ProtectedRoute>
        <LayoutProject />
      </ProtectedRoute>
    ),
    children: [
      {
        path: PageURL.ProjectViewPageURL,
        element: <ProjectViewPage />,
      },

      {
        path: PageURL.GraphCanvasPageURL,
        element: <GraphCanvasPage />,
      },
      {
        path: PageURL.GraphSourcePageURL,
        element: <GraphSourcePage />,
      },

      {
        path: PageURL.ProjectDataPageURL,
        element: <ProjectDataPage />,
      },

      {
        path: PageURL.OptSetupPageURL,
        element: <OptSetupPage />,
      },
      {
        path: PageURL.OptSetupListPageURL,
        element: <OptSetupListPage />,
      },

      {
        path: PageURL.OptRunPageURL,
        element: <OptRunPage />,
      },
      {
        path: PageURL.OptRunListPageURL,
        element: <OptRunListPage />,
      },
      {
        path: PageURL.OptRunProblemPageURL,
        element: <OptRunProblemPage />,
      },

      {
        path: PageURL.ProjectExecutePageURL,
        element: <ProjectExecutePage />,
      },
      {
        path: PageURL.ProjectResultPageURL,
        element: <ProjectResultPage />,
      },
      {
        path: PageURL.ProjectFileListPageURL,
        element: <ProjectFileListPage />,
      },
    ],
  },

  // Dev Pages
  ...devRoutes,
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
