//
// OptSetup Transformers
//

function transformObj(optSetupObj) {
  const { id = "", projectId = "", graphId = "", name = "", notes = "", updatedOn } = optSetupObj;
  const optSetup = {
    id: id,
    projectId: projectId,
    graphId: graphId,
    name: name,
    notes: notes,
    updatedOn: updatedOn,
  };

  return optSetup;
}

function transformList(optSetupObjList) {
  const optSetupList = optSetupObjList.map((obj) => {
    return transformObj(obj);
  });

  return optSetupList;
}

// Exports
// ----------------------------------------------------------------------------

const OptSetupTransformer = {
  transformObj,
  transformList,
};

export default OptSetupTransformer;
