import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Form } from "antd";

// Components
import Modal from "../../../../components/antd/modal/Modal";
import Input from "../../../../components/antd/input/Input";
import Button from "../../../../components/button/Button";

/**
 * Save Graph Modal
 * @param {*} isModalOpen : Boolean
 * @param {*} bas64Image : String
 * @param {*} closeModal : Function
 * @param {*} onSave : Function
 * @param {*} form : Antd form object
 */
export default function SaveGraphModal({
  isModalOpen = false,
  base64Image = "",
  closeModal = () => {},
  onSave = () => {},
  form,
}) {
  const createFileLoading = useSelector((state) => state.projectFile.createFileLoading);
  const isApiSuccess = useSelector((state) => state.projectFile.isApiSuccess);

  useEffect(() => {
    if (isApiSuccess) {
      closeModal();
      form.resetFields();
    }
  }, [isApiSuccess]);

  return (
    <Modal isModalOpen={isModalOpen} title="Save Graph" onCancel={closeModal}>
      {base64Image && (
        <div className="w-75 mx-auto">
          <img className="img-fluid" src={base64Image} alt="Chart" />
        </div>
      )}

      <Form form={form} layout="vertical" onFinish={onSave}>
        {/* <Form.Item
      name="fileKind"
      label="File Kind"
      rules={[
        {
          required: true,
          message: "File Kind is required !!",
        },
      ]}
    >
      <Select placeholder="Select Variable Type">
        {FILE_KINDS.map((fileKind) => {
          const { name = "", value = "" } = fileKind;

          return (
            <Select.Option key={value} value={value}>
              {name}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item> */}

        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input placeholder="Enter Title" />
        </Form.Item>

        <Form.Item name="notes" label="Notes">
          <Input type="TextArea" placeholder="Enter notes" />
        </Form.Item>

        <div className="d-flex flex-row-reverse">
          <Button className="btn-primary" loading={createFileLoading} disabled={createFileLoading}>
            Save Graph
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
