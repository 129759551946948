import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { ProjectActions } from "../../../redux-slice/project/projectSlice";

// Components
import Icon from "../../../components/icon/Icon";

// Constants

//
// Page Components
// ----------------------------------------------------------------------------

/**
 * Page
 */
export default function ProjectViewPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Page Params
  const params = useParams();
  const { projectId } = params;
  const graphId = projectId; // graphId is just "projectId"

  // Dispatch API calls
  useEffect(() => {
    dispatch(ProjectActions.getProject({ projectId }));
  }, [projectId, graphId, dispatch]);

  //
  // Selector State
  const projectInfo = useSelector((state) => state.project.projectInfo);
  const projectInfoLoading = useSelector((state) => state.project.projectInfoLoading);

  // Data
  const { name, description } = projectInfo || {};

  //
  return (
    <div className="main-cont position-right-0">
      <div className="content-wrapper">
        {/** */}

        <div className="page-content">
          {/** */}
          <div className="mt-3 mb-4">
            <label className="fw-bold">Name</label>
            <h3 className="">{name}</h3>
          </div>
          <div className="my-3">
            <label className="fw-bold">Description</label>
            <p className="m-1 mb-3">{description}</p>
          </div>
        </div>

        {/** */}
      </div>
    </div>
  );
}
