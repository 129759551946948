// Utils
import UrlUtils from "../../utils/urlUtils";

// URLs
import { NodeUrls } from "../../constants/serviceUrls";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Create Node
const createNode = async function (graphId, nodeInfo) {
  // API URL
  const apiURL = UrlUtils.format(NodeUrls.createNode, { graphId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(apiURL, {}, nodeInfo);
};

// Update Node Info
const updateNodeInfo = async function (graphId, nodeUid, nodeInfo) {
  const { type = "", name = "", symbol = "", varType = "" } = nodeInfo || {};

  // Params
  const params = {
    type,
    name,
    symbol,
    varType,
  };

  // API URL
  const apiURL = UrlUtils.format(NodeUrls.updateNodeInfoByUid, { graphId, nodeUid });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiURL, params);
};

// Update Node Style
const updateNodeStyle = async function (graphId, nodeUid, styleObj) {
  // API URL
  const apiURL = UrlUtils.format(NodeUrls.updateNodeStyleByUid, { graphId, nodeUid });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiURL, {}, styleObj);
};

// Update Node Position
const updateNodePosition = async function (graphId, nodeUid, x, y) {
  // Params
  const params = {
    x,
    y,
  };

  // API URL
  const apiURL = UrlUtils.format(NodeUrls.updateNodePositionByUid, { graphId, nodeUid });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiURL, params);
};

// Update Node Equation Generation Status
const updateNodeEqnGenStatus = async function (graphId, nodeUid, generateEqns) {
  // Params
  const params = {
    generate: generateEqns,
  };

  // API URL
  const apiURL = UrlUtils.format(NodeUrls.updateNodeEqnGenStatusByUid, { graphId, nodeUid });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiURL, params);
};

// Delete Node
const deleteNode = async function (graphId, nodeUid) {
  // Params
  const params = {
    edges: true,
  };

  // API URL
  const apiURL = UrlUtils.format(NodeUrls.deleteNodeByUid, { graphId, nodeUid });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.delete(apiURL, params);
};

// Export
const NodeService = {
  //
  createNode,

  //
  updateNodeInfo,
  updateNodeStyle,
  updateNodePosition,
  updateNodeEqnGenStatus,

  //
  deleteNode,
};

export default NodeService;
