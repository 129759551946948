//
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

// Actions
import { AuthActions } from "../../redux-slice/auth/authSlice";

// Components
import Button from "../../components/button/Button";
import Icon from "../../components/icon/Icon";
import Dropdown from "../../components/antd/dropdown/Dropdown";

// Constants
import PageURL from "../../constants/pageUrls";
import { ICON_SIZE } from "../../constants/GeneralConstants";

//
import NavLeft from "./NavLeft";
import NavTop from "./NavTop";
import Sidebar from "./Sidebar";

//
// ----------------------------------------------------------------------------

function ProfileMenu() {
  // Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  function navigateToProfilePage() {
    navigate(PageURL.ProfilePageURL);
  }

  function dispatchEvent() {
    dispatch(AuthActions.resetLogin({ navigate }));
  }

  const profileBtn = (
    <Button icon={<Icon iconName="user" size={ICON_SIZE.MD} />} onClick={navigateToProfilePage}>
      Profile
    </Button>
  );

  const logoutBtn = (
    <Button icon={<Icon iconName="logout" size={ICON_SIZE.MD} />} onClick={dispatchEvent}>
      Log Out
    </Button>
  );

  const menuItems = [
    {
      key: "1",
      label: profileBtn,
    },
    {
      key: "2",
      label: logoutBtn,
    },
  ];

  return (
    // Dropdrown Component
    <Dropdown label="admin" menuItems={menuItems} isArrowPointAtCenter={true} placement="bottomRight" />
  );
}

/**
 * Main Layout Component
 */
export default function Layout({
  themeId, //
  topbar = false,
  navLeft = false,
  sidebar = false,
  sidebarCollapsible = false,
  layoutClassName = "",
}) {
  // Page State
  const [showSideBar, setShowSideBar] = useState(true);

  // Data
  const isSidebarCollapsed = sidebar && !showSideBar;

  // NavTop :: Default Behaviour
  const sectionRight = <ProfileMenu />;

  return (
    <div className={`page ${layoutClassName}`} data-theme={themeId}>
      {/* Topbar */}
      {(topbar || sidebar) && ( //
        <NavTop
          sectionRight={sectionRight}
          sidebarCollapsible={sidebarCollapsible}
          showSideBar={showSideBar}
          setShowSideBar={setShowSideBar}
        />
      )}

      <div className="page-wrapper">
        {/* Fixed width vertical Navigation on Left edge */}
        {navLeft && ( //
          <NavLeft />
        )}

        {/* vertical Sidebar (defaults to left edge) */}
        {sidebar && ( //
          <Sidebar collapsible={sidebarCollapsible} showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
        )}

        {/* Main Content */}
        <div className={`main-cont ${isSidebarCollapsed ? "collapsed" : ""}`}>
          <div className={"content-wrapper "}>
            <Outlet />
          </div>
        </div>

        {/*_*/}
      </div>
    </div>
  );
}
