import CardCollapsible from "../../../../components/card-collapsible/CardCollapsible";

const CONFIG = {
  solver: "ipopt",
  iterations: 1000,
  presolve_eps: 0.000001,
};

export default function AdvanceConfigCard({ advanceConfig = {}, setAdvanceConfig = () => {} }) {
  // Objective Information
  const {
    solver = CONFIG.solver,
    iterations = CONFIG.iterations,
    presolve_eps = CONFIG.presolve_eps,
  } = advanceConfig || {};

  function handleChange({ target }, key) {
    setAdvanceConfig((prev) => ({ ...prev, [key]: target.value }));
  }

  return (
    <CardCollapsible header={"Solver Configuration:"} collapsed={true} className="pb-1">
      {/** */}
      <div className="row mb-2">
        <label className="col-2 col-form-label">Solver</label>
        <div className="col-10">
          <input value={solver} disabled={true} className="form-control" onChange={(e) => handleChange(e, "solver")} />
        </div>
      </div>
      <div className="row mb-2">
        <label className="col-2 col-form-label">Iterations</label>
        <div className="col-10">
          <input
            value={iterations}
            disabled={true}
            className="form-control"
            onChange={(e) => handleChange(e, "iterations")}
          />
        </div>
      </div>
      <div className="row mb-2">
        <label className="col-2 col-form-label">Presolve Eps</label>
        <div className="col-10">
          <input value={presolve_eps} className="form-control" onChange={(e) => handleChange(e, "presolve_eps")} />
        </div>
      </div>
    </CardCollapsible>
  );
}
