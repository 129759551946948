/**
 * Odd-ball URL Utilities
 */

//
// ----------------------------------------------------------------------------

/**
 * Format the Given URL with path/request parameters substitued with the passed values
 */

function format(inUrlStr = "", argVals = {}, queryVals = {}) {
  // Iterate over args and substitute values
  let url = inUrlStr;
  for (const [key, value] of Object.entries(argVals)) {
    const pathParam = ":" + key;
    url = url.replace(pathParam, value);
  }

  let queryString = "";
  for (const [key, value] of Object.entries(queryVals)) {
    queryString = queryString ? `${queryString}&${key}=${value}` : `${key}=${value}`;
  }

  if (queryString) {
    url = `${url}?${queryString}`;
  }

  return url;
}

const UrlUtils = {
  format,
};

// Export
export default UrlUtils;
