//
import moment from "moment";

// Utils
import TableUtils from "./tableUtils";

function constructOptSetupListTableData(optSetupList) {
  // Construct rows
  const optSetupRowMap = {};

  for (const idx in optSetupList) {
    const optSetup = optSetupList[idx];
    const { id, updatedOn } = optSetup;

    const optSetupRow = {
      ...optSetup,
      key: id,
      updatedOn: moment(updatedOn).fromNow(),
    };

    optSetupRowMap[id] = optSetupRow;
  }

  return optSetupRowMap;
}

function getOptSetupTableHeader(currentPage = "", pageSize = "") {
  return [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => TableUtils.tableSerialNumber(currentPage, pageSize, index),
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Last updated",
      dataIndex: "updatedOn",
    },
  ];
}

const OptSetupTableUtils = { constructOptSetupListTableData, getOptSetupTableHeader };

export default OptSetupTableUtils;
