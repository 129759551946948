import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

// Actions
import { GraphActions } from "../../../redux-slice/graph/graphSlice";
import { OptSetupActions } from "../../../redux-slice/project/optSetupSlice";
import { ProjectActions } from "../../../redux-slice/project/projectSlice";

// Constants
import { QueryParamsKeys } from "../../../constants/WebConstants";

// Utils
import GraphHelper from "../../../helpers/graphHelper";

// Components
import Button from "../../../components/button/Button";
import Loader from "../../../components/loader/Loader";

import PageHeader from "../../../app/layout/PageHeader";

// Sections
import BoundsCard from "./components/BoundsCard";
import ObjectiveFunctionCard from "./components/ObjectiveFunctionCard";

//
// Page Components
// ----------------------------------------------------------------------------

//
function PageHeaderSection({
  projectId = "",
  graphId = "",
  optSetupId = "",
  objectiveInfo = {},
  boundsData = {},
  config = {},
  isPageLoading = false,
}) {
  // Dispatch and Navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Information
  const { name = "", isMinimize = true, costExpression = "" } = objectiveInfo;

  // Save and Setup
  function saveOptSetup() {
    // Objective Array
    const objectivesArray = [{ name, isMinimize, costExpression }];

    // Var Bounds
    const varDefaultValues = {};
    const varMinBounds = {};
    const varMaxBounds = {};

    // Constructing var values
    Object.keys(boundsData).forEach((varSymbol) => {
      const { value = "", min = "", max = "" } = boundsData[varSymbol] || {};

      // Parsing min, max and value
      const parsedValue = parseFloat(value) ?? "";
      const parsedMin = parseFloat(min) ?? "";
      const parsedMax = parseFloat(max) ?? "";

      // TODO: Try to handle zero value, instead of converting to string in the condition.
      // Checking for Nan and Zero
      if (!isNaN(parsedValue) && `${parsedValue}`) {
        varDefaultValues[varSymbol] = parsedValue;
      } else {
        if (!isNaN(parsedMin) && `${parsedMin}`) {
          varMinBounds[varSymbol] = parsedMin;
        }
        if (!isNaN(parsedMax) && `${parsedMax}`) {
          varMaxBounds[varSymbol] = parsedMax;
        }
      }
    });

    // Opt Setup Config Sample (TODO: get it as user input)

    // Form Data
    const optSetupObj = {
      projectId,
      graphId,
      name,
      objectives: objectivesArray,
      varDefaultValues,
      varMinBounds,
      varMaxBounds,

      config: { ...config },
      // ...varValues,
    };

    // If its updating the existing opt run
    if (optSetupId) {
      // Dispatch
      dispatch(OptSetupActions.updateOptSetup({ optSetupObj, projectId, optSetupId }));
      return;
    }

    // If its creating a new opt run
    // Dispatch
    dispatch(OptSetupActions.createOptSetup({ optSetupObj, projectId, navigate }));
  }

  // Content
  const pageTitle = "Optimization Problem Setup";
  const pageActions = (
    <div className="btn-cont">
      {/* Save And Run Button */}
      <Button className="btn btn-secondary" onClick={saveOptSetup} loading={isPageLoading} disabled={isPageLoading}>
        Save
      </Button>
    </div>
  );

  return <PageHeader title={pageTitle} actions={pageActions} />;
}

//
function ConstraintsCard({ equations = [] }) {
  //
  return (
    <div className="card card-info">
      <div className="card-body">
        <h6 className="py-1 pb-2 mb-3 border-bottom fw-bold">Subject to:</h6>
        <ul className="list-unstyled list-graph-elems">
          {equations.map((eq, idx) => {
            const { id, name, text } = eq;
            //
            return (
              <li className="" key={`key-eqn-${id}`}>
                {text}
                <small className="text-secondary ms-2 fst-italic">{name}</small>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

//
function constructFormData(data = [], optSetup = {}) {
  // Opt Setup
  const { varDefaultValues = {}, varMinBounds = {}, varMaxBounds = {}, solution = {} } = optSetup;

  return data.reduce((acc, eachData) => {
    const { symbol = "" } = eachData || {};

    const obj = {
      min: varMinBounds[symbol] ?? "",
      max: varMaxBounds[symbol] ?? "",
      value: varDefaultValues[symbol] ?? "",
      solution: solution[symbol] ?? "",
    };

    return { ...acc, [symbol]: obj };
  }, {});
}

/**
 * Page
 */
export default function OptSetupPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Page Params
  const { projectId } = useParams();
  const graphId = projectId; // graphId is just "projectId"

  // Page Query Params
  const [searchParams, setSearchParams] = useSearchParams();
  const optSetupId = searchParams.get(QueryParamsKeys.optSetupId) ?? "";

  // Selector State
  const graphData = useSelector((state) => state.graph.graphData);
  const optSetup = useSelector((state) => state.optSetup.optSetup);
  const refreshGraphLoading = useSelector((state) => state.graph.refreshGraphLoading); // Generate Equation Loading State

  console.log(optSetup);

  const createOptSetupLoading = useSelector((state) => state.optSetup.createOptSetupLoading);
  const updateOptSetupLoading = useSelector((state) => state.optSetup.updateOptSetupLoading);
  const isPageLoading = createOptSetupLoading || updateOptSetupLoading;

  // State
  const [boundsData, setBoundsData] = useState({});
  const [objectiveInfo, setObjectiveInfo] = useState("");

  //
  // Graph Data
  const { edges = [], parameters = [], equations = [] } = graphData || {};

  // OptSetup Information
  const { objectives = [] } = optSetup || {};
  const objectivesStr = JSON.stringify(objectives);

  // Var Suggestions
  const varSuggestions = GraphHelper.getVarSuggestionsData(graphData);

  // Dispatch API calls
  useEffect(() => {
    dispatch(ProjectActions.getProject({ projectId }));
    dispatch(GraphActions.getGraphData({ graphId }));
    dispatch(GraphActions.refreshGraph({ graphId }));
  }, [projectId, graphId, dispatch]);

  useEffect(() => {
    if (optSetupId) {
      dispatch(OptSetupActions.getOptSetup({ projectId, optSetupId }));
    }
  }, [projectId, optSetupId, dispatch]);

  useEffect(() => {
    if (optSetupId) {
      const objInfo = objectives[0] || {};
      setObjectiveInfo(objInfo);
    } else {
      setObjectiveInfo({});
    }
  }, [optSetupId, objectivesStr, objectives]);

  useEffect(() => {
    if (optSetupId) {
      // Combining data of parameters and edges
      const data = [...parameters, ...edges];

      setBoundsData(constructFormData(data, optSetup));
    } else {
      setBoundsData({});
    }
  }, [optSetup, optSetupId]);

  //
  return (
    <div className="main-cont position-right-0">
      {refreshGraphLoading && ( //
        <Loader containerClassName="input-group-text" />
      )}

      {!refreshGraphLoading && (
        <div className="content-wrapper">
          {/** Header */}
          <PageHeaderSection
            projectId={projectId}
            graphId={graphId}
            optSetupId={optSetupId}
            objectiveInfo={objectiveInfo}
            boundsData={boundsData}
            isPageLoading={isPageLoading}
          />

          {/* Page Content */}
          <div className="page-content">
            <div className="row">
              <div className="col-5">
                {/** Objective Function */}
                <ObjectiveFunctionCard
                  varSuggestions={varSuggestions}
                  objectiveInfo={objectiveInfo}
                  setObjectiveInfo={setObjectiveInfo}
                />

                {/** Constraints */}
                <ConstraintsCard equations={equations} />
              </div>
              <div className="col-7">
                {/** Bounds */}
                <BoundsCard boundsData={boundsData} setBoundsData={setBoundsData} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
