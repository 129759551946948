//
import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { OptRunJobActions } from "../../redux-slice/project/optRunJobSlice";
import { setErrorInfo } from "../../app/error/ErrorActions";

// Service
import OptRunJobService from "../../services/project/optRunJobService";

// Utils
import UrlUtils from "../../utils/urlUtils";

// Constants
import PageURL from "../../constants/pageUrls";

//
// Saga Functions
// ----------------------------------------------------------------------------

// Create OptRunJob
function* createOptRunJob(action) {
  const { projectId, optSetupId, optRunObj, navigate } = action.payload;
  try {
    yield OptRunJobService.createOptRunJob(projectId, optSetupId, optRunObj);

    // Dispatching Action
    yield put(OptRunJobActions.createOptRunJobSuccess());

    // Toaster
    toast.success("Saved successfully");

    //
    // Navigate to OptRunJob View Page
    const pageURL = UrlUtils.format(PageURL.OptRunJobListPageURL, { projectId, optSetupId });
    navigate(pageURL);

    //
  } catch (error) {
    yield put(OptRunJobActions.createOptRunJobFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Get Opt Run List
function* getOptRunJobs(action) {
  const { projectId } = action.payload;
  try {
    const { items, pagination } = yield OptRunJobService.getOptSetupRunJobs(projectId);

    // Dispatching Action
    yield put(OptRunJobActions.getOptRunJobsSuccess({ optRunList: items, optRunListPagination: pagination }));
  } catch (error) {
    yield put(OptRunJobActions.getOptRunJobsFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

//
// Export

export default function* root() {
  yield all([
    //
    takeLatest(OptRunJobActions.createOptRunJob.type, createOptRunJob),

    //
    takeLatest(OptRunJobActions.getOptRunJobs.type, getOptRunJobs),
  ]);
}
