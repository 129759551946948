//

// Utils
import UrlUtils from "../../utils/urlUtils";
import HttpClientFactory from "../../app/http/HttpClientFactory";

// URLs
import { OptRunJobUrls } from "../../constants/serviceUrls";
import { DefaultPagination } from "../../constants/WebConstants";

//
// Service Methods
// ----------------------------------------------------------------------------

//
// OptRunJob

// Create OptRun Job
const createOptRunJob = async function (projectId, optSetupId, optRunMultiObj) {
  // API URL
  const apiURL = UrlUtils.format(OptRunJobUrls.createOptRunJob, { projectId, optSetupId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(apiURL, {}, optRunMultiObj);
};

//
// Get OptSetup Run Jobs

const getOptSetupRunJobs = async function (projectId, optSetupId, pageNumber, pageSize) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  // API URL
  const apiURL = UrlUtils.format(OptRunJobUrls.getOptSetupRunJobs, { projectId, optSetupId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(apiURL, params);
};

//
// Export
// ----------------------------------------------------------------------------

const OptRunJobService = {
  //
  createOptRunJob,

  //
  getOptSetupRunJobs,
};

export default OptRunJobService;
