//

// Utils
import HttpClientFactory from "../../app/http/HttpClientFactory";
import UrlUtils from "../../utils/urlUtils";

// Constants
import { ProjectUrls } from "../../constants/serviceUrls";
import { DefaultPagination } from "../../constants/WebConstants";

//
// Service Methods
// ----------------------------------------------------------------------------

// Create Project
const createProject = async (projectData) => {
  // API URL
  const apiURL = UrlUtils.format(ProjectUrls.createProject);

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(apiURL, {}, projectData);
};

// Update Project Info By Id
const updateProject = async (projectId, projectData) => {
  // API URL
  const apiURL = UrlUtils.format(ProjectUrls.updateProjectInfo, { projectId: projectId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiURL, {}, projectData);
};

// Get Project Info By Id
const getProject = async (projectId) => {
  // API URL
  const apiURL = UrlUtils.format(ProjectUrls.getProject, { projectId: projectId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(apiURL);
};

// Search Projects
const searchProjects = async (searchText, pageNumber, pageSize) => {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (searchText) {
    params.q = searchText;
  }

  // API URL
  const apiURL = UrlUtils.format(ProjectUrls.searchProjects);

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(apiURL, params);
};

// Exports
// ----------------------------------------------------------------------------

const ProjectService = {
  //
  createProject,

  //
  updateProject,

  //
  getProject,
  searchProjects,
};

export default ProjectService;
