import { createSlice } from "@reduxjs/toolkit";

// Constants
import { USER_OBJECT_KEYS, USER_STATUS } from "../../constants/userConstants";

// Utils
import UserTableUtils from "../../utils/userTableUtils";

// Transformer
import { getTransformedUserList } from "./userTransformer";

// Initial State
const initialState = {
  // list users state
  userMap: {},
  searchUsersLoading: false,
  searchUsersError: {},

  // Users State
  userId: "",
  upsertUserLoading: false,
  upsertUserSuccess: false,
  upsertUserError: {},

  // Change User Password
  updateUserPasswordLoading: false,
  updateUserPasswordSuccess: false,
  updateUserPasswordError: {},

  // Update User Status Enable / Disable
  userStatusLoadingmap: {},
  userStatusError: {},

  // Update User Role
  updateUserRoleLoading: false,
  updateUserRoleSuccess: false,
  updateUserRoleError: {},

  // User fetch state
  user: {},
  getUserLoading: false,
  getUserError: {},
};

const userSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    // Create User
    createUser: (state) => {
      state.userId = "";
      state.upsertUserError = {};
      state.upsertUserSuccess = false;
      state.upsertUserLoading = true;
    },

    createUserSuccess: (state, action) => {
      state.upsertUserLoading = false;
      state.upsertUserSuccess = true;
      state.userId = action.payload.userId;
    },

    createUserFailure: (state, action) => {
      state.upsertUserLoading = false;
      state.upsertUserSuccess = false;
      state.upsertUserError = action.payload.error;
    },

    // Update User
    updateUser: (state) => {
      state.upsertUserError = {};
      state.upsertUserSuccess = false;
      state.upsertUserLoading = true;
    },

    updateUserSuccess: (state) => {
      state.upsertUserLoading = false;
      state.upsertUserSuccess = true;
    },

    updateUserFailure: (state, action) => {
      state.upsertUserLoading = false;
      state.upsertUserSuccess = false;
      state.upsertUserError = action.payload.error;
    },

    // Enable User
    enableUser: (state, action) => {
      const { userId } = action.payload;
      state.userStatusLoadingmap[userId] = true;
      state.userStatusError = {};
    },

    enableUserSuccess: (state, action) => {
      const { userId } = action.payload;
      state.userMap[userId][USER_STATUS.IS_ACTIVE] = true;
      delete state.userStatusLoadingmap[userId];
    },

    enableUserFailure: (state, action) => {
      const { userId, error } = action.payload;
      delete state.userStatusLoadingmap[userId];
      state.userStatusError = error;
    },

    // Disable User
    disableUser: (state, action) => {
      const { userId } = action.payload;
      state.userStatusLoadingmap[userId] = true;
      state.userStatusError = {};
    },

    disableUserSuccess: (state, action) => {
      const { userId } = action.payload;
      state.userMap[userId][USER_STATUS.IS_ACTIVE] = false;
      delete state.userStatusLoadingmap[userId];
    },

    disableUserFailure: (state, action) => {
      const { userId, error } = action.payload;
      delete state.userStatusLoadingmap[userId];
      state.userStatusError = error;
    },

    // Get User
    getUser: (state) => {
      state.getUserError = {};
      state.getUserLoading = true;
      state.user = {};
    },

    getUserSuccess: (state, action) => {
      const { user } = action.payload;
      state.user = user;
      state.getUserLoading = false;
    },

    getUserFailure: (state, action) => {
      state.getUserLoading = false;
      state.getUserError = action.payload.error;
    },

    // Change User Password
    updateUserPassword: (state) => {
      state.updateUserPasswordLoading = true;
      state.updateUserPasswordSuccess = false;
      state.updateUserPasswordError = {};
    },

    updateUserPasswordSuccess: (state) => {
      state.updateUserPasswordLoading = false;
      state.updateUserPasswordSuccess = true;
    },

    updateUserPasswordFailure: (state, action) => {
      state.updateUserPasswordLoading = false;
      state.updateUserPasswordSuccess = false;
      state.updateUserPasswordError = action.payload.error;
    },

    updateUserRole: (state) => {
      state.updateUserRoleLoading = true;
      state.updateUserRoleSuccess = false;
      state.updateUserRoleError = {};
    },

    updateUserRoleSuccess: (state, action) => {
      const { userId, role } = action.payload;
      state.userMap[userId][USER_OBJECT_KEYS.ROLE] = role;
      state.updateUserRoleLoading = false;
      state.updateUserRoleSuccess = true;
    },

    updateUserRoleFailure: (state, action) => {
      state.updateUserRoleLoading = false;
      state.updateUserRoleSuccess = false;
      state.updateUserRoleError = action.payload.error;
    },

    // List Users
    searchUsers: (state) => {
      state.searchUsersError = {};
      state.searchUsersLoading = true;
    },

    searchUserSuccess: (state, action) => {
      const { userListObj } = action.payload;
      const [usersList = [], authUsersList = {}] = getTransformedUserList(userListObj);

      state.userMap = UserTableUtils.constructUserListTableData(usersList, authUsersList);
      state.searchUsersLoading = false;
    },

    searchUserFailure: (state, action) => {
      state.searchUsersLoading = false;
      state.searchUsersError = action.payload.error;
    },

    reset: () => initialState,
  },
});

// Reducer
export const UserReducer = userSlice.reducer;

// Actions
export const UserActions = userSlice.actions;
