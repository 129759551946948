// Auth Transformers
function transformObj(authObj = {}) {
  const auth = {
    ...authObj,
  };

  return auth;
}

function transformList(authObjList = []) {
  const authList = authObjList.map((obj) => {
    return transformObj(obj);
  });

  return authList;
}

// Exports
const AuthTransformer = {
  transformObj,
  transformList,
};

export default AuthTransformer;
