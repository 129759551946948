//
import moment from "moment";

import DecimalUtils from "../utils/DecimalUtils";

//
// Helper Methods
// ----------------------------------------------------------------------------

function varsAsTableHeaders(varArray = {}) {
  // Headers
  const headersArray = varArray.map((v) => {
    const { name, symbol, varType } = v;
    const header = {
      title: symbol,
      label: name,
      dataIndex: symbol,
    };

    return header;
  });

  return headersArray;
}

function runsAsTableRows(optRunList = []) {
  // Construct rows
  const rowList = optRunList.map((optRun) => {
    const { id, objectivesCost = {}, solution = {} } = optRun;

    const objCosts = Object.values(objectivesCost);
    const objCost = objCosts.length > 0 ? DecimalUtils.fixDecimal(objCosts[0], 2) : "-";

    // Row Object
    const rowObj = {
      key: id,
      objCost: objCost,
    };

    // add var values
    Object.keys(solution).forEach((k) => {
      const val = solution[k];
      rowObj[k] = val ? DecimalUtils.fixDecimal(val, 2) : "-";
    });

    return rowObj;
  });

  return rowList;
}

// Exports
// ----------------------------------------------------------------------------

const OptRunHelper = {
  varsAsTableHeaders,
  runsAsTableRows,
};

export default OptRunHelper;
