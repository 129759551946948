//
// OptRun Transformers
//

function transformObj(optRunObj) {
  //
  const { id = "", projectId, group, isSolutionFound, objectivesCost, solution } = optRunObj;
  const optRun = {
    id: id,
    projectId: projectId,
    group: group,
    isSolutionFound: isSolutionFound,
    objectivesCost: objectivesCost,
    solution: solution,
  };

  return optRun;
}

function transformList(optRunObjList) {
  const optRunList = optRunObjList.map((obj) => {
    return transformObj(obj);
  });

  return optRunList;
}

// Exports
// ----------------------------------------------------------------------------

const OptRunTransformer = {
  transformObj,
  transformList,
};

export default OptRunTransformer;
