//
import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { OptSetupActions } from "../../redux-slice/project/optSetupSlice";
import { setErrorInfo } from "../../app/error/ErrorActions";

// Service
import OptSetupService from "../../services/project/optSetupService";

// Utils
import UrlUtils from "../../utils/urlUtils";

// Constants
import { QueryParamsKeys } from "../../constants/WebConstants";
import PageURL from "../../constants/pageUrls";

//
// Saga Functions
// ----------------------------------------------------------------------------

// Create OptSetup
function* createOptSetup(action) {
  const { projectId, optSetupObj, navigate } = action.payload;
  try {
    const { id = "" } = yield OptSetupService.createOptSetup(projectId, optSetupObj);

    // Dispatching Action
    yield put(OptSetupActions.createOptSetupSuccess());

    // Toaster
    toast.success("Save & Setup completed successfully");

    //
    // Navigate to OptSetup View Page
    const pageURI = UrlUtils.format(PageURL.OptSetupPageURL, { projectId });
    const pageURL = pageURI + `?${QueryParamsKeys.optSetupId}=${id}`;
    navigate(pageURL);

    //
  } catch (error) {
    yield put(OptSetupActions.createOptSetupFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Update OptSetup
function* updateOptSetup(action) {
  const { projectId, optSetupId, optSetupObj } = action.payload;
  try {
    yield OptSetupService.updateOptSetup(projectId, optSetupId, optSetupObj);

    const optSetup = yield OptSetupService.getOptSetup(projectId, optSetupId);

    // Dispatching Action
    yield put(OptSetupActions.getOptSetupSuccess({ optSetup }));

    // Dispatching Action
    yield put(OptSetupActions.updateOptSetupSuccess());

    // Toaster
    toast.success("Updated successfully");
  } catch (error) {
    yield put(OptSetupActions.updateOptSetupFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Get OptSetup
function* getOptSetup(action) {
  const { projectId, optSetupId } = action.payload;
  try {
    const optSetup = yield OptSetupService.getOptSetup(projectId, optSetupId);

    // Dispatching Action
    yield put(OptSetupActions.getOptSetupSuccess({ optSetup }));
  } catch (error) {
    yield put(OptSetupActions.getOptSetupFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Get OptSetup list
function* searchOptSetups(action) {
  try {
    const { projectId, searchText, pageNumber, pageSize } = action.payload;

    const { items, pagination } = yield OptSetupService.searchOptSetups(projectId, searchText, pageNumber, pageSize);

    // Dispatching Action
    yield put(OptSetupActions.searchOptSetupsSuccess({ items, pagination }));
  } catch (error) {
    yield put(OptSetupActions.searchOptSetupsFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

//
// Export

export default function* root() {
  yield all([
    //
    takeLatest(OptSetupActions.createOptSetup.type, createOptSetup),

    //
    takeLatest(OptSetupActions.updateOptSetup.type, updateOptSetup),

    //
    takeLatest(OptSetupActions.getOptSetup.type, getOptSetup),
    takeLatest(OptSetupActions.searchOptSetups.type, searchOptSetups),
  ]);
}
