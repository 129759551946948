// Utils
import UrlUtils from "../../utils/urlUtils";

// URLs
import { ParameterUrls } from "../../constants/serviceUrls";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Create Parameter
const createParameter = async function (graphId, parameterObj) {
  // API URL
  const apiURL = UrlUtils.format(ParameterUrls.createParameter, { graphId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(apiURL, {}, parameterObj);
};

// Update Parameter
const updateParameter = async function (graphId, parameterId, parameterObj) {
  // API URL
  const apiURL = UrlUtils.format(ParameterUrls.updateParameter, { graphId, parameterId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiURL, {}, parameterObj);
};

// Delete Parameter
const deleteParameter = async function (graphId, parameterId) {
  // API URL
  const apiURL = UrlUtils.format(ParameterUrls.deleteParameter, { graphId, parameterId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.delete(apiURL);
};

// Export
const ParameterService = {
  //
  createParameter,

  //
  updateParameter,

  //
  deleteParameter,
};

export default ParameterService;
