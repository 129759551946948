import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { EquationActions } from "../../redux-slice/graph/equationSlice";
import { setErrorInfo } from "../../app/error/ErrorActions";

// Service
import EquationService from "../../services/graph/equationService";

// Constants
import GraphConfig from "../../reactflow/GraphConfig";

//
const NODE_EQNS = GraphConfig.NodeDataProp.EQUATIONS;

// Create Equation
export function* createEquation(action) {
  const { equationObj, updateNodeMathData } = action.payload;

  try {
    const { graphId } = equationObj;
    const newEquation = yield EquationService.createEquation(graphId, equationObj);

    // Callback
    updateNodeMathData(NODE_EQNS, newEquation, false);

    yield put(EquationActions.addEquationSuccess());
  } catch (error) {
    yield put(EquationActions.addEquationFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Update Equation
export function* updateEquation(action) {
  const { equationId, equationObj, updateNodeMathData } = action.payload;

  try {
    const { graphId } = equationObj;
    yield EquationService.updateEquation(graphId, equationId, equationObj);

    // Callback
    updateNodeMathData(NODE_EQNS, equationObj, false);

    yield put(EquationActions.updateEquationSuccess());
  } catch (error) {
    yield put(EquationActions.updateEquationFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Delete Equation
export function* deleteEquation(action) {
  const { equationObj, updateNodeMathData } = action.payload;

  try {
    const { graphId, id: equationId } = equationObj;
    yield EquationService.deleteEquation(graphId, equationId);

    // Callback
    updateNodeMathData(NODE_EQNS, equationObj, true);

    yield put(EquationActions.deleteEquationSuccess());
  } catch (error) {
    yield put(EquationActions.deleteEquationFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Export
export default function* root() {
  yield all([
    //
    takeLatest(EquationActions.addEquation.type, createEquation),

    //
    takeLatest(EquationActions.updateEquation.type, updateEquation),

    //
    takeLatest(EquationActions.deleteEquation.type, deleteEquation),
  ]);
}
