// Constants

// URLs
const PageURL = {
  // Login Page
  LoginPageURL: "/a/login",
  ForgotPasswordPageURL: "/a/forgot-password",

  // Profile Page
  ProfilePageURL: "/profile",

  // Home Page
  DashboardPageURL: "/",

  // Users Page
  UserListPageURL: "/users",

  // Project Pages
  ProjectListPageURL: "/p/list",
  ProjectViewPageURL: "/p/:projectId",

  ProjectDataPageURL: "/p/:projectId/data",
  ProjectExecutePageURL: "/p/:projectId/run",
  ProjectResultPageURL: "/p/:projectId/result",

  // Project Pages :: Graph
  GraphCanvasPageURL: "/p/:projectId/canvas",
  GraphSourcePageURL: "/p/:projectId/source",

  // Project Pages :: OptSetup and Run
  OptSetupPageURL: "/p/:projectId/opt/setup",
  OptSetupListPageURL: "/p/:projectId/opt/list",
  OptSetupViewPageURL: "/p/:projectId/opt/:optSetupId",

  OptRunPageURL: "/p/:projectId/opt/:optSetupId/run",
  OptRunListPageURL: "/p/:projectId/opt/:optSetupId/runs",

  OptRunProblemPageURL: "/p/:projectId/opt/:optSetupId/run/problem",

  OptRunJobListPageURL: "/p/:projectId/opt/:optSetupId/run/jobs",

  // Project Pages :: File
  ProjectFileListPageURL: "/p/:projectId/files",

  // Error Page
  ErrorPageURL: "/error",

  //
};

// Export
export default PageURL;
