import { useState } from "react";

// Components
import ExpressionInput from "../../../../components/input-expression/ExpressionInput";

/**
 * Objective Function Card
 * @param {*} param0
 * @returns
 */
export default function ObjectiveFunctionCard({
  varSuggestions = [],
  objectiveInfo = {},
  setObjectiveInfo = () => {},
}) {
  // Objective Information
  const { name = "", isMinimize = "", costExpression = "" } = objectiveInfo || {};

  // State
  const [equatioInpVal, setEquationInpVal] = useState(costExpression);

  // Handle Change Function
  function handleEquationChange(e, newValue, newPlainTextValue) {
    // Updating input value state
    setEquationInpVal(e.target.value);

    // Updating state with plain text without any special characters
    setObjectiveInfo((prev) => ({ ...prev, costExpression: newPlainTextValue }));
  }

  function handleChange({ target }, key) {
    setObjectiveInfo((prev) => ({ ...prev, [key]: target.value }));
  }

  //
  return (
    <div className="card card-info mb-3">
      <div className="card-body">
        {/** */}
        <div className="row mb-3">
          <label className="col-2 col-form-label">Name</label>
          <div className="col-10">
            <input value={name} className="form-control" onChange={(e) => handleChange(e, "name")} />
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-2 col-form-label">Objective</label>
          <div className="col-10">
            <select value={isMinimize} onChange={(e) => handleChange(e, "isMinimize")} className="form-select">
              <option value={true}>Minimize</option>
              <option value={false}>Maximize</option>
            </select>
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-2 col-form-label">Cost</label>
          <div className="col-10">
            <ExpressionInput
              value={equatioInpVal}
              suggestions={varSuggestions}
              onChange={handleEquationChange}
              placeholder={"Objective function ..."}
            />

            <div className="pt-2">
              <b>Note : </b>
              Please provide space between operators. eg: (a + b)
            </div>
          </div>
        </div>

        {/** */}
      </div>
    </div>
  );
}
