// Components
import Button from "../../../../components/button/Button";

/**
 * User Modal Footer
 * @param {*} handleReset : Funtion
 * @param {*} handleSubmit : Function
 * @param {*} btnLoading : Boolean Value
 */
export default function UserModalFooter({
  handleReset = () => {},
  handleSubmit = () => {},
  btnLoading = false,
  upsertBtnName = "Submit",
}) {
  return (
    <div className="d-flex justify-content-end gap-3">
      <Button onClick={handleReset} className="btn-outline-dark btn-outline-custom">
        Reset
      </Button>

      <Button onClick={handleSubmit} className="btn btn-primary" loading={btnLoading}>
        {upsertBtnName}
      </Button>
    </div>
  );
}
