import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Components
import DataTable from "../../../../components/antd/table/DataTable";
import SkeletonTable from "../../../../components/antd/table/SkeletonTable";
import Icon from "../../../../components/icon/Icon";

// Constants
import PageURL from "../../../../constants/pageUrls";

// Utils
import UrlUtils from "../../../../utils/urlUtils";
import ProjectTableUtils from "../../../../utils/projectTableUtils";

// Section
import ProjectTablePagination from "./ProjectTablePagination";

// Page Constants
const EYE_ICON_SIZE = 16;

// Page Components
function ProjectTableActions({ record = {} }) {
  const navigate = useNavigate();

  const { key: id } = record;
  const projectCanvasURL = UrlUtils.format(PageURL.GraphCanvasPageURL, { projectId: id });

  return (
    <div>
      <Icon
        iconName="eye"
        title="View"
        size={EYE_ICON_SIZE}
        onClick={() => navigate(projectCanvasURL)}
        strokeWidth={1.5}
      />
    </div>
  );
}

export default function ProjectsTable({ currentPage = "", pageSize = "", dataLoading }) {
  // Selectors
  const projectsMap = useSelector((state) => state.project.projectsMap);

  // Constructing columns for users table
  const actionColumn = {
    title: "Action",
    Key: "action",
    render: (record) => {
      return <ProjectTableActions record={record} />;
    },
  };

  const projectTableHeaders = ProjectTableUtils.getProjectTableHeader(currentPage, pageSize);
  const columns = [...projectTableHeaders, actionColumn];

  if (dataLoading) {
    return <SkeletonTable columnCount={5} rowCount={10} />;
  }

  //
  return (
    <>
      <DataTable rows={Object.values(projectsMap)} columns={columns} applyTableBorder={true} />

      {/* Table Pagination..*/}
      <ProjectTablePagination pageSize={pageSize} />
    </>
  );
}
