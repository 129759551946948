import { useSearchParams } from "react-router-dom";

// Components
import Pagination from "../../../../components/antd/pagination/Pagination";

export default function ProjectFileTablePagination({ fileListLoading = false, fileListPagination = {} }) {
  //Search Params state
  const [searchParams, setSearchParams] = useSearchParams();

  const { pageNo: currentPage, pageSize, totalCount } = fileListPagination || {};

  //Function
  function handleChange(pageNo) {
    setSearchParams({
      pn: pageNo,
      ps: pageSize,
    });
  }

  if (fileListLoading || !pageSize) {
    return null;
  }

  return (
    <div className={`d-flex justify-content-center mt-4`}>
      <Pagination currentPage={currentPage} totalCount={totalCount} pageSize={pageSize} onPageChange={handleChange} />
    </div>
  );
}
