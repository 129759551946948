//
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";

// Actions
import { GraphActions } from "../../redux-slice/graph/graphSlice";

// Components
import Icon from "../../components/icon/Icon";
import NavLeft from "./NavLeft";
import NavTop from "./NavTop";

// Utils
import UrlUtils from "../../utils/urlUtils";

// Constants
import PageURL from "../../constants/pageUrls";

// Constants :: Local
const ProjectNavItems = [
  {
    id: "1",
    icon: <Icon iconName="shapes" />,
    name: "Build",
    link: PageURL.GraphCanvasPageURL,
  },
  {
    id: "2",
    icon: <Icon iconName="code" />,
    name: "Source",
    link: PageURL.GraphSourcePageURL,
  },
  {
    id: "3",
    icon: <Icon iconName="play" />,
    name: "Opt",
    link: PageURL.OptSetupListPageURL,
  },
  {
    id: "4",
    icon: <Icon iconName="table2" />,
    name: "Data",
    link: PageURL.ProjectDataPageURL,
  },
  {
    id: "5",
    icon: <Icon iconName="files" />,
    name: "Files",
    link: PageURL.ProjectFileListPageURL,
  },
  {
    id: "6",
    icon: <Icon iconName="info" />,
    name: "Info",
    link: PageURL.ProjectViewPageURL,
  },
  {
    id: "7",
    icon: <Icon iconName="library" />,
    name: "Library",
    link: "/p/:projectId/library",
  },
];

// Layout
// ----------------------------------------------------------------------------

function TopSectionLeft({ project }) {
  const { name } = project;
  return <h5 className="my-3 pt-1 ps-4">{name}</h5>;
}

function TopSectionCenter({ project }) {
  return <></>;
}

function TopSectionRight({ project, activeItem, dispatchGraphValidation }) {
  //
  const { id: projectId, name, updatedOn } = project;

  //
  return (
    <div className="py-2 me-3" style={{ minWidth: "240px" }}>
      <div className={`${activeItem === "1" ? "" : "d-none"}`}>
        <button className="btn btn-sm btn-outline-primary" onClick={() => dispatchGraphValidation({})}>
          Validate Graph
        </button>
      </div>
    </div>
  );
}

/**
 * Layout Component for Project Pages
 */
export default function LayoutProject({ themeId }) {
  // Dispatch
  const dispatch = useDispatch();

  // Page Params
  const params = useParams();
  const { projectId } = params;
  const graphId = projectId; // graphId is just "projectId"

  // State
  const [activeItem, setActiveItem] = useState("1");

  // State :: Selected
  const project = useSelector((state) => state.project.projectInfo);

  //
  // Page Functions

  // Graph Validation
  const dispatchGraphValidation = () => {
    // dispatch(GraphActions.validateGraph({ graphId, nodes, edges, parametersMap, equationsMap }));
    dispatch(GraphActions.validateGraph({ graphId }));
  };

  //
  // Format Components with Project information

  // Update left nav-items URLs with projectId
  const navItems = ProjectNavItems.map((item) => {
    const link = item.link;
    const formattedLink = UrlUtils.format(link, { projectId });
    return { ...item, link: formattedLink };
  });

  // Top Nav items
  const sectionLeft = <TopSectionLeft project={project} />;
  const sectionCenter = <TopSectionCenter project={project} />;
  const sectionRight = (
    <TopSectionRight project={project} activeItem={activeItem} dispatchGraphValidation={dispatchGraphValidation} />
  );

  //
  return (
    <div className={"page layout-project"} data-theme={themeId}>
      {/** NavTop :: Customized for Project */}
      <NavTop sectionLeft={sectionLeft} sectionCenter={sectionCenter} sectionRight={sectionRight} />

      <div className="page-wrapper">
        {/** NavLeft :: Customized for Project */}
        <NavLeft navItems={navItems} activeItem={activeItem} setActiveItem={setActiveItem} />

        {/** Page specific Content */}
        <Outlet />
      </div>
    </div>
  );
}
