//
import { createSlice } from "@reduxjs/toolkit";

// Utils
import OptRunJobTransformer from "./optRunJobTransformer";

// Intital state
const initialState = {
  // Create OptRun :: Job
  createOptRunJobLoading: false,
  createOptRunJobError: "",

  optRunJobList: [],
  optRunJobListPagination: {},
  optRunJobListLoading: false,
  optRunJobListError: "",
};

// OptRunJob Slice
const optRunJobSlice = createSlice({
  // Name
  name: "OptRunJob",

  // Initial State
  initialState,

  // Reducers
  reducers: {
    // Create OptRunJob
    createOptRunJob: (state) => {
      state.createOptRunJobLoading = true;
    },

    createOptRunJobSuccess: (state) => {
      state.createOptRunJobLoading = false;
      state.createOptRunJobError = "";
    },

    createOptRunJobFailure: (state, action) => {
      state.createOptRunJobLoading = false;
      state.createOptRunJobError = action.payload.error;
    },

    // Get OptRun Jobs List
    getOptRunJobs: (state) => {
      state.optRunJobListLoading = true;
      state.optRunJobListError = "";
    },

    getOptRunJobsSuccess: (state, action) => {
      const { optRunJobList, optRunJobListPagination } = action.payload;
      state.optRunJobListPagination = optRunJobListPagination;

      const transformedOptRunJobList = OptRunJobTransformer.transformList(optRunJobList);
      // state.optRunJobsMap = OptRunJobTableUtils.constructOptRunJobListTableData(transformedOptRunJobList);

      state.optRunJobListLoading = false;
    },

    getOptRunJobsFailure: (state, action) => {
      state.optRunJobListLoading = false;
      state.optRunJobListError = action.payload.error;
    },
  },
});

// Reducer
export const OptRunJobReducer = optRunJobSlice.reducer;

// Actions
export const OptRunJobActions = optRunJobSlice.actions;
