import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Form } from "antd";

// Actions
import { ProjectActions } from "../../../redux-slice/project/projectSlice";
import { GraphActions } from "../../../redux-slice/graph/graphSlice";
import { OptSetupActions } from "../../../redux-slice/project/optSetupSlice";
import { OptRunActions } from "../../../redux-slice/project/optRunSlice";
import { ProjectFileActions } from "../../../redux-slice/project/projectFileSlice";

// Constants
import { QueryParamsKeys } from "../../../constants/WebConstants";
import PageURL from "../../../constants/pageUrls";

// Utils
import UrlUtils from "../../../utils/urlUtils";
import GraphHelper from "../../../helpers/graphHelper";
import OptRunHelper from "../../../helpers/optRunHelper";
import FileUtils from "../../../utils/fileUtils";

// Components
import PageHeader from "../../../app/layout/PageHeader";
import OptRunTable from "./components/OptRunTable";
import OptRunGraph from "./components/OptRunGraph";
import SaveGraphModal from "./components/SaveGraphModal";
import SkeletonTable from "../../../components/antd/table/SkeletonTable";

// Helper Functions
function getVarArray(graphData) {
  const varSummary = GraphHelper.getVarSummary(graphData);
  const { graphVars = [], nodeVars = [], edgeVars = [] } = varSummary || {};
  const varArray = [...graphVars, ...nodeVars, ...edgeVars];
  return varArray;
}

//
// Page Components
// ----------------------------------------------------------------------------

//
function PageHeaderSection({ projectInfo = {}, optSetup = {} }) {
  //
  const { id: projectId } = projectInfo;
  const { id: optSetupId, name, notes } = optSetup;

  // URLs
  const optSetupPageURL = UrlUtils.format(PageURL.OptSetupPageURL, { projectId }, { optSetupId });

  // Content
  const pageTitle = name + " :: Solutions";
  const pageActions = (
    <div className="btn-cont">
      <Link className="btn btn-outline-primary" to={optSetupPageURL}>
        View Opt Setup
      </Link>
    </div>
  );

  return <PageHeader title={pageTitle} actions={pageActions} />;
}

//

/**
 * Opt Run List Page
 */
export default function OptRunListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Page Path Params
  const params = useParams();
  const { projectId = "", optSetupId = "" } = params;
  const graphId = projectId; // graphId is just "projectId"

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chartTitle, setChartTitle] = useState("");
  const [base64Image, setBase64Image] = useState("");
  const [varArray, setVarArray] = useState([]);

  // Selector State
  const projectInfo = useSelector((state) => state.project.projectInfo);
  const optSetup = useSelector((state) => state.optSetup.optSetup);

  const optRunList = useSelector((state) => state.optRun.optRunList);
  const optRunListPagination = useSelector((state) => state.optRun.optRunListPagination);
  const optRunListLoading = useSelector((state) => state.optRun.optRunListLoading);

  const graphData = useSelector((state) => state.graph.graphData);

  const [form] = Form.useForm();

  // Page Query Params
  const [searchParams] = useSearchParams();
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber) ?? "";
  const pageSize = searchParams.get(QueryParamsKeys.pageSize) ?? "";

  const optRunDataList = OptRunHelper.runsAsTableRows(optRunList);

  function openModal(base64String, chartLayout) {
    const { title = "" } = chartLayout;

    setBase64Image(base64String);
    setIsModalOpen(true);
    setChartTitle(title.text || "");
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  function onSave(values) {
    const { title = "", notes = "" } = values;
    const file = FileUtils.base64ToFile(base64Image, "new");

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileKind", "REPORT");
    formData.append("name", title);
    formData.append("title", title);
    formData.append("notes", notes);

    dispatch(ProjectFileActions.createFile({ projectId, fileData: formData }));
  }

  // Dispatch API calls
  useEffect(() => {
    dispatch(ProjectActions.getProject({ projectId }));
    dispatch(GraphActions.getGraphData({ graphId }));
  }, [projectId, graphId, dispatch]);

  useEffect(() => {
    dispatch(OptSetupActions.getOptSetup({ projectId, optSetupId }));
    dispatch(OptRunActions.searchOptRuns({ projectId, optSetupId, pageNumber, pageSize: 1000 }));
  }, [projectId, optSetupId, pageNumber, pageSize, dispatch]);

  useEffect(() => {
    const result = getVarArray(graphData);
    setVarArray(result);
  }, [graphData]);

  useEffect(() => {
    form.setFieldsValue({ title: chartTitle, notes: "" });
  }, [isModalOpen, chartTitle]);

  //
  return (
    <div className="main-cont position-right-0">
      <div className="content-wrapper">
        {/** Header */}
        <PageHeaderSection projectInfo={projectInfo} optSetup={optSetup} />

        {/* Page Content */}
        <div className="page-content">
          <div className="row">
            {optRunListLoading && <SkeletonTable columnCount={10} rowCount={5} />}

            {!optRunListLoading && (
              <>
                <div className="col-7">
                  {/** OptRun Data  */}
                  <OptRunTable
                    optSetup={optSetup}
                    varArray={varArray}
                    records={optRunDataList}
                    pagination={optRunListPagination}
                  />
                </div>

                <div className="col-5">
                  <OptRunGraph varArray={varArray} dataList={optRunDataList} openModal={openModal} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <SaveGraphModal
        isModalOpen={isModalOpen}
        base64Image={base64Image}
        closeModal={closeModal}
        onSave={onSave}
        form={form}
      />
    </div>
  );
}
