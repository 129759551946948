// Graph Transformers
function transformObj(graphObj = {}) {
  const graph = {
    ...graphObj,
  };

  return graph;
}

function transformList(graphObjList = []) {
  const graphList = graphObjList.map((obj) => {
    return transformObj(obj);
  });

  return graphList;
}

// Exports
const GraphTransformer = {
  transformObj,
  transformList,
};

export default GraphTransformer;
