//
import Logo from "../../components/logo/Logo";

//
// Component
// ----------------------------------------------------------------------------

const NavBranding = ({ className = "", logoURL = "", redirectURL }) => {
  return (
    <div className={`navbar-branding ${className}`}>
      <Logo
        className={"pt-2"}
        imageURL={logoURL}
        imageHeight={36}
        redirect={redirectURL && redirectURL.length > 0 ? true : false}
        redirectURL={redirectURL}
      />
    </div>
  );
};

export default NavBranding;
