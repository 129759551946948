// Constants
import { ContentType } from "../../app/http/HttpConstants";

// Urls
import { AuthUrls } from "../../constants/serviceUrls";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Login
async function login(username, password) {
  const bodyFormData = new FormData();
  bodyFormData.set("username", username);
  bodyFormData.set("password", password);

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(AuthUrls.login, {}, bodyFormData, ContentType.APP_FORM_URL_ENCODED);
}

// Reset Password
async function resetPassword(email = "", newPassword = "") {
  const params = {
    userEmail: email,
  };

  const bodyFormData = new FormData();
  bodyFormData.set("newPassword", newPassword);

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(AuthUrls.resetPassword, params, bodyFormData, ContentType.APP_FORM_URL_ENCODED);
}

const AuthService = {
  login,
  resetPassword,
};

export default AuthService;
