// File Transformers
function transformObj(fileObj = {}) {
  const {
    id = "",
    updatedOn = "",
    projectId = "",
    fileKind = "",
    fileName = "",
    fileType = "",
    fileURL = "",
    title = "",
    notes = "",
  } = fileObj;

  const file = {
    id: id,
    updatedOn: updatedOn,
    projectId: projectId,
    fileKind: fileKind,
    fileName: fileName,
    fileType: fileType,
    fileURL: fileURL,
    title: title,
    notes: notes,
  };

  return file;
}

function transformList(fileObjList = []) {
  const fileList = fileObjList.map((obj) => {
    return transformObj(obj);
  });

  return fileList;
}

// Exports
const ProjecFileTransformer = {
  transformObj,
  transformList,
};

export default ProjecFileTransformer;
