import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

// Components
import Pagination from "../../../../components/antd/pagination/Pagination";

export default function ProjectTablePagination({ pageSize }) {
  //Search Params state
  const [searchParams, setSearchParams] = useSearchParams();

  const projectListLoading = useSelector((state) => state.project.projectListLoading);
  const projectListPagination = useSelector((state) => state.project.projectListPagination);
  const { pageNo: currentPage, totalCount } = projectListPagination || {};

  //Function
  function handleChange(pageNo) {
    setSearchParams({
      pn: pageNo,
      ps: pageSize,
    });
  }

  if (projectListLoading || !pageSize) {
    return null;
  }

  return (
    <div className={`d-flex justify-content-center mt-4`}>
      <Pagination currentPage={currentPage} totalCount={totalCount} pageSize={pageSize} onPageChange={handleChange} />
    </div>
  );
}
