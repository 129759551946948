//
import { createSlice } from "@reduxjs/toolkit";

// Utils
import OptSetupTableUtils from "../../utils/optSetupTableUtils";
import OptSetupTransformer from "./optSetupTransformer";

// Intital state
const initialState = {
  // Create OptSetup
  createOptSetupLoading: false,
  createOptSetupError: "",

  // Update OptSetup
  updateOptSetupLoading: false,
  updateOptSetupError: "",

  // Get OptSetup
  optSetup: {},
  optSetupLoading: false,
  optSetupError: "",

  // Search
  optSetupsMap: {},
  optSetupListPagination: {},
  optSetupListLoading: false,
  optSetupListError: "",
};

// OptSetup Slice
const optSetupSlice = createSlice({
  // Name
  name: "OptSetup",

  // Initial State
  initialState,

  // Reducers
  reducers: {
    // Create OptSetup
    createOptSetup: (state) => {
      state.createOptSetupLoading = true;
    },

    createOptSetupSuccess: (state) => {
      state.createOptSetupLoading = false;
      state.createOptSetupError = "";
    },

    createOptSetupFailure: (state, action) => {
      state.createOptSetupLoading = false;
      state.createOptSetupError = action.payload.error;
    },

    // Update OptSetup
    updateOptSetup: (state) => {
      state.updateOptSetupLoading = true;
    },

    updateOptSetupSuccess: (state) => {
      state.updateOptSetupLoading = false;
      state.updateOptSetupError = "";
    },

    updateOptSetupFailure: (state, action) => {
      state.updateOptSetupLoading = false;
      state.updateOptSetupError = action.payload.error;
    },

    // Get OptSetup
    getOptSetup: (state) => {
      state.optSetupLoading = true;
    },

    getOptSetupSuccess: (state, action) => {
      state.optSetup = action.payload.optSetup;
      state.optSetupLoading = false;
      state.optSetupError = "";
    },

    getOptSetupFailure: (state, action) => {
      state.optSetupLoading = false;
      state.optSetupError = action.payload.error;
    },

    // Search OptSetup
    searchOptSetups: (state) => {
      state.optSetupListLoading = true;
      state.optSetupListError = "";
    },

    searchOptSetupsSuccess: (state, action) => {
      const { items, pagination } = action.payload;

      state.optSetupListPagination = pagination;
      const optSetupsList = OptSetupTransformer.transformList(items);

      state.optSetupsMap = OptSetupTableUtils.constructOptSetupListTableData(optSetupsList);
      state.optSetupListLoading = false;
    },

    searchOptSetupsFailure: (state, action) => {
      state.optSetupListLoading = false;
      state.optSetupListError = action.payload.error;
    },
  },
});

// Reducer
export const OptSetupReducer = optSetupSlice.reducer;

// Actions
export const OptSetupActions = optSetupSlice.actions;
