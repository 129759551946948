import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { AuthActions } from "../../redux-slice/auth/authSlice";

// Urls
import PageURL from "../../constants/pageUrls";

// Constants
import { ICON_SIZE } from "../../constants/GeneralConstants";

// Components
import Button from "../../components/button/Button";
import Icon from "../../components/icon/Icon";
import Logo from "../../components/logo/Logo";

// Images
import URL_IMG_LOGO from "../../assests/imgs/branding/logo.png";

// Page Components
// ----------------------------------------------------------------------------

function LoginError({ loginError }) {
  //If No error
  if (!loginError) {
    return null;
  }

  return (
    <div className="card bg-secondary-subtle mt-3">
      <div className="card-body text-center text-danger">
        <Icon iconName="circleX" size={ICON_SIZE.MD} className="me-2" />
        Username/Password is incorrect
      </div>
    </div>
  );
}

function ForgotPasswordSection() {
  return (
    <div className="mt-4 text-center">
      <Link to={PageURL.ForgotPasswordPageURL} className="text-muted text-decoration-none">
        <Icon iconName="lock" size={ICON_SIZE.MD} className="me-2" />
        Forgot your password?
      </Link>
    </div>
  );
}

function LoginCard() {
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Page State
  const [formData, setFormData] = useState({});

  // Selector State
  const loginLoading = useSelector((state) => state.auth.loginLoading) || false;
  const loginError = useSelector((state) => state.auth.loginError) || false;

  // Input onChange
  const handleInputChange = function ({ target }) {
    const inpVal = target.value;
    const inpName = target.name;

    const formDataObj = { ...formData };
    formDataObj[inpName] = inpVal;

    setFormData(formDataObj);
  };

  // form onSubmit
  const handleFormSubmit = (event) => {
    // Prevent Default
    event.preventDefault();

    const { username, password } = formData;
    dispatch(AuthActions.login({ username, password, navigate }));
  };

  return (
    <div className="card shadow mt-5">
      <div className="card-body position-relative">
        {/* Logo Component */}
        <div className="img-cont d-flex justify-content-center">
          <Logo className="image" imageHeight={140} imageURL={URL_IMG_LOGO} redirect={false} />
        </div>

        <div className="p-2 pt-5">
          <form className="form-horizontal pb-5" onSubmit={handleFormSubmit}>
            <div className="form-group mb-4">
              <label className="form-label">Username</label>
              <input className="form-control" type="email" name="username" onChange={handleInputChange} />
            </div>
            <div className="form-group">
              <label className="form-label">Password</label>
              <input className="form-control" type="password" name="password" onChange={handleInputChange} />
            </div>

            {/* Login Error */}
            <LoginError loginError={loginError} />
            <div className="mt-4 d-grid">
              {/* Button Component */}
              <Button
                className="btn btn-sm btn-secondary btn-block"
                type="submit"
                loading={loginLoading}
                disabled={loginLoading}
              >
                Login
              </Button>
            </div>

            {/* Forgot Password Section */}
            {/* 
            <ForgotPasswordSection />
            */}
          </form>
        </div>
      </div>
    </div>
  );
}

function LoginCardFooter() {
  return (
    <div className="mt-5 text-center">
      <p></p>
    </div>
  );
}

/**
 * Login Page
 */
export default function LoginPage() {
  return (
    <div className="container my-5 pt-sm-5">
      <div className="row justify-content-center">
        <div className="col-md-8 col-lg-6 col-xl-5">
          {/* Login Card */}
          <LoginCard />

          {/* Login Footer */}
          <LoginCardFooter />
        </div>
      </div>
    </div>
  );
}
