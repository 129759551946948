//

// Utils
import UrlUtils from "../../utils/urlUtils";
import HttpClientFactory from "../../app/http/HttpClientFactory";

// URLs
import { OptRunUrls } from "../../constants/serviceUrls";
import { DefaultPagination } from "../../constants/WebConstants";

//
// Service Methods
// ----------------------------------------------------------------------------

//
// OptRun

// Create OptRun
const createOptRun = async function (projectId, optSetupId, optRunObj) {
  // API URL
  const apiURL = UrlUtils.format(OptRunUrls.createOptRun, { projectId, optSetupId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(apiURL, {}, optRunObj);
};

//
// Delete OptRun
const deleteOptRun = async function (projectId, optSetupId, optRunId) {
  // API URL
  const apiURL = UrlUtils.format(OptRunUrls.deleteOptRun, { projectId, optSetupId, optRunId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.delete(apiURL, {});
};

// Delete OptRuns by OptSetup
const deleteOptRunsBySetup = async function (projectId, optSetupId) {
  // API URL
  const apiURL = UrlUtils.format(OptRunUrls.deleteOptRunsBySetup, { projectId, optSetupId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.delete(apiURL, {});
};

//
// Get OptRun
const getOptRun = async function (projectId, optSetupId, optRunId) {
  // API URL
  const apiURL = UrlUtils.format(OptRunUrls.getOptRun, { projectId, optSetupId, optRunId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(apiURL);
};

// Search OptRun
const searchOptRuns = async function (projectId, optSetupId, searchParams, pageNumber, pageSize) {
  const params = {
    ...searchParams,
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  // API URL
  const apiURL = UrlUtils.format(OptRunUrls.searchOptRuns, { projectId, optSetupId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(apiURL, params);
};

//
// Problem

const getOptRunProblem = async function (projectId, optSetupId, optRunObj, format = "") {
  // Params
  const params = {
    _f: format,
  };

  // API URL
  const apiURL = UrlUtils.format(OptRunUrls.getOptRunProblem, { projectId, optSetupId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(apiURL, params, optRunObj);
};

//
// Export
// ----------------------------------------------------------------------------

const OptRunService = {
  //
  createOptRun,

  //
  deleteOptRun,
  deleteOptRunsBySetup,

  //
  getOptRun,
  searchOptRuns,

  //
  getOptRunProblem,
};

export default OptRunService;
