import moment from "moment";

// Utils
import TableUtils from "./tableUtils";

function constructProjectListTableData(projectList) {
  // Construct rows
  const projectRowMap = {};

  for (let index = 0; index < projectList.length; index++) {
    const { id = "", name = "", description = "", createdOn = "", updatedOn = "" } = projectList[index];

    const projectRow = {
      key: id,
      name,
      description,
      createdOn: moment(createdOn).format("MMM D, YYYY"),
      updatedOn: moment(updatedOn).fromNow(),
    };
    projectRowMap[id] = projectRow;
  }

  return projectRowMap;
}

function getProjectTableHeader(currentPage = "", pageSize = "") {
  return [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => TableUtils.tableSerialNumber(currentPage, pageSize, index),
    },
    {
      title: "Project Name",
      dataIndex: "name",
      render: (text, record) => {
        return (
          <div style={{ padding: 0 }}>
            {text}
            <span className="sub-text">{record.description}</span>
          </div>
        );
      },
    },
    {
      title: "Created",
      dataIndex: "createdOn",
    },
    {
      title: "Modified",
      dataIndex: "updatedOn",
    },
  ];
}

const ProjectTableUtils = { constructProjectListTableData, getProjectTableHeader };

export default ProjectTableUtils;
