import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Constants
import { DefaultPagination } from "../../../constants/WebConstants";
import { LocalStorageKeys } from "../../../constants/GeneralConstants";
import { USER_MODAL_TYPES } from "../../../constants/userConstants";

//Utils
import { getItem } from "../../../app/LocalStorage";

// Actions
import { UserActions } from "../../../redux-slice/user/userSlice";

// Components
import SkeletonTable from "../../../components/antd/table/SkeletonTable";
import Button from "../../../components/button/Button";

// Page Components
import UserPasswordChangeModal from "./components/UserPasswordChangeModal";
import UserCreateEditModal from "./components/UserCreateEditModal";
import UserRoleUpdateModal from "./components/UserRoleUpdateModal";
import UsersTable from "./components/UsersTable";

function Header({ setOpenModal = () => {} }) {
  function openAddUserModal() {
    setOpenModal({ state: true, type: USER_MODAL_TYPES.USER_DATA });
  }

  return (
    <div className="w-100 d-flex justify-content-between align-items-center my-3">
      <p className="fw-bold fs-5 m-0 p-0">Users List</p>
      <Button className="btn-primary" onClick={openAddUserModal}>
        Add User
      </Button>
    </div>
  );
}

export default function UserListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Getting orgId from the Local Storage
  const { id: orgId } = JSON.parse(getItem(LocalStorageKeys.AUTH_ORG));

  // States
  const [openModal, setOpenModal] = useState({ state: "false", type: "" });
  const [userData, setUserData] = useState({});

  const { key: userId } = userData;

  const searchUsersLoading = useSelector((state) => state.user.searchUsersLoading);

  useEffect(() => {
    const pageNumber = DefaultPagination.pageNumber;
    const pageSize = DefaultPagination.maxPageSize;
    dispatch(UserActions.searchUsers({ orgId, pageNumber, pageSize }));
  }, [dispatch]);

  //
  return (
    <div className="page-content">
      {/* Users List Loading Section */}
      {searchUsersLoading && <SkeletonTable columnCount={5} rowCount={12} />}

      {/* User List Table with header to add new user */}
      {!searchUsersLoading && (
        <>
          <Header setOpenModal={setOpenModal} />
          <UsersTable setUserData={setUserData} setOpenModal={setOpenModal} />
        </>
      )}

      {/* User Create / Edit Modal */}
      <UserCreateEditModal
        orgId={orgId}
        userData={userData}
        setUserData={setUserData}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />

      <UserPasswordChangeModal userId={userId} openModal={openModal} setOpenModal={setOpenModal} />

      <UserRoleUpdateModal userId={userId} openModal={openModal} setOpenModal={setOpenModal} userData={userData} />
    </div>
  );
}
