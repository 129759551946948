import moment from "moment";

// Utils
import TableUtils from "./tableUtils";

function constructFileListTableData(fileList) {
  // Construct rows
  const fileRowMap = {};

  for (let index = 0; index < fileList.length; index++) {
    const { id = "", updatedOn = "" } = fileList[index];

    const fileRow = {
      key: id,

      ...fileList[index],

      createdOn: moment(updatedOn).format("MMM D, YYYY"),
      updatedOn: moment(updatedOn).fromNow(),
    };

    fileRowMap[id] = fileRow;
  }

  return fileRowMap;
}

function getFileTableHeader(currentPage = "", pageSize = "") {
  return [
    {
      title: "S.No",
      dataIndex: "sno",
      render: (text, record, index) => TableUtils.tableSerialNumber(currentPage, pageSize, index),
    },
    {
      title: "Name",
      dataIndex: "fileName",
      render: (text, record) => {
        return (
          <div style={{ padding: 0 }}>
            {text}
            <span className="sub-text">{record.description}</span>
          </div>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "fileType",
    },
    {
      title: "Kind",
      dataIndex: "fileKind",
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Created",
      dataIndex: "createdOn",
    },
    {
      title: "Modified",
      dataIndex: "updatedOn",
    },
  ];
}

const ProjectFileTableUtils = { constructFileListTableData, getFileTableHeader };

export default ProjectFileTableUtils;
