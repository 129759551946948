// React Related Imports
import { all, put, takeLatest } from "redux-saga/effects";

// Services
import UserService from "../../services/user/userService";

// Constants
import { DefaultPagination } from "../../constants/WebConstants";

// Actions
import { UserActions } from "../../redux-slice/user/userSlice";

// Create User
function* createUser(action) {
  try {
    const { userObj } = action.payload;

    const userId = yield UserService.createUser(userObj);

    yield put({
      type: UserActions.searchUsers.type,
      payload: {
        orgId: userObj.orgId,
        pageNumber: DefaultPagination.pageNumber,
        pageSize: DefaultPagination.pageSize,
      },
    });

    // dispatching action
    yield put(UserActions.createUserSuccess({ userId }));
  } catch (error) {
    yield put(UserActions.createUserFailure({ error: "Error" }));
    console.log("Error : ", error);
  }
}

// Update User Details
function* updateUser(action) {
  try {
    const { userId = "", userObj = {} } = action.payload;

    yield UserService.updateUser(userId, userObj);

    yield put({
      type: UserActions.searchUsers.type,
      payload: {
        orgId: userObj.orgId,
        pageNumber: DefaultPagination.pageNumber,
        pageSize: DefaultPagination.pageSize,
      },
    });

    yield put(UserActions.updateUserSuccess());
  } catch (error) {
    yield put(UserActions.updateUserFailure({ error: "Error" }));
    console.log("Error : ", error);
  }
}

// Update User Enable
function* enableUser(action) {
  const { userId = "" } = action.payload;
  try {
    yield UserService.enableUser(userId);

    yield put(UserActions.enableUserSuccess({ userId }));
  } catch (error) {
    yield put(UserActions.enableUserFailure({ userId, error: "Error" }));
    console.log("Error : ", error);
  }
}

// Update User Disable
function* disableUser(action) {
  const { userId = "" } = action.payload;
  try {
    yield UserService.disableUser(userId);

    yield put(UserActions.disableUserSuccess({ userId }));
  } catch (error) {
    yield put(UserActions.disableUserFailure({ userId, error: "Error" }));
    console.log("Error : ", error);
  }
}

// Get User Details
function* getUser(action) {
  try {
    const { userId = "" } = action.payload;

    const userData = yield UserService.getUser(userId);

    yield put(UserActions.getUserSuccess({ userData }));
  } catch (error) {
    yield put(UserActions.getUserFailure({ error: "Error" }));
    console.log("Error : ", error);
  }
}

// Change User Password
function* updateUserPassword(action) {
  const { userId = "", oldPassword = "", newPassword = "" } = action.payload;
  try {
    yield UserService.updateUserPassword(userId, oldPassword, newPassword);

    yield put(UserActions.updateUserPasswordSuccess());
  } catch (error) {
    yield put(UserActions.updateUserPasswordFailure({ error }));
    console.log("Error : ", error);
  }
}

// List Users
function* searchUsers(action) {
  try {
    const { orgId, pageNumber = DefaultPagination.pageNumber, pageSize = DefaultPagination.pageSize } = action.payload;

    const userListObj = yield UserService.searchUsers(orgId, pageNumber, pageSize);
    yield put(UserActions.searchUserSuccess({ userListObj }));
  } catch (error) {
    yield put(UserActions.searchUserFailure({ error: "Error" }));
    console.log("Error : ", error);
  }
}

function* updateUserRole(action) {
  const { userId = "", role = "" } = action.payload;
  try {
    yield UserService.updateUserRole(userId, role);

    yield put(UserActions.updateUserRoleSuccess({ userId, role }));
  } catch (error) {
    yield put(UserActions.updateUserRoleFailure({ error: "Error" }));
    console.log("Error : ", error);
  }
}

export default function* root() {
  yield all([
    takeLatest(UserActions.createUser.type, createUser),

    //
    takeLatest(UserActions.updateUser.type, updateUser),
    takeLatest(UserActions.enableUser.type, enableUser),
    takeLatest(UserActions.disableUser.type, disableUser),
    takeLatest(UserActions.updateUserRole.type, updateUserRole),
    takeLatest(UserActions.updateUserPassword.type, updateUserPassword),

    //
    takeLatest(UserActions.getUser.type, getUser),
    takeLatest(UserActions.searchUsers.type, searchUsers),
  ]);
}
