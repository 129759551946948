import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Actions
import { ProjectActions } from "../../redux-slice/project/projectSlice";

// Constants and Utils
import { LocalStorageKeys } from "../../constants/GeneralConstants";
import { getItem } from "../../app/LocalStorage";

// Components
import Modal from "../../components/antd/modal/Modal";
import Input from "../../components/antd/input/Input";
import Button from "../../components/button/Button";

// Sections
import ProjectsTable from "../project/project-list/components/ProjectsTable";

// Page Constants
const FORM_INPUTS = {
  name: "name",
  description: "description",
};

const DEFAULT_FORM_VALUES = { name: "", description: "" };

// Page Components
function ProjectCreateCard({ setIsModalOpen = () => {} }) {
  function openModal() {
    setIsModalOpen(true);
  }

  return (
    <div className="card cursor-pointer" onClick={openModal}>
      <div className="card-body">
        <h5 className="card-title">New Project</h5>
        <p className="text-muted">Create and Model new System</p>
      </div>
    </div>
  );
}

function ProjectImportCard({ className = "" }) {
  return (
    <div className={`card point-cursor ${className}`}>
      <div className="card-body">
        <h5 className="card-title">Import Model</h5>
        <p className="text-muted">Importing an existing model file.</p>
      </div>
    </div>
  );
}

// Create Project Modal Body Section
function ProjectCreateModal({ isModalOpen = false, setIsModalOpen = () => {} }) {
  // Dispatch
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State
  const [formData, setformData] = useState(DEFAULT_FORM_VALUES);
  const { name = "", description = "" } = formData;

  // Input onChange
  function onInputChange(e, inputName) {
    const inpVal = e.target.value || "";

    setformData((prev) => ({ ...prev, [inputName]: inpVal }));
  }

  function closeModal() {
    setIsModalOpen(false);
    setformData(DEFAULT_FORM_VALUES);
  }

  // Form onSubmit
  function onFormSubmit(e) {
    e.preventDefault(); // Prevent Default

    // Get Auth User details and add it to form data
    const { userId } = JSON.parse(getItem(LocalStorageKeys.AUTH_USER));
    const projectData = {
      userId,
      ...formData,
    };

    dispatch(ProjectActions.createProject({ projectData, navigate, setIsModalOpen }));
  }

  return (
    <Modal
      title="Create a Project"
      isModalOpen={isModalOpen}
      onCancel={closeModal}
      isOkAndCancelBtnPresent={false}
      size="md"
    >
      {/* Project Create Modal */}
      <form className="form-horizontal pb-5" onSubmit={onFormSubmit}>
        <div className="form-group mb-2">
          <label className="form-label">
            Name <span className="text-danger">*</span>
          </label>
          <Input
            value={name}
            className="form-control shadow-none"
            onChange={(e) => onInputChange(e, FORM_INPUTS.name)}
            autoComplete="off"
          />
        </div>
        <div className="form-group mb-2">
          <label className="form-label">Description</label>
          <Input
            value={description}
            type="TextArea"
            className="form-control shadow-none"
            onChange={(e) => onInputChange(e, FORM_INPUTS.description)}
          />
        </div>

        <div className="btn-cont mt-3">
          <Button className="btn btn-secondary float-end" type="submit">
            Create
          </Button>
        </div>
      </form>
    </Modal>
  );
}

/**
 * Page
 */
export default function DashboardPage() {
  // Dispatch
  const dispatch = useDispatch();

  // State
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Selector State
  const projectListLoading = useSelector((state) => state.project.projectListLoading);

  //use Effect
  useEffect(() => {
    dispatch(ProjectActions.searchProjects({ pageSize: 5, pageNumber: 1 }));
  }, [dispatch]);

  return (
    <>
      {/* Page Content */}
      <div className="page-content">
        {/* DashboardCards Section */}
        <div className="row">
          <div className="col-md-3">
            {/* Project Create Card */}
            <ProjectCreateCard setIsModalOpen={setIsModalOpen} />
          </div>

          <div className="col-md-3">
            {/* Project Import Card */}
            <ProjectImportCard className="d-none" />
          </div>
        </div>

        {/** Recent Projects */}
        <div className="my-4 fw-bold fs-5">Recent Projects</div>
        <ProjectsTable dataLoading={projectListLoading} />
      </div>

      {/** Modals*/}
      <div>
        <ProjectCreateModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      </div>
    </>
  );
}
