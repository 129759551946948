// Utils
import UrlUtils from "../../utils/urlUtils";

// URLs
import { EquationUrls } from "../../constants/serviceUrls";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Create Equation
const createEquation = async function (graphId, equationObj) {
  // API URL
  const apiURL = UrlUtils.format(EquationUrls.createEquation, { graphId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(apiURL, {}, equationObj);
};

// Update Equation
const updateEquation = async function (graphId, equationId, equationObj) {
  // API URL
  const apiURL = UrlUtils.format(EquationUrls.updateEquation, { graphId, equationId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiURL, {}, equationObj);
};

// Delete Equation
const deleteEquation = async function (graphId, equationId) {
  // API URL
  const apiURL = UrlUtils.format(EquationUrls.deleteEquation, { graphId, equationId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.delete(apiURL);
};

// Export
const EquationService = {
  //
  createEquation,

  //
  updateEquation,

  //
  deleteEquation,
};

export default EquationService;
