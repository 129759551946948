/**
 * Constraints Card
 * @param {*} equations : Array
 */
export default function ConstraintsCard({ equations = [] }) {
  //
  return (
    <div className="card card-info">
      <div className="card-body">
        <h6 className="py-1 pb-2 mb-3 border-bottom fw-bold">Subject to:</h6>
        <ul className="list-unstyled list-graph-elems">
          {equations.map((eq) => {
            const { id, name, text } = eq;
            return (
              <li key={`key-eqn-${id}`}>
                {text}
                <small className="text-secondary ms-2 fst-italic">{name}</small>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
