import { useSelector } from "react-redux";

// Utils
import DecimalUtils from "../../../../utils/DecimalUtils";

// Components
import Button from "../../../../components/button/Button";

/**
 * Objective Function Solution Card
 * @param {*} optRunId : Id
 * @param {*} setOpenModal : Function
 */
export default function ObjectiveFunctionSolutionCard({ optRunId = "", setOpenModal = () => {} }) {
  // Opt Run Selector State
  const optRun = useSelector((state) => state.optRun.optRun);

  // Objective Cost Information
  const { objectivesCost = {}, statusMsg = "" } = optRun || {};
  const { obj00 = "" } = objectivesCost;

  // If opt run id is not present
  if (!optRunId) {
    return null;
  }

  return (
    <div className="card card-info mt-4 bg-warning-subtle">
      <div className="card-body">
        <div className="row">
          <div className="col-5">
            <span className="fw-bold me-2">Status Msg:</span> {statusMsg}
          </div>
          <div>
            <span className="fw-bold me-2">Objective Cost:</span> {DecimalUtils.fixDecimal(obj00)}
          </div>
          <div className="mt-2">
            <Button className="btn btn-sm btn-outline-secondary" onClick={() => setOpenModal(true)}>
              View Log
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
