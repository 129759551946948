//
import { createSlice } from "@reduxjs/toolkit";

// Utils
import ProjecFileTransformer from "./projectFileTransformer";
import ProjectFileTableUtils from "../../utils/projectFileTableUtils";

// Intital state
const initialState = {
  // Create File
  createFileLoading: false,
  CreateFileError: "",

  // Update File Information
  updateFileInfoLoading: false,
  updateFileInfoError: "",

  // Download File
  downloadFileLoadingMap: {},
  downloadFileError: "",

  // Get File Information
  fileInfo: {},
  fileInfoLoading: false,
  fileInfoError: "",

  // Search
  filesMap: {},
  fileListPagination: {},
  fileListLoading: false,
  fileListError: "",

  // Api Success
  isApiSuccess: false,
};

// File Slice
const fileSlice = createSlice({
  // Name
  name: "ProjectFile",

  // Initial State
  initialState,

  // Reducers
  reducers: {
    // Create File
    createFile: (state) => {
      state.isApiSuccess = false;
      state.createFileLoading = true;
    },

    createFileSuccess: (state) => {
      state.createFileLoading = false;
      state.isApiSuccess = true;
      state.CreateFileError = "";
    },

    createFileFailure: (state, action) => {
      state.createFileLoading = false;
      state.isApiSuccess = false;
      state.CreateFileError = action.payload.error;
    },

    // Update File Information
    updateFileInfo: (state) => {
      state.isApiSuccess = false;
      state.updateFileInfoLoading = true;
    },

    updateFileInfoSuccess: (state) => {
      state.updateFileInfoLoading = false;
      state.isApiSuccess = true;
      state.updateFileInfoError = "";
    },

    updateFileInfoFailure: (state, action) => {
      state.isApiSuccess = false;
      state.updateFileInfoLoading = false;
      state.updateFileInfoError = action.payload.error;
    },

    // Download File
    downloadFile: (state, action) => {
      const { fileId = "" } = action.payload;

      state.downloadFileLoadingMap[fileId] = true;
    },

    downloadFileSuccess: (state, action) => {
      const { fileId = "" } = action.payload;

      delete state.downloadFileLoadingMap[fileId];
      state.downloadFileError = "";
    },

    downloadFileFailure: (state, action) => {
      const { fileId = "" } = action.payload;

      delete state.downloadFileLoadingMap[fileId];
      state.downloadFileError = "";
    },

    // Get File Information
    getFileInfo: (state) => {
      state.fileInfoLoading = true;
    },

    getFileInfoSuccess: (state, action) => {
      state.fileInfo = action.payload.fileInfo;
      state.fileInfoLoading = false;
      state.fileInfoError = "";
    },

    getFileInfoFailure: (state, action) => {
      state.fileInfoLoading = false;
      state.fileInfoError = action.payload.error;
    },

    // Search File
    searchFiles: (state) => {
      state.fileListLoading = true;
      state.fileListError = "";
    },

    searchFilesSuccess: (state, action) => {
      const { items = [], pagination = {} } = action.payload.fileList;

      const filesList = ProjecFileTransformer.transformList(items);

      state.filesMap = ProjectFileTableUtils.constructFileListTableData(filesList);
      state.fileListPagination = pagination;
      state.fileListLoading = false;
    },

    searchFilesFailure: (state, action) => {
      state.fileListLoading = false;
      state.fileListError = action.payload.error;
    },
  },
});

// Reducer
export const ProjectFileReducer = fileSlice.reducer;

// Actions
export const ProjectFileActions = fileSlice.actions;
