import Plotly from "plotly.js-dist-min";

async function createImage(graphDivId = "") {
  if (!graphDivId) {
    return null;
  }
  const graphDiv = document.getElementById(graphDivId) ?? "";

  if (!graphDiv) {
    return null;
  }

  const url = await Plotly.toImage(graphDiv, { format: "png", width: 800, height: 600 });

  return url;
}

const ChartHelper = {
  createImage,
};

export default ChartHelper;
