//Constants & Utils
import { LocalStorageKeys } from "../../constants/GeneralConstants";
import { getItem } from "../../app/LocalStorage";

/**
 * Pre defined APP Themes.
 */
const Themes = {
  DEFAULT: {
    id: "DEFAULT",
    name: "default",
    custom: false,
  },
};

/**
 * App theme selector
 */
export default function getTheme() {
  // ThemeId from Local Storage
  const themeId = getItem(LocalStorageKeys.ORG_TYPE);

  // Get the Theme from existing defs
  const theme = Themes[themeId];
  if (theme) {
    return theme;
  }

  // Return platform
  return Themes.DEFAULT;
}
