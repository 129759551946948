import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "antd";
import { KeyRound, Pencil, UserPen } from "lucide-react";

// Constants
import { USER_LIST_TABLE_HEADER, USER_MODAL_TYPES } from "../../../../constants/userConstants";
import { LocalStorageKeys } from "../../../../constants/GeneralConstants";

// Utils
import { getItem } from "../../../../app/LocalStorage";

// Actions
import { UserActions } from "../../../../redux-slice/user/userSlice";

// Components
import DataTable from "../../../../components/antd/table/DataTable";

function UserListTableActions({ record = {}, userId = "", setUserData = () => {}, setOpenModal = () => {} }) {
  // Dispatch
  const dispatch = useDispatch();

  const { key = "", isActive = "" } = record;

  const userStatusLoading = useSelector((state) => state.user.userStatusLoadingmap[key]) || false;

  const actionIconClassName = isActive ? "cursor-pointer" : "pe-none opacity-25";

  // Function to handle edit
  function handleEditUserBtn() {
    setOpenModal({ state: true, type: USER_MODAL_TYPES.USER_DATA });
    setUserData(record);
  }

  function handleChangePasswordBtn() {
    setOpenModal({ state: true, type: USER_MODAL_TYPES.CHANGE_PASSWORD });
    setUserData(record);
  }

  function handleUpdateUserBtn() {
    setOpenModal({ state: true, type: USER_MODAL_TYPES.USER_ROLE });
    setUserData(record);
  }

  // Function to handle user status enable/disable
  function handleEditUserActive(enable, key) {
    const dispatchFunction = enable ? UserActions.enableUser : UserActions.disableUser;
    dispatch(dispatchFunction({ userId: key }));
  }

  return (
    <div className="d-flex align-items-center gap-3">
      <Pencil className={actionIconClassName} size={16} onClick={handleEditUserBtn}>
        <title>Edit</title>
      </Pencil>

      <KeyRound size={16} onClick={handleChangePasswordBtn} className={actionIconClassName} aria-disabled={true}>
        <title>Change Password</title>
      </KeyRound>

      {key !== userId && (
        <>
          <UserPen size={16} onClick={handleUpdateUserBtn} className={actionIconClassName}>
            <title>Update Role</title>
          </UserPen>
          <Switch
            defaultChecked={isActive}
            onChange={(e) => handleEditUserActive(e, key)}
            loading={userStatusLoading}
            size="small"
          ></Switch>
        </>
      )}
    </div>
  );
}

/**
 * Users Table Component
 * @param {*} setOpenModal: Function
 * @param {*} setUserData: Function
 * @returns
 */
export default function UsersTable({ setOpenModal = () => {}, setUserData = () => {} }) {
  // States
  const [columns, setColumns] = useState([]);

  // Selectors
  const userMap = useSelector((state) => state.user.userMap);

  // Getting userId from LocalStorage
  const { userId } = JSON.parse(getItem(LocalStorageKeys.AUTH_USER));

  // Constructing columns for users table
  const actionColumn = {
    title: "Action",
    Key: "action",
    render: (record) => {
      return (
        <UserListTableActions record={record} userId={userId} setUserData={setUserData} setOpenModal={setOpenModal} />
      );
    },
  };

  useEffect(() => {
    setColumns([...USER_LIST_TABLE_HEADER, actionColumn]);
  }, []);

  //
  return (
    <>
      <DataTable rows={Object.values(userMap)} columns={columns} applyTableBorder={true} />
    </>
  );
}
