import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";

// Components
import PageHeader from "../../../app/layout/PageHeader";
import OptSetupTable from "./components/OptSetupTable";

// Utils
import UrlUtils from "../../../utils/urlUtils";

// Actions
import { OptSetupActions } from "../../../redux-slice/project/optSetupSlice";
import { ProjectActions } from "../../../redux-slice/project/projectSlice";

// Constants
import { QueryParamsKeys } from "../../../constants/WebConstants";
import PageURL from "../../../constants/pageUrls";

//
// Constants

//
// Page Components
// ----------------------------------------------------------------------------

//
function PageHeaderSection({ projectInfo = {} }) {
  //
  const { id } = projectInfo;

  // URLs
  const projectOptSetupPageURL = UrlUtils.format(PageURL.OptSetupPageURL, { projectId: id });

  // Content
  const pageTitle = "Optimization Objectives";
  const pageActions = (
    <div className="btn-cont">
      <Link className="btn btn-outline-primary" to={projectOptSetupPageURL}>
        Setup new Objective
      </Link>
    </div>
  );

  return <PageHeader title={pageTitle} actions={pageActions} />;
}

//

/**
 * Page
 */
export default function OptSetupListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Page Path Params
  const params = useParams();
  const { projectId } = params;

  // Page Query Params
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber) ?? "";
  const pageSize = searchParams.get(QueryParamsKeys.pageSize) ?? "";

  // Dispatch API calls
  useEffect(() => {
    dispatch(ProjectActions.getProject({ projectId }));
  }, [projectId, dispatch]);

  useEffect(() => {
    dispatch(OptSetupActions.searchOptSetups({ projectId, pageNumber, pageSize }));
  }, [projectId, pageNumber, pageSize, dispatch]);

  //
  // Selector State
  const projectInfo = useSelector((state) => state.project.projectInfo);

  //
  // Data

  //
  return (
    <div className="main-cont position-right-0">
      <div className="content-wrapper">
        {/** Header */}
        <PageHeaderSection projectInfo={projectInfo} />

        {/* Page Content */}
        <div className="page-content">
          <div className="py-3 pb-5">
            <OptSetupTable projectId={projectId} />
          </div>
        </div>
      </div>
    </div>
  );
}
