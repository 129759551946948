import React from "react";
import { Pagination as AntdPagination } from "antd";
import { ChevronLeft, ChevronRight } from "lucide-react";

// Components
import Button from "../../button/Button";

// CSS
import "./Pagination.scss";

export default function Pagination({ currentPage, totalCount, pageSize, onPageChange }) {
  // For calculating total number of pages
  const totalPages = Math.ceil(totalCount / pageSize);

  if (totalPages <= 1) {
    return null;
  }

  const disablePreviousBtn = currentPage === 1;
  const disableNextBtn = currentPage === totalPages;

  return (
    <>
      <Button
        className="btn-secondary me-2 btn-sm"
        disabled={disablePreviousBtn}
        onClick={() => onPageChange(currentPage - 1)}
        icon={<ChevronLeft size={18} className="icon-previous" />}
      >
        Previous
      </Button>

      <AntdPagination
        current={currentPage}
        total={totalCount}
        pageSize={pageSize}
        onChange={onPageChange}
        showSizeChanger={false}
        itemRender={(page, type, originalElement) => {
          if (type === "prev" || type === "next") {
            return null;
          }
          return originalElement;
        }}
      />

      <Button
        className="btn-secondary ms-2 btn-sm"
        disabled={disableNextBtn}
        onClick={() => onPageChange(currentPage + 1)}
        icon={<ChevronRight size={18} className="icon-next" />}
      >
        Next
      </Button>
    </>
  );
}
