import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Components
import DataTable from "../../../../components/antd/table/DataTable";
import SkeletonTable from "../../../../components/antd/table/SkeletonTable";

// Constants
import { QueryParamsKeys } from "../../../../constants/WebConstants";

// Utils
import UrlUtils from "../../../../utils/urlUtils";
import OptSetupTableUtils from "../../../../utils/optSetupTableUtils";

// URLs
import PageURL from "../../../../constants/pageUrls";

// Page Components
function OptSetupTableActions({ record = {} }) {
  const { id, projectId } = record;

  const pathParamsObj = { projectId, optSetupId: id };
  const queryParamsObj = { [QueryParamsKeys.optSetupId]: id };

  const OptSetupPageURL = UrlUtils.format(PageURL.OptSetupPageURL, pathParamsObj, queryParamsObj);

  const OptRunPageURL = UrlUtils.format(PageURL.OptRunPageURL, pathParamsObj, {});
  const OptRunListPageURL = UrlUtils.format(PageURL.OptRunListPageURL, pathParamsObj, {});
  const OptRunProblemPageURL = UrlUtils.format(PageURL.OptRunProblemPageURL, pathParamsObj, {});

  //
  return (
    <ul className="list-inline mb-0">
      <li className="list-inline-item">
        <Link className="btn btn-sm btn-outline-primary" to={OptSetupPageURL}>
          View Setup
        </Link>
      </li>
      <li className="list-inline-item">
        <Link className="btn btn-sm btn-outline-secondary" to={OptRunListPageURL}>
          View Solutions
        </Link>
      </li>
      <li className="list-inline-item">
        <Link className="btn btn-sm btn-outline-primary" to={OptRunPageURL}>
          Run
        </Link>
      </li>
      <li className="list-inline-item d-none">
        <Link className="btn btn-sm btn-outline-secondary" to={OptRunProblemPageURL}>
          View AMPL Problem
        </Link>
      </li>
    </ul>
  );
}

export default function OptSetupTable({ currentPage = "", pageSize = "" }) {
  const optSetupListLoading = useSelector((state) => state.optSetup.optSetupListLoading);
  const optSetupsMap = useSelector((state) => state.optSetup.optSetupsMap);

  if (optSetupListLoading) {
    return <SkeletonTable columnCount={4} rowCount={5} />;
  }

  // Constructing columns for users table
  const actionColumn = {
    title: "Action",
    Key: "action",
    render: (record) => {
      return <OptSetupTableActions record={record} />;
    },
  };

  const optSetupTableHeaders = OptSetupTableUtils.getOptSetupTableHeader(currentPage, pageSize);
  const columns = [...optSetupTableHeaders, actionColumn];

  return (
    //
    <DataTable rows={Object.values(optSetupsMap)} columns={columns} applyTableBorder={true} />
  );
}
