import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Form, Modal } from "antd";

// Actions
import { UserActions } from "../../../../redux-slice/user/userSlice";

// Constants
import { USER_FORM_INPUT_DATA, USER_MODAL_TYPES } from "../../../../constants/userConstants";

// Components
import UserModalFooter from "./UserModalFooter";

const formInitialValues = {
  firstName: "",
  lastName: "",
  emailId: "",
  phoneNum: "",
  designation: "",
  password: "",
};

// Page Components

function FormItem({ data = [], userId = "" }) {
  return (
    <div className="d-flex justify-content-between gap-4 mt-5">
      {data.map((item, i) => {
        const { label = "", name = "", rules = [], help = "", input = <></> } = item;

        // Check it is edit and removing the password field
        const isEditForm = userId !== "" && name === "password";

        return (
          !isEditForm && (
            <Form.Item
              key={i}
              className="flex-grow-1 fw-semibold"
              layout="vertical"
              label={label}
              name={name}
              rules={rules}
              help={help}
            >
              {input}
            </Form.Item>
          )
        );
      })}
    </div>
  );
}

/**
 *
 * @param {*} orgId: Number/String
 * @param {*} openModal: Object
 * @param {*} setOpenModal: Function
 * @param {*} userData: Object
 * @param {*} setUserData: Function
 * @returns
 */
export default function UserCreateEditModal({
  orgId = "",
  openModal = { state: false, type: "" },
  setOpenModal = () => {},
  userData = {},
  setUserData = () => {},
}) {
  // Dispatch
  const dispatch = useDispatch();

  // Form
  const [form] = Form.useForm();

  // States
  const [validationError, setValidationError] = useState("");
  const [showError, setShowError] = useState(false);

  const { key: userId = "" } = userData;
  const { state = false, type = "" } = openModal;
  const isModalOpen = state && type === USER_MODAL_TYPES.USER_DATA;
  const modalTitle = userId ? "Edit User Details" : "Create New User";
  const formInput = USER_FORM_INPUT_DATA;

  // Selectors
  const upsertUserLoading = useSelector((state) => state.user.upsertUserLoading);

  const upsertUserSuccess = useSelector((state) => state.user.upsertUserSuccess);

  const errorFromApi = useSelector((state) => state.user.upsertUserError);

  // Function to reset the form
  function handleReset() {
    setValidationError("");
    setShowError(false);
    if (userId) {
      form.setFieldsValue(userData);
      return;
    }
    form.resetFields();
  }

  // Function to handle the modal cancel
  function handleCancel() {
    setOpenModal({ state: "false", type: "" });
    setUserData({});
    setValidationError("");
    setShowError(false);
  }

  // Function to handle create or update
  function handleUpsert(values = {}) {
    setValidationError("");
    setShowError(false);

    if (userId) {
      dispatch(UserActions.updateUser({ userId, userObj: values }));
      return;
    }

    dispatch(UserActions.createUser({ userObj: values }));
  }

  // Function to handle the form submission
  function handleSubmit() {
    form
      .validateFields()
      .then((values) => {
        handleUpsert({ ...values, orgId });
      })
      .catch((info) => {
        setValidationError(info.errorFields[0].errors[0]);
        setShowError(true);
      });
  }

  // To update the form data for edit
  useEffect(() => {
    form.setFieldsValue(userData);
  }, [userId]);

  // action when the upsert is successful
  useEffect(() => {
    if (upsertUserSuccess) {
      setOpenModal({ state: "false", type: "" });
      setUserData({});
    }
  }, [upsertUserSuccess]);

  // to display the error when api fails
  useEffect(() => {
    if (Object.keys(errorFromApi).length !== 0) {
      setValidationError("Error while creating / updating user details");
      setShowError(true);
    }
  }, [JSON.stringify(errorFromApi)]);

  return (
    <Modal
      forceRender
      open={isModalOpen}
      title={modalTitle}
      onCancel={handleCancel}
      afterClose={handleReset}
      width={700}
      footer={() => (
        <UserModalFooter
          handleReset={handleReset}
          handleSubmit={handleSubmit}
          upsertBtnName={userId ? "Update User" : "Create User"}
          btnLoading={upsertUserLoading}
        />
      )}
    >
      {/* Error Alert */}
      {showError && <Alert message={validationError} type="error" />}

      <Form className="pb-4" form={form} initialValues={formInitialValues}>
        {formInput.map((data, index) => (
          <FormItem key={index} data={data} userId={userId} />
        ))}
      </Form>
    </Modal>
  );
}
