//
import React from "react";
import { useSelector } from "react-redux";

// Components
import Modal from "../../../../components/antd/modal/Modal";

// Component
// ----------------------------------------------------------------------------

function LogMessages({ log }) {
  return (
    <div className="log-view-modal">
      {log.map(({ message }) => {
        return <p className="mb-3">{message}</p>;
      })}
    </div>
  );
}

/**
 * Log View Modal
 * @param {*} openModal : Boolean
 * @param {*} setOpenModal : Function
 */
export default function LogViewModal({ openModal = false, setOpenModal = () => {} }) {
  // Opt Run Selector State
  const optRun = useSelector((state) => state.optRun.optRun);
  const { log = [] } = optRun;

  function handleCancel() {
    setOpenModal(false);
  }

  return (
    <Modal
      title={
        <div className="mb-4">
          <span>Log View</span>
        </div>
      }
      isModalOpen={openModal}
      onCancel={handleCancel}
    >
      <LogMessages log={log} />
    </Modal>
  );
}
