// Components
import Icon from "../icon/Icon";

// CSS
import "./Loader.scss";

/**
 * Loader
 * @param {*} size
 */
export default function Loader({ size = 16 }) {
  return <Icon className="loader" iconName="loaderCircle" size={size} />;
}
