import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Form, Modal, Select } from "antd";

// Actions
import { UserActions } from "../../../../redux-slice/user/userSlice";

// Constants
import { USER_MODAL_TYPES, USER_ROLES } from "../../../../constants/userConstants";

// Components
import UserModalFooter from "./UserModalFooter";

/**
 * User Role Update Modal
 * @param {*} userId: Number
 * @param {*} openModal: Boolean
 * @param {*} setOpenModal: Function
 * @param {*} setUserData: Function
 * @param {*} userData: Object
 * @returns
 */
export default function UserRoleUpdateModal({
  userId = "",
  openModal = { state: false, type: "" },
  setOpenModal = () => {},
  setUserData = () => {},
  userData = {},
}) {
  // Dispatch
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  // States
  const [validationError, setValidationError] = useState("");
  const [showError, setShowError] = useState(false);

  // Selectors
  const updateUserRoleLoading = useSelector((state) => state.user.updateUserRoleLoading);
  const updateUserRoleSuccess = useSelector((state) => state.user.updateUserRoleSuccess);
  const errorFromApi = useSelector((state) => state.user.updateUserRoleError);

  const { role = "" } = userData;

  const { state = false, type = "" } = openModal;
  const isModalOpen = state && type === USER_MODAL_TYPES.USER_ROLE;

  function handleReset() {
    form.resetFields();
    setValidationError("");
    setShowError(false);
  }

  function handleCancel() {
    setOpenModal({ state: false, type: "" });
    setUserData({});
    form.resetFields();
  }

  function handleUpdateUserRole(value) {
    const { role = "" } = value;

    dispatch(UserActions.updateUserRole({ userId, role }));
    setValidationError("");
    setShowError(false);
  }

  function handleSubmit() {
    form
      .validateFields()
      .then((values) => handleUpdateUserRole(values))
      .catch((info) => {
        setValidationError(info.errorFields[0].errors[0]);
        setShowError(true);
      });
  }

  useEffect(() => {
    if (updateUserRoleSuccess) {
      setOpenModal({ state: false, type: "" });
      form.resetFields();
      setValidationError("");
      setShowError(false);
      setUserData({});
    }
  }, [updateUserRoleSuccess]);

  useEffect(() => {
    if (Object.keys(errorFromApi).length !== 0) {
      setValidationError("Error while updating the user role");
      setShowError(true);
    }
  }, [JSON.stringify(errorFromApi)]);

  return (
    <Modal
      open={isModalOpen}
      title="Update User Role"
      onCancel={handleCancel}
      afterClose={handleReset}
      width={500}
      footer={() => (
        <UserModalFooter handleReset={handleReset} handleSubmit={handleSubmit} btnLoading={updateUserRoleLoading} />
      )}
    >
      {/* Error Alert */}
      {showError && <Alert message={validationError} type="error" />}

      <Form
        labelCol={{
          span: 7,
        }}
        wrapperCol={{
          span: 16,
        }}
        layout="horizontal"
        className="my-4"
        form={form}
      >
        <Form.Item label="Current Role : " className="fw-semibold align-items-center">
          <strong className="p-0 m-0 mx-3">{role}</strong>
        </Form.Item>
        <Form.Item
          label="Select New Role : "
          className="fw-semibold"
          rules={[{ required: true, message: "Select Role" }]}
          help=""
          name="role"
        >
          <Select className="ms-3">
            <Select.Option key={USER_ROLES.ADMIN} value={USER_ROLES.ADMIN}>
              {USER_ROLES.ADMIN}
            </Select.Option>
            <Select.Option key={USER_ROLES.EDITOR} value={USER_ROLES.EDITOR}>
              {USER_ROLES.EDITOR}
            </Select.Option>
            <Select.Option key={USER_ROLES.VIEWER} value={USER_ROLES.VIEWER}>
              {USER_ROLES.VIEWER}
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}
