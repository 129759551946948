//

// Utils
import HttpClientFactory from "../../app/http/HttpClientFactory";
import UrlUtils from "../../utils/urlUtils";

// URLs
import { OptSetupUrls } from "../../constants/serviceUrls";
import { DefaultPagination } from "../../constants/WebConstants";

//
// Service Methods
// ----------------------------------------------------------------------------

// Create Opt Setup
const createOptSetup = async function (projectId, optSetupObj) {
  // API URL
  const apiURL = UrlUtils.format(OptSetupUrls.createOptSetup, { projectId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(apiURL, {}, optSetupObj);
};

// Update Opt Setup
const updateOptSetup = async function (projectId, optSetupId, optSetupObj) {
  // API URL
  const apiURL = UrlUtils.format(OptSetupUrls.updateOptSetup, { projectId, optSetupId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(apiURL, {}, optSetupObj);
};

// Get Opt Setup
const getOptSetup = async function (projectId, optSetupId) {
  // API URL
  const apiURL = UrlUtils.format(OptSetupUrls.getOptSetup, { projectId, optSetupId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(apiURL);
};

// Get Opt Run History
const searchOptSetups = async function (projectId, searchParams, pageNumber, pageSize) {
  const params = {
    ...searchParams,
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  // API URL
  const apiURL = UrlUtils.format(OptSetupUrls.searchOptSetups, { projectId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(apiURL, params);
};

//
// Export
// ----------------------------------------------------------------------------

const OptSetupService = {
  //
  createOptSetup,

  //
  updateOptSetup,

  //
  getOptSetup,
  searchOptSetups,
};

export default OptSetupService;
