import { toast } from "react-toastify";

// React Related Imports
import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { setErrorInfo } from "../../app/error/ErrorActions";
import { AuthActions } from "../../redux-slice/auth/authSlice";

// Constants
import { LocalStorageKeys } from "../../constants/GeneralConstants";

// URLs
import PageURL from "../../constants/pageUrls";

// Api
import AuthService from "../../services/auth/authService";

// Utilities
import { setItem, setAuthToken } from "../../app/LocalStorage";

// Http Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Login
function* login(action) {
  // Action Payload
  const { username, password, navigate } = action.payload;

  try {
    // Call API
    const authInfo = yield AuthService.login(username, password);
    const { org = {}, privileges = [], token } = authInfo;

    // Store User AuthInfo in LocalStorage
    setAuthToken(token);

    setItem(LocalStorageKeys.AUTH_USER, JSON.stringify(authInfo));
    setItem(LocalStorageKeys.AUTH_USER_PRIVILEGES, privileges);
    setItem(LocalStorageKeys.AUTH_ORG, JSON.stringify(org));
    setItem(LocalStorageKeys.AUTH_ORG_TYPE, org.type);

    // Reload HttpClientFactory
    HttpClientFactory.reload();

    // Dispatching action
    yield put(AuthActions.loginSuccess({ authInfo }));

    navigate(PageURL.DashboardPageURL);
  } catch (error) {
    yield put(AuthActions.loginFailure({ error: "Login Error" }));
  }
}

// Log Out
function* logOut(action) {
  try {
    const { navigate } = action.payload;

    // Clear LocalStorage
    yield localStorage.clear();

    // Reset HttpClientFactory
    yield HttpClientFactory.reset();

    // Redirect to login page
    navigate(PageURL.LoginPageURL);
  } catch (error) {
    // TODO :: Handle error
    console.error(error);
  }
}

// Reset Password
function* resetPassword(action) {
  try {
    const { email = "", newPassword = "" } = action.payload;

    yield AuthService.resetPassword(email, newPassword);

    // Dispatching action
    yield put(AuthActions.resetPasswordSuccess());

    // Toaster
    toast.success("Reset Password Success");
  } catch (error) {
    yield put(AuthActions.resetPasswordFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

/**
 * Auth Sagas
 */
export default function* root() {
  yield all([
    takeLatest(AuthActions.login.type, login),
    takeLatest(AuthActions.resetLogin.type, logOut),
    takeLatest(AuthActions.resetPassword.type, resetPassword),
  ]);
}
