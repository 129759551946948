//

//
// Helper Methods
// ----------------------------------------------------------------------------

const getVarSummary = (graphObj = {}) => {
  // Graph Object
  const { edges = [], parameters = [] } = graphObj;

  // Param Vars
  const graphVars = [];
  const nodeVars = [];

  for (let idx in parameters) {
    const { nodeUid, varType, name, symbol } = parameters[idx];
    const pVar = {
      varType,
      name,
      symbol,
    };

    // add to nodeVars if nodeUid is present
    if (!nodeUid) {
      graphVars.push(pVar);
    } else {
      nodeVars.push(pVar);
    }
  }

  // Edge Vars
  const edgeVars = edges.map((e) => {
    const eVar = {
      varType: e.varType,
      name: e.name,
      symbol: e.symbol,
    };

    return eVar;
  });

  // Result
  const varSummary = {
    graphVars,
    nodeVars,
    edgeVars,
  };

  return varSummary;
};

/**
 * {
 *   "id": "",
 *   "display": ""
 * }
 */
function getVarSuggestionsData(graphObj = {}) {
  // Vars
  const { graphVars, nodeVars, edgeVars } = getVarSummary(graphObj);
  const varArray = [...graphVars, ...nodeVars, ...edgeVars];

  // Suggestions
  const suggestions = varArray.map(({ symbol }) => ({ id: symbol, display: symbol }));
  return suggestions;
}

// Exports
// ----------------------------------------------------------------------------

const GraphHelper = {
  getVarSummary,
  getVarSuggestionsData,
};

export default GraphHelper;
