import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { setErrorInfo } from "../../app/error/ErrorActions";
import { ProjectFileActions } from "../../redux-slice/project/projectFileSlice";

// Utils
import FileUtils from "../../utils/fileUtils";

// Service
import ProjectFileService from "../../services/project/projectFileService";

// Create File
function* createFile(action) {
  const { projectId = "", fileData, pageNumber = "", pageSize = "" } = action.payload;

  try {
    yield ProjectFileService.createFile(projectId, fileData);

    yield put(ProjectFileActions.createFileSuccess());

    yield put(ProjectFileActions.searchFiles({ projectId, pageNumber, pageSize }));

    toast.success("File Created Successfully");
  } catch (error) {
    yield put(ProjectFileActions.createFileFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Update File Information
function* updateFileInfo(action) {
  const { projectId = "", fileId = "", fileData, pageNumber = "", pageSize = "" } = action.payload;
  try {
    yield ProjectFileService.updateFileInfo(projectId, fileId, fileData);

    // Dispatching Action
    yield put(ProjectFileActions.updateFileInfoSuccess());

    yield put(ProjectFileActions.searchFiles({ projectId, pageNumber, pageSize }));

    // Toaster
    toast.success("File Updated Sucessfully");
  } catch (error) {
    yield put(ProjectFileActions.updateFileInfoFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Download File
function* downloadFile(action) {
  const { fileId = "", fileName = "", fileURL = "" } = action.payload;

  try {
    const data = yield ProjectFileService.downloadFile(fileURL);

    // Save File
    FileUtils.saveFile(fileName, data);

    yield put(ProjectFileActions.downloadFileSuccess({ fileId }));
  } catch (error) {
    yield put(ProjectFileActions.downloadFileFailure({ fileId, error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Get File Information
function* getFileInfo(action) {
  const { projectId = "", fileId = "" } = action.payload;

  try {
    const fileInfo = yield ProjectFileService.getFileInfo(projectId, fileId);

    // Dispatching Action
    yield put(ProjectFileActions.getFileInfoSuccess({ fileInfo }));
  } catch (error) {
    yield put(ProjectFileActions.getFileInfoFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Search Files
function* searchFiles(action) {
  try {
    const { projectId = "", searchText = "", pageNumber = "", pageSize = "" } = action.payload;

    const fileList = yield ProjectFileService.searchFiles(projectId, pageNumber, pageSize, searchText);

    // Dispatching Action
    yield put(ProjectFileActions.searchFilesSuccess({ fileList }));
  } catch (error) {
    yield put(ProjectFileActions.searchFilesFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Export
export default function* root() {
  yield all([
    //
    takeLatest(ProjectFileActions.createFile.type, createFile),

    //
    takeLatest(ProjectFileActions.updateFileInfo.type, updateFileInfo),
    takeLatest(ProjectFileActions.downloadFile.type, downloadFile),

    //
    takeLatest(ProjectFileActions.getFileInfo.type, getFileInfo),
    takeLatest(ProjectFileActions.searchFiles.type, searchFiles),
  ]);
}
