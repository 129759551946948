// Utils
import UrlUtils from "../../utils/urlUtils";

// URLs
import { GraphUrls } from "../../constants/serviceUrls";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Refresh Generated Data
const refreshGraph = async function (graphId) {
  // API URL
  const apiURL = UrlUtils.format(GraphUrls.refreshGraphSetup, { graphId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(apiURL);
};

// Get Graph Data By Id
const getGraphDataById = async function (graphId) {
  // API URL
  const apiURL = UrlUtils.format(GraphUrls.getDataById, { graphId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(apiURL);
};

// Export
const GraphService = {
  //
  refreshGraph,

  //
  getGraphDataById,
};

export default GraphService;
