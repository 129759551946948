import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// Custom Hook
import { useUpdateNodeInternals } from "reactflow";

// Actions
import { NodeActions } from "../../redux-slice/graph/nodeSlice.js";

// Components
import InlineEdit from "../../components/inline-edit/InlineEdit.jsx";

// Config
import ReactFlowExt from "../ReactFlowExt.js";

// Constant and Utils
import HandlesCont from "../connection/HandlesCont.jsx";
import GraphUtil from "../GraphUtil";

//
// Constants
const PX_TOP_OFFSET = 35;
const PX_SPACING = 30;

//
// ControlVolumeNode
// ----------------------------------------------------------------------------

function ControlVolumeNode({ id, type, selected, data = {} }) {
  const dispatch = useDispatch();

  // Page Params
  const params = useParams();
  const { projectId } = params;
  const graphId = projectId;

  // Node Internals
  const updateNodeInternals = useUpdateNodeInternals();

  // Handles
  const sourceHandleIds = data.handles?.source || [];
  const sourceHandlesCount = sourceHandleIds.length;

  const targetHandleIds = data.handles?.target || [];
  const targetHandlesCount = targetHandleIds.length;

  // Parameters and Equations
  const parameters = data.parameters || [];
  const equations = data.equations || [];

  // Component State
  const [handlesCount, setHandlesCount] = useState(sourceHandlesCount + targetHandlesCount);
  const [bodyHeight, setBodyHeight] = useState(estimateBodyHeight());

  //
  //
  // Component Functions
  const updateNodeData = ReactFlowExt.useUpdateNodeData(id);

  //
  // Functions

  function estimateBodyHeight() {
    // Update Body Height
    const bodyHeightUnits = sourceHandlesCount > targetHandlesCount ? sourceHandlesCount : targetHandlesCount;
    const newBodyHeight = (bodyHeightUnits + 1) * PX_SPACING;

    return newBodyHeight;
  }

  function addHandle(isInput) {
    // Get HandleIds
    const handleIds = isInput ? targetHandleIds : sourceHandleIds;

    // Handles Array
    const handleCnt = handleIds.length;
    const lastHandleId = handleCnt === 0 ? "" : handleIds[handleCnt - 1];
    const nextHandleId = GraphUtil.nextHandleId(isInput, lastHandleId);

    handleIds.push(nextHandleId); // Push to Handles

    // Update Count to trigger re-render
    setHandlesCount(handlesCount + 1);

    // Body Height
    const newBodyHeight = estimateBodyHeight();
    setBodyHeight(newBodyHeight);

    // Update Internals
    updateNodeInternals(id);
  }

  const onNodeNameChange = React.useCallback(
    (newName) => {
      // Data Update
      const dataUpdates = { name: newName, symbol: data.symbol, varType: data.vaType };

      // Dispatch Node Update Action
      dispatch(NodeActions.updateNodeInfo({ graphId, nodeUid: id, nodeInfo: dataUpdates }));

      // Callback
      updateNodeData(dataUpdates);
    },
    [dispatch, graphId, id, data, updateNodeData]
  );

  //
  const nodeName = data?.name || "";

  //
  return (
    <div className="">
      <div className="node-header" title={`"${nodeName} (Control Volume)"`}>
        <button className="btn btn-add-handle left" title="Add Input" onClick={() => addHandle(true)}>
          +
        </button>
        <InlineEdit className="node-title px-3" value={nodeName} setValue={onNodeNameChange} />
        <button className="btn btn-add-handle right" title="Add Output" onClick={() => addHandle(false)}>
          +
        </button>
      </div>
      <div className="node-body" style={{ minHeight: bodyHeight }}>
        {parameters && parameters.length > 0 && (
          <div className="sec-info">
            <p className="sec-title">Parameters</p>
            <ul className="list-unstyled mb-1">
              {parameters.map((p, idx) => {
                return (
                  <li key={`node-${id}-param-${idx}`}>
                    {p.name} ( <small>{p.symbol}</small> )
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        <div className="sec-info d-none">
          <p className="sec-title">Equations</p>
        </div>
      </div>

      {/** Source Handles */}
      <HandlesCont
        nodeId={id}
        type={"source"}
        handles={sourceHandleIds}
        topOffset={PX_TOP_OFFSET}
        spacing={PX_SPACING}
      />

      {/** Target Handles */}
      <HandlesCont
        nodeId={id}
        type={"target"}
        handles={targetHandleIds}
        topOffset={PX_TOP_OFFSET}
        spacing={PX_SPACING}
      />
    </div>
  );
}

export default memo(ControlVolumeNode);
