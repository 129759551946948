/**
 * Table Serial Number Function
 * @param {*} pageNumber : Current page number
 * @param {*} pageSize : Current page size
 * @param {*} index : Index from map
 */

function tableSerialNumber(currentPage = "", pageSize = "", index = 0) {
  const currentPageNumber = currentPage ? Number(currentPage) : 0;
  const pageSizeNumber = pageSize ? Number(pageSize) : 0;

  const serialNumber = currentPageNumber === 1 ? index + 1 : (currentPageNumber - 1) * pageSizeNumber + index + 1;
  return serialNumber;
}

const TableUtils = { tableSerialNumber };

export default TableUtils;
