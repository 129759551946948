// Constants
import { UserUrls } from "../../constants/serviceUrls";

// Utils
import HttpClientFactory from "../../app/http/HttpClientFactory";
import UrlUtils from "../../utils/urlUtils";

// Service Methods
// ----------------------------------------------------------------------------

/**
 * Create New User
 * @param {*} userObj: Object
 * @returns
 */
async function createUser(userObj) {
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(UserUrls.createUser, {}, userObj);
}

/**
 * Update Exist User
 * @param {*} userId : Number
 * @param {*} userObj : Object
 * @returns
 */
async function updateUser(userId, userObj) {
  const url = UrlUtils.format(UserUrls.updateUser, { userId });
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(url, {}, userObj);
}

async function enableUser(userId) {
  const url = UrlUtils.format(UserUrls.enableUser, { userId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(url);
}

async function disableUser(userId) {
  const url = UrlUtils.format(UserUrls.disableUser, { userId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(url);
}

async function updateUserRole(userId, role) {
  const url = UrlUtils.format(UserUrls.updateUserRole, { userId });

  const params = {
    role,
  };
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(url, params);
}

/**
 * Change User Password
 * @param {*} userId: Number
 * @param {*} oldPassword: String
 * @param {*} newPassword: String
 * @returns
 */
async function updateUserPassword(userId, oldPassword, newPassword) {
  const params = {
    userId,
    oldPassword,
    newPassword,
  };

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(UserUrls.updateUserPassword, params);
}

/**
 * Get User Details
 * @param {*} userId : Number
 * @returns
 */
async function getUser(userId) {
  const url = UrlUtils.format(UserUrls.getUser, { userId });
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(url);
}

async function searchUsers(orgId, pageNumber, pageSize) {
  const params = {
    org: orgId,
    isAuthReq: true,
    pn: pageNumber,
    ps: pageSize,
  };

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(UserUrls.searchUsers, params);
}

// Exports
// ----------------------------------------------------------------------------

const UserService = {
  //
  createUser,

  //
  updateUser,
  enableUser,
  disableUser,
  updateUserRole,
  updateUserPassword,

  //
  getUser,
  searchUsers,
};

export default UserService;
