import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Constants & Utils
import { LocalStorageKeys } from "../../constants/GeneralConstants";
import { getItem } from "../LocalStorage";

// Components
import Icon from "../../components/icon/Icon";

// Images

// URLs
import PageURL from "../../constants/pageUrls";

/**
 * Sidebar Menu
 */
const SidebarMenus = {
  PLATFORM: [
    {
      title: "Home",
      items: [
        {
          id: 1,
          itemName: "Projects",
          icon: <Icon iconName="folderOpenDot" />,
          itemLink: PageURL.ProjectListPageURL,
          childItems: [],
        },
      ],
    },
    {
      title: "Admin",
      items: [
        {
          id: 2,
          itemName: "Users",
          icon: <Icon iconName="users" />,
          itemLink: PageURL.UserListPageURL,
          childItems: [],
        },
      ],
    },
  ],
  ACADEMIC: [
    {
      title: "Home",
      items: [
        {
          id: 1,
          itemName: "Projects",
          icon: <Icon iconName="folderOpenDot" />,
          itemLink: PageURL.ProjectListPageURL,
          childItems: [],
        },
      ],
    },
    {
      title: "Admin",
      items: [
        {
          id: 2,
          itemName: "Users",
          icon: <Icon iconName="users" />,
          itemLink: PageURL.UserListPageURL,
          childItems: [],
        },
      ],
    },
  ],
  BUSINESS: [
    {
      title: "Home",
      items: [
        {
          id: 1,
          itemName: "Projects",
          icon: <Icon iconName="folderOpenDot" />,
          itemLink: PageURL.ProjectListPageURL,
          childItems: [],
        },
      ],
    },
    {
      title: "Admin",
      items: [
        {
          id: 2,
          itemName: "Users",
          icon: <Icon iconName="users" />,
          itemLink: PageURL.UserListPageURL,
          childItems: [],
        },
      ],
    },
  ],
};

// Page Components

/**
 * {
 *   title: "Some text",
 *   items: []
 * }
 */
function Menu({ menu, isSidebarCollapsed, orgInfo }) {
  // Navigate
  const navigate = useNavigate();

  const { title, items = [] } = menu;

  const [selectedMenu, setSelectedMenu] = useState(null);

  return (
    <>
      {/* Menu Title */}
      {/* <li className="menu-title fs-5 text-black" key="t-menu">
        {isSidebarCollapsed ? title : "--"}
      </li> */}

      {/* Menu Items */}
      {items.map((menuItem, index) => {
        return (
          <MenuItem
            key={index}
            menuItem={menuItem}
            orgInfo={orgInfo}
            isSidebarCollapsed={isSidebarCollapsed}
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
          />
        );
      })}
    </>
  );
}

/**
 * {
 *   itemName: "Some text",
 *   itemIconCls: "far fa-icon",
 *   itemLink: "/link",
 *   childItems: [
 *     {
 *       itemName: "",
 *       itemLink: ""
 *     }
 *   ]
 * }
 */
function MenuItem({ menuItem, orgInfo, isSidebarCollapsed, selectedMenu = null, setSelectedMenu = () => {} }) {
  // State
  const [showSubMenu, setShowSubMenu] = useState(false);

  // Data
  const { itemName, icon, itemLink, childItems = [], id: menuId } = menuItem;
  const { id: orgId, type: orgType } = orgInfo;

  // Item URL
  const menuItemURL = itemLink ? itemLink.replace(":orgId", orgId) : "#";

  // Check if it has sub-menu
  const hasSubMenu = childItems && childItems.length > 0;

  return (
    <li
      className={`menu-items fs-5 text-black ${showSubMenu ? "show-sub-menu" : ""} ${
        selectedMenu === menuId ? "active" : ""
      }`}
    >
      <div
        className={`menu-link`}
        onClick={() => {
          setShowSubMenu((prev) => !prev);
          setSelectedMenu(menuId);
        }}
      >
        <Link to={menuItemURL} className="menu-link">
          <span className="me-3">{icon}</span>
          <span className={`link-name ${!isSidebarCollapsed && "d-none"}`}>{itemName}</span>
        </Link>
        {hasSubMenu && (
          <i className={`menu-icons arrow fa-solid fa-chevron-down fa-2xs ${!isSidebarCollapsed && "d-none"}`} />
        )}
      </div>
      <ul className={`sub-menu ${showSubMenu ? "d-block" : ""}`}>
        <li>
          <Link className={`link-name ${isSidebarCollapsed && "d-none"}`}>{itemName}</Link>
        </li>
        {childItems.map((childItem, index) => {
          const childItemName = childItem["itemName"];
          const childItemLink = childItem["itemLink"];
          const childItemURL = childItemLink ? childItemLink.replace(":orgId", orgId) : "#";

          return (
            <li key={index} className="mb-2">
              <Link to={childItemURL}>{childItemName}</Link>
            </li>
          );
        })}
      </ul>
    </li>
  );
}

/**
 * Sidebar Component
 */
export default function SideBar({ collapsible = false, showSideBar, setShowSideBar }) {
  // Authenticated Org Type
  const authOrgStr = getItem(LocalStorageKeys.AUTH_ORG) || "{}";
  const authOrg = JSON.parse(authOrgStr);
  const authOrgType = authOrg?.type;

  // Menus
  const sidebarMenuList = SidebarMenus[authOrgType] || [];

  //
  return (
    <nav className={`sidebar ${showSideBar ? "" : "collapsed"}`}>
      <ul className={`nav-links ${showSideBar ? "" : "overflow-visible"}`}>
        {/* Itetrate over Menu group list */}
        {sidebarMenuList.map((menuGroup, idx) => {
          return <Menu key={idx} menu={menuGroup} isSidebarCollapsed={showSideBar} orgInfo={authOrg} />;
        })}
      </ul>
    </nav>
  );
}
