import { toast } from "react-toastify";
import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { setErrorInfo } from "../../app/error/ErrorActions";
import { ProjectActions } from "../../redux-slice/project/projectSlice";

// Service
import ProjectService from "../../services/project/projectService";

// Utils
import UrlUtils from "../../utils/urlUtils";

// URLs
import PageURL from "../../constants/pageUrls";

//
// Saga Functions
// ----------------------------------------------------------------------------

// Create Project
function* createProject(action) {
  const { projectData, navigate } = action.payload;

  try {
    const { id } = yield ProjectService.createProject(projectData);

    // Dispatching Action
    yield put(ProjectActions.createProjectSuccess());

    // Toaster
    toast.success("Project Created Successfully");

    // Navigate
    const projectCanvasPageURL = UrlUtils.format(PageURL.GraphCanvasPageURL, { projectId: id });
    navigate(projectCanvasPageURL);
  } catch (error) {
    yield put(ProjectActions.createProjectFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Update Project Information
function* updateProject(action) {
  const { projectId, projectData } = action.payload;
  try {
    yield ProjectService.updateProject(projectId, projectData);

    // Dispatching Action
    yield put(ProjectActions.updateProjectSuccess());

    // Toaster
    toast.success("Project Updated Sucessfully");
  } catch (error) {
    yield put(ProjectActions.updateProjectFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Get Project
function* getProject(action) {
  const { projectId } = action.payload;

  try {
    const projectInfo = yield ProjectService.getProject(projectId);

    // Dispatching Action
    yield put(ProjectActions.getProjectSuccess({ projectInfo }));
  } catch (error) {
    yield put(ProjectActions.getProjectFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Get Project list
function* searchProjects(action) {
  try {
    const { searchText, pageNumber, pageSize } = action.payload;

    const { items, pagination } = yield ProjectService.searchProjects(searchText, pageNumber, pageSize);

    // Dispatching Action
    yield put(ProjectActions.searchProjectsSuccess({ items, pagination }));
  } catch (error) {
    yield put(ProjectActions.searchProjectsFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Export
export default function* root() {
  yield all([
    //
    takeLatest(ProjectActions.createProject.type, createProject),

    //
    takeLatest(ProjectActions.updateProject.type, updateProject),

    //
    takeLatest(ProjectActions.getProject.type, getProject),
    takeLatest(ProjectActions.searchProjects.type, searchProjects),
  ]);
}
