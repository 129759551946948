import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Form, Modal } from "antd";

// Actions
import { UserActions } from "../../../../redux-slice/user/userSlice";

// Constants
import { StatusCode } from "../../../../constants/WebConstants";
import { USER_CHANGE_PASSWORD_INPUT_DATA, USER_MODAL_TYPES } from "../../../../constants/userConstants";

// Components
import UserModalFooter from "./UserModalFooter";

function FormItem({ data = {} }) {
  const { label = "", name = "", rules = [], help = "", input = <></> } = data;
  return (
    <Form.Item className="fw-semibold" label={label} name={name} rules={rules} help={help}>
      {input}
    </Form.Item>
  );
}

/**
 * User Password Change Modal
 * @param {*} userId: String/Number
 * @param {*} openModal: Function
 * @param {*} setOpenModal: Function
 * @returns
 */
export default function UserPasswordChangeModal({
  userId = "",
  openModal = { state: false, type: "" },
  setOpenModal = () => {},
}) {
  // Dispatch
  const dispatch = useDispatch();

  // Form
  const [form] = Form.useForm();

  // States
  const [validationError, setValidationError] = useState("");
  const [showError, setShowError] = useState(false);

  // Selectors
  const updateUserPasswordLoading = useSelector((state) => state.user.updateUserPasswordLoading);
  const updateUserPasswordSuccess = useSelector((state) => state.user.updateUserPasswordSuccess);

  const errorFromApi = useSelector((state) => state.user.updateUserPasswordError);

  // Extracting status code from error
  const { response = {} } = errorFromApi;
  const statusCode = response?.status || "";

  const { state = false, type = "" } = openModal;
  const isModalOpen = state && type === USER_MODAL_TYPES.CHANGE_PASSWORD;

  const formInput = USER_CHANGE_PASSWORD_INPUT_DATA;

  function handleReset() {
    form.resetFields();
    setValidationError("");
    setShowError(false);
  }

  function handleChangePassword(value) {
    const { oldPassword = "", newPassword = "", confirmPassword = "" } = value;
    if (newPassword != confirmPassword) {
      setValidationError("Passwords do not match");
      setShowError(true);
      return;
    }

    dispatch(UserActions.updateUserPassword({ userId, oldPassword, newPassword }));
    setValidationError("");
    setShowError(false);
  }

  function handleSubmit() {
    form
      .validateFields()
      .then((value) => handleChangePassword(value))
      .catch((info) => {
        setValidationError(info.errorFields[0].errors[0]);
        setShowError(true);
      });
  }

  useEffect(() => {
    if (updateUserPasswordSuccess) {
      setOpenModal({ state: false, type: "" });
      form.resetFields();
      setValidationError("");
      setShowError(false);
    }
  }, [updateUserPasswordSuccess]);

  useEffect(() => {
    const isOldPasswordWrong = statusCode === StatusCode.FORBIDDEN;
    if (isOldPasswordWrong) {
      setValidationError("Old password is incorrect");
      setShowError(true);
    }
  }, [statusCode]);

  return (
    <Modal
      open={isModalOpen}
      title="Change Password"
      onCancel={() => setOpenModal({ state: false, type: "" })}
      afterClose={handleReset}
      width={500}
      footer={() => (
        <UserModalFooter handleReset={handleReset} handleSubmit={handleSubmit} btnLoading={updateUserPasswordLoading} />
      )}
    >
      {/* Error Alert */}
      {showError && <Alert message={validationError} type="error" />}

      <Form
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
        className="my-4"
        form={form}
      >
        {formInput.map((data, index) => (
          <FormItem key={index} data={data} />
        ))}
      </Form>
    </Modal>
  );
}
