// Components
import Button from "../../components/button/Button";

// Images
import URL_IMG from "../../assests/imgs/branding/logo.png";

// Page Components
// ----------------------------------------------------------------------------

function ForgotPasswordCard() {
  return (
    <div className="card shadow mt-5">
      <div className="card-body position-relative">
        {/* Logo Component */}
        <img className="m-4 d-flex align-items-center justify-content-center" src={URL_IMG} alt="" />

        <div className="p-2 pt-5">
          <form className="form-horizontal">
            <div className="form-group mb-3">
              <label>Email</label>

              {/* Input */}
              <input type="email" name="email" className="form-control mt-2" placeholder="Enter email" />
            </div>
            <div className="form-group text-end mb-3">
              {/* Button Component */}
              <Button className="w-md w-25" color="primary" label="Reset" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

function ForgotPasswordFooter() {
  return (
    <div className="mt-5 text-center">
      <p></p>
    </div>
  );
}

/**
 * Forgot Password Page
 */
export default function ForgotPasswordPage() {
  return (
    <div className="container my-5 pt-sm-5">
      <div className="row justify-content-center">
        <div className="col-md-8 col-lg-6 col-xl-5">
          {/* Forgot Password Card */}
          <ForgotPasswordCard />

          {/* Forgot Password Footer */}
          <ForgotPasswordFooter />
        </div>
      </div>
    </div>
  );
}
