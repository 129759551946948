import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Hooks
import { useUpdateQueryParams } from "../../../hooks/useUpdateQueryParams";

// Actions
import { ProjectFileActions } from "../../../redux-slice/project/projectFileSlice";

// Constants
import { QueryParamsKeys } from "../../../constants/WebConstants";
import { ICON_SIZE } from "../../../constants/GeneralConstants";

// Components
import PageHeader from "../../../app/layout/PageHeader";
import ProjectFileTable from "./components/ProjectFileTable";
import Button from "../../../components/button/Button";
import Icon from "../../../components/icon/Icon";

// Section
import CreateAndEditProjectFileModal from "./components/CreateAndEditProjectFileModal";

// Page Components
function PageHeaderSection({ setIsModalOpen, setSelectedFileId }) {
  function openModal() {
    setIsModalOpen(true);
    setSelectedFileId("");
  }

  // Content
  const pageTitle = "Files";
  const pageActions = (
    <div className="btn-cont">
      <Button
        icon={<Icon size={ICON_SIZE.MD} iconName="plus" color="white" />}
        className="btn-secondary"
        onClick={openModal}
      >
        Create File
      </Button>
    </div>
  );

  return <PageHeader title={pageTitle} actions={pageActions} />;
}

/**
 * Project File List Page
 */
export default function ProjectFileListPage() {
  const dispatch = useDispatch();

  const { projectId = "" } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState("");
  const [selectedFileInfo, setSelectedFileInfo] = useState({});

  // Query Params
  const [searchParams] = useUpdateQueryParams();

  const filesMap = useSelector((state) => state.projectFile.filesMap);
  const fileInfo = useSelector((state) => state.projectFile.fileInfo);
  const isApiSuccess = useSelector((state) => state.projectFile.isApiSuccess);

  // From URL
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber) ?? "";
  const pageSize = searchParams.get(QueryParamsKeys.pageSize) ?? "";
  const searchText = searchParams.get(QueryParamsKeys.searchText);

  useEffect(() => {
    dispatch(ProjectFileActions.searchFiles({ projectId, pageNumber, pageSize, searchText }));
  }, [dispatch, projectId, pageNumber, pageSize, searchText]);

  useEffect(() => {
    if (isApiSuccess) {
      setIsModalOpen(false);
    }
  }, [isApiSuccess]);

  useEffect(() => {
    // Clearing previous selection
    setSelectedFileInfo({});

    const fileInfo = filesMap[selectedFileId] || {};
    const isFileInfoPresent = Object.keys(fileInfo).length !== 0;

    if (isFileInfoPresent) {
      setSelectedFileInfo(fileInfo);
    }

    if (!isFileInfoPresent && selectedFileId) {
      dispatch(ProjectFileActions.getFileInfo({ projectId, fileId: selectedFileId }));
    }
  }, [selectedFileId]);

  useEffect(() => {
    setSelectedFileInfo(fileInfo);
  }, [fileInfo]);

  return (
    <div className="main-cont position-right-0">
      <div className="content-wrapper">
        {/** Header */}
        <PageHeaderSection setIsModalOpen={setIsModalOpen} setSelectedFileId={setSelectedFileId} />

        {/* Page Content */}
        <div className="page-content">
          <div className="py-3 pb-5">
            <ProjectFileTable
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              selectedFileId={selectedFileId}
              setSelectedFileId={setSelectedFileId}
            />
          </div>
        </div>

        <CreateAndEditProjectFileModal
          fileInfo={selectedFileInfo}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      </div>
    </div>
  );
}
