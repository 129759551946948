//
import { createSlice } from "@reduxjs/toolkit";

// Utils
import OptRunTransformer from "./optRunTransformer";

// Intital state
const initialState = {
  // Create OptRun
  createOptRunLoading: false,
  createOptRunError: "",
  isOptRunSuccess: "",

  // Delete OptRun
  deleteOptRunLoading: false,
  deleteOptRunError: "",

  // Delete OptRun (by OptSetup)
  deleteOptRunsBySetupLoading: false,
  deleteOptRunsBySetupError: "",

  // Get OptRun
  optRun: {},
  optRunLoading: false,
  optRunError: "",

  optRunList: [],
  optRunListPagination: {},
  optRunListLoading: false,
  optRunListError: "",

  // OptRun Problem
  optRunProblem: "",
  optRunProblemLoading: false,
  optRunProblemError: "",
};

// OptRun Slice
const optRunSlice = createSlice({
  // Name
  name: "OptRun",

  // Initial State
  initialState,

  // Reducers
  reducers: {
    // Create OptRun
    createOptRun: (state) => {
      state.optRun = {};
      state.createOptRunLoading = true;
      state.isOptRunSuccess = false;
    },

    createOptRunSuccess: (state, action) => {
      state.createOptRunLoading = false;
      state.optRun = action.payload.optRun;
      state.isOptRunSuccess = true;
      state.createOptRunError = "";
    },

    createOptRunFailure: (state, action) => {
      state.optRun = {};
      state.createOptRunLoading = false;
      state.isOptRunSuccess = false;
      state.createOptRunError = action.payload.error;
    },

    //
    // Delete OptSetup Runs
    deleteOptRunsBySetup: (state) => {
      state.deleteOptRunsBySetupLoading = true;
    },

    deleteOptRunsBySetupSuccess: (state) => {
      state.deleteOptRunsBySetupLoading = false;
      state.deleteOptRunsBySetupError = "";
    },

    deleteOptRunsBySetupFailure: (state, action) => {
      state.deleteOptRunsBySetupLoading = false;
      state.deleteOptRunsBySetupError = action.payload.error;
    },

    // Delete OptRun
    deleteOptRun: (state) => {
      state.deleteOptRunLoading = true;
    },

    deleteOptRunSuccess: (state) => {
      state.deleteOptRunLoading = false;
      state.deleteOptRunError = "";
    },

    deleteOptRunFailure: (state, action) => {
      state.deleteOptRunLoading = false;
      state.deleteOptRunError = action.payload.error;
    },

    //
    // Get OptRun
    getOptRun: (state) => {
      state.optRunLoading = true;
    },

    getOptRunSuccess: (state, action) => {
      state.optRun = action.payload.optRun;
      state.optRunLoading = false;
      state.optRunError = "";
    },

    getOptRunFailure: (state, action) => {
      state.optRunLoading = false;
      state.optRunError = action.payload.error;
    },

    // Get Opt Run List
    searchOptRuns: (state) => {
      state.optRunListLoading = true;
      state.optRunListError = "";
    },

    searchOptRunsSuccess: (state, action) => {
      const { items, pagination } = action.payload;

      state.optRunList = OptRunTransformer.transformList(items);
      state.optRunListPagination = pagination;
      state.optRunListLoading = false;
    },

    searchOptRunsFailure: (state, action) => {
      state.optRunListLoading = false;
      state.optRunListError = action.payload.error;
    },

    // Get OptRun Problem
    getOptRunProblem: (state) => {
      state.optRunProblemLoading = true;
    },

    getOptRunProblemSuccess: (state, action) => {
      state.optRunProblem = action.payload.optRunProblem;
      state.optRunProblemLoading = false;
      state.optRunProblemError = "";
    },

    getOptRunProblemFailure: (state, action) => {
      state.optRunProblemLoading = false;
      state.optRunProblemError = action.payload.error;
    },
  },
});

// Reducer
export const OptRunReducer = optRunSlice.reducer;

// Actions
export const OptRunActions = optRunSlice.actions;
