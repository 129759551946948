import { createSlice } from "@reduxjs/toolkit";

// Transformer
import ParameterTransformer from "./parameterTransformer";

// Util Functions
function updateParameterDetails(parameterMap, currentParameterDetails, isupdate = false) {
  const cloneParameterMap = { ...parameterMap };
  const { nodeUid: nodeId, id: parameterId } = currentParameterDetails;
  const parameterArray = cloneParameterMap[nodeId] || [];
  if (isupdate) {
    const filteredParameterArray = parameterArray.filter((eachParameter) => eachParameter.id != parameterId);
    filteredParameterArray.push(currentParameterDetails);
    cloneParameterMap[nodeId] = filteredParameterArray;
    return;
  }
  parameterArray.push(currentParameterDetails);
  cloneParameterMap[nodeId] = parameterArray;
  return cloneParameterMap;
}

function deleteParameterDetails(parameterMap, nodeId, parameterId) {
  const cloneParameterMap = { ...parameterMap };
  const parameterArray = cloneParameterMap[nodeId] || [];
  const newParameterArray = parameterArray.filter((eachParameter) => eachParameter.id != parameterId);
  cloneParameterMap[nodeId] = newParameterArray;
  return cloneParameterMap;
}

// Intital state
const initialState = {
  parameterMap: {},

  // Create Paramter
  createParameterLoading: false,
  createParameterError: "",

  // Update Parameters
  updateParameterLoading: false,
  updateParameterError: "",

  // Delete Parameters
  deleteParameterLoading: false,
  deleteParameterError: "",
};

// Parameter Slice
const parameterSlice = createSlice({
  // Name
  name: "Parameter",

  // Initial State
  initialState,

  // Reducers
  reducers: {
    // Add Paramter
    createParameter: (state) => {
      state.createParameterLoading = true;
    },

    createParameterSuccess: (state, action) => {
      const { parameterDetails } = action.payload;
      const transformedParameterDetails = ParameterTransformer.transformObj(parameterDetails);

      const updatedParameterMap = updateParameterDetails(state.parameterMap, transformedParameterDetails);

      state.createParameterLoading = false;
      state.parameterMap = updatedParameterMap;
      state.createParameterError = "";
    },

    createParameterFailure: (state, action) => {
      state.createParameterLoading = false;
      state.createParameterError = action.payload.error;
    },

    // Update Paramter
    updateParameter: (state) => {
      state.updateParameterLoading = true;
    },

    updateParameterSuccess: (state, action) => {
      const { parameterDetails } = action.payload;
      const transformedParameterDetails = ParameterTransformer.transformObj(parameterDetails);

      const updatedParameterMap = updateParameterDetails(state.parameterMap, transformedParameterDetails);

      state.updateParameterLoading = false;
      state.parameterMap = updatedParameterMap;
      state.updateParameterError = "";
    },

    updateParameterFailure: (state, action) => {
      state.updateParameterLoading = false;
      state.updateParameterError = action.payload.error;
    },

    // Delete Paramter
    deleteParameter: (state) => {
      state.deleteParameterLoading = true;
    },

    deleteParameterSuccess: (state, action) => {
      const { nodeId, parameterId } = action.payload;
      const updatedParameterMap = deleteParameterDetails(state.parameterMap, nodeId, parameterId);

      state.deleteParameterLoading = false;
      state.parameterMap = updatedParameterMap;
      state.deleteParameterError = "";
    },

    deleteParameterFailure: (state, action) => {
      state.deleteParameterLoading = false;
      state.deleteParameterError = action.payload.error;
    },
  },
});

// Reducer
export const ParameterReducer = parameterSlice.reducer;

// Actions
export const ParameterActions = parameterSlice.actions;
