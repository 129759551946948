import { createSlice } from "@reduxjs/toolkit";

// Transfomer
import EdgeTransformer from "./edgeTransformer";

// Util Functions
function updateEdgeInfo(edgeInfoMap, graphId, edgeId, edgeInfo) {
  const cloneEdgeInfoMap = { ...edgeInfoMap };
  edgeInfo["graphId"] = graphId;
  cloneEdgeInfoMap[edgeId] = edgeInfo;
  return cloneEdgeInfoMap;
}

function updateEdgeStyle(edgeStyleMap, edgeId, styleObj) {
  const cloneEdgeStyleMap = { ...edgeStyleMap };
  cloneEdgeStyleMap[edgeId] = styleObj;
  return cloneEdgeStyleMap;
}

// Intital state
const initialState = {
  // Create Edge
  createEdgeLoading: false,
  createEdgeErr: "",
  createdEdge: {},

  // Update Edge Info
  updateEdgeInfoLoading: false,
  updateEdgeInfoErr: "",
  edgeInfoMap: {},

  // Update Edge Var Info
  updateEdgeVarInfoLoading: false,
  updateEdgeVarInfoErr: "",
  edgeVarInfoMap: {},

  // Update Edge Style
  updateEdgeStyleLoading: false,
  updateEdgeStyleErr: "",
  edgeStyleMap: {},

  // Delete Reducer
  deleteEdgeLoading: false,
  deleteEdgeErr: "",
};

// Edge Slice
const edgeSlice = createSlice({
  // Name
  name: "Edge",

  // Initial State
  initialState,

  // Reducers
  reducers: {
    // Create Edge
    createEdge: (state) => {
      state.createEdgeLoading = true;
    },

    createEdgeSuccess: (state, action) => {
      const { newEdge = {} } = action.payload;
      const transformedCreatedEdge = EdgeTransformer.transformObj(newEdge);

      state.createdEdge = transformedCreatedEdge;
      state.createEdgeLoading = false;
      state.createEdgeErr = "";
    },

    createEdgeFailure: (state, action) => {
      state.createEdgeLoading = false;
      state.createEdgeErr = action.payload.error;
    },

    // Update Edge Information
    updateEdgeInfo: (state) => {
      state.updateEdgeInfoLoading = true;
    },

    updateEdgeInfoSuccess: (state, action) => {
      const { graphId, edgeId, edgeInfo = {} } = action.payload;
      const transformedEdgeInfo = EdgeTransformer.transformObj(edgeInfo);

      const edgeInfoMap = updateEdgeInfo(state.edgeInfoMap, graphId, edgeId, transformedEdgeInfo);

      state.updateEdgeInfoLoading = false;
      state.edgeInfoMap = edgeInfoMap;
      state.updateEdgeInfoErr = "";
    },

    updateEdgeInfoFailure: (state, action) => {
      state.updateEdgeInfoLoading = true;
      state.updateEdgeInfoErr = action.payload.error;
    },

    // Update Edge Var Info
    updateEdgeVarInfo: (state) => {
      state.updateEdgeVarInfoLoading = true;
    },

    updateEdgeVarInfoSuccess: (state) => {
      state.updateEdgeVarInfoLoading = false;
      state.updateEdgeVarInfoErr = "";
    },

    updateEdgeVarInfoFailure: (state, action) => {
      state.updateEdgeVarInfoLoading = false;
      state.updateEdgeVarInfoErr = action.payload.error;
    },

    // Update Edge Style
    updateEdgeStyle: (state) => {
      state.updateEdgeStyleLoading = true;
    },

    updateEdgeStyleSuccess: (state, action) => {
      const { graphId, edgeId, edgeInfo } = action.payload;
      const transformedEdgeInfo = EdgeTransformer.transformObj(edgeInfo);

      const edgeStyleMap = updateEdgeStyle(state.edgeInfoMap, graphId, edgeId, transformedEdgeInfo);

      state.updateEdgeStyleLoading = false;
      state.edgeStyleMap = edgeStyleMap;
      state.updateEdgeStyleErr = "";
    },

    updateEdgeStyleFailure: (state, action) => {
      state.updateEdgeStyleLoading = false;
      state.updateEdgeStyleErr = action.payload.error;
    },

    // Delete Edge
    deleteEdge: (state) => {
      state.deleteEdgeLoading = true;
    },

    deleteEdgeSuccess: (state) => {
      state.deleteEdgeLoading = false;
    },

    deleteEdgeFailure: (state, action) => {
      state.deleteEdgeLoading = true;
      state.deleteEdgeErr = action.payload.error;
    },
  },
});

// Reducer
export const EdgeReducer = edgeSlice.reducer;

// Actions
export const EdgeActions = edgeSlice.actions;
