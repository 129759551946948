export function getTransformedUserList(userListObj) {
  // Response from the API
  const { items = [], authInfoMap = {} } = userListObj || {};

  const authUsersList = {};
  for (const id of Object.keys(authInfoMap)) {
    const { role = "" } = authInfoMap[id];
    authUsersList[id] = {
      role: role,
    };
  }

  const usersList = items.map((user) => {
    const {
      id = "",
      orgId = "",
      firstName = "",
      lastName = "",
      isActive = false,
      emailId = "",
      phoneNum = "",
      designation = "",
    } = user;

    const users = {
      id: id,
      orgId: orgId,
      firstName: firstName,
      lastName: lastName,
      designation: designation,
      isActive: isActive,
      emailId: emailId,
      phoneNum: phoneNum,
    };
    return users;
  });

  return [usersList, authUsersList];
}
